import React from "react";
//import { Provider } from "react-redux";
//import { Provider } from 'jotai';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as JotaiProvider } from 'jotai';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

const App = ({ store, basename }) => {
     console.log = console.warn = console.error = () => {};
     console.warn = () => {};

    return (
        <ReduxProvider  store={store}>
        <JotaiProvider>
            <BrowserRouter basename={basename}>
                <AppRoutes basename={basename} />
            </BrowserRouter>
        </JotaiProvider>
        </ReduxProvider>
    )
}

export default App;