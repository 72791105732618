import React, { useState, useEffect } from 'react';
import { LoadingBars, NoRecordFound } from "../layouts/loadbars/LoadingBars";
import { ButtonCreateEvent, NavTabs } from "./FormAtributes";
import { GET_RECOM } from "./Apis";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../layouts/popovers/PopOvers";
import { Link, useOutletContext } from "react-router-dom";
export default function ListEvent() {
    var datetime = new Date();
    var year = datetime.getFullYear();
    var datetime = new Date();
    var year = datetime.getFullYear();
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const ReloadData = async () => {
        const results = await GET_RECOM();
        if (results.data && Object.values(results.data).length > 0) {
            //console.log(results.data)
            setCampaigns({ loading: false, data: results.data, message: "" });
        } else if (results.message) {
            setCampaigns({ loading: false, data: [], message: results.message });
        } else {
            setCampaigns({ loading: false, data: [], message: "No record found" });
        }
    };

    const firstLetter = (nm) => {
        return nm[0];
    };

    const createRegistrationLink = (eventId) => {
        const payload = { event_id: eventId };
        //const token = sign(payload, packageJSON.system_param.PublicKey);
        const token = btoa(JSON.stringify(payload)).replace(/\//g, '_').replace(/\+/g, '-');
        return `/registration/${token}`;
    };
    useEffect(() => {
        ReloadData();
    }, []);
    return (
        <div className="row g-5 g-xl-5 mb-1 mb-xl-10">

            <div className="card card-flush h-md-100 w-100 ">
                <div className="card-header border-0 p-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-900">
                            <span
                                style={{
                                    background:
                                        "linear-gradient(to right, #3A7BD5 0%, #00D2FF 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    display: "inline-block",
                                }}
                            >
                                <span id="kt_landing_hero_text">Recommended for you</span>
                            </span>
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">Events and Training</span>
                    </h3>
                    <div className="card-toolbar">

                    </div>
                </div>
                <div className="card-body p-5">
                    {(Object.values(campaigns.data).length > 0) ?
                        campaigns.data.map((v, index) => (
                            <div key={index}>
                                <div className="d-flex flex-stack">
                                    <div className="symbol symbol-40px me-4">
                                        <div className="symbol-label fs-2 fw-semibold bg-danger text-inverse-danger">{firstLetter(decodeURIComponent(v.short_title))} </div>
                                    </div>
                                    <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                        <div className="flex-grow-1 me-2">
                                            <a href="pages/user-profile/overview.html" className="text-gray-800 text-hover-primary fs-7 fw-bold">{decodeURIComponent(v.short_title)}</a>
                                            <span className="text-muted fw-semibold d-block fs-7">Open</span>
                                        </div>
                                        <OverlayTrigger
                                            delay={{ show: 250, hide: 400 }}
                                            placement="top"
                                            overlay={renderTooltip({
                                                title: "Apply this event",
                                            })}
                                        >
                                            <Link type="button" to={createRegistrationLink(v.event_id)} className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                                                <i className="ki-duotone ki-arrow-right fs-2">
                                                    <span className="path1"></span>
                                                    <span className="path2"></span>
                                                </i>

                                            </Link>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                                <div className="separator separator-dashed my-4"></div>
                            </div>))
                        : <span className="text-muted fw-semibold d-block fs-7">No Recommended event</span>}

                </div>

            </div>
        </div>
    )
}
