import { AlertNofif } from "../layouts/alerts/AlertsUI";

const RenderAccessWidgets = (widgets, widget_id) =>{
    const findSelectedWidget = widgets.find(item => item.widget_id === widget_id && item.is_enabled === 1);
    if(findSelectedWidget){
        return true;
    }else{
        const findSelectedWidget = widgets.find(item => item.widget_id === widget_id);
        if(findSelectedWidget.parent_id === 0){
            return <AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />
        }else{
            return "";
        }
    }
}

export {RenderAccessWidgets}