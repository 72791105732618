import ExcelJS from 'exceljs';

const downloadCSV = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('My Sheet');

    worksheet.columns = [
        { header: 'No', key: 'id' },
        { header: 'Group Membership', key: 'name' },
        { header: 'Email', key: 'email' },
    ];

    if (Object.values(data).length > 0) {
        data.forEach((item, index) => {
            worksheet.addRow({ id: index + 1, name: item.group, email: item.email });
        });
    }

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    workbook.csv.writeBuffer().then((buffer) => {
        writeFile("Export Recepient Group.csv", buffer);
    });
};

export default downloadCSV;
