import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Papa from 'papaparse';
import { RemoveEmptyValue } from '../../../helpers/CommonHelpers';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { ButtonTersier } from '../../layouts/buttons';
import { RenderAccessWidgets } from '../RenderAccessUser';
import { AlertNofif } from '../../layouts/alerts/AlertsUI';

export function RecepientForm() {
    const context = useOutletContext();
    const widget_11 = RenderAccessWidgets(context.widgets, 11);
    const navigate = useNavigate()
    let params = useParams();
    const recipient_id = params.recipient_id;
    const fileInputRef = useRef(null);
    const objParam = {
        recipient_id: 0,
        name: "",
        description: "",
        mail_users: "",
        is_active: 1
    };
    const [post, setPost] = useState(objParam);
    const [recepient, setRecepient] = useState({ loading: false, data: [], message: "" });

    useEffect(() => {
        if (recipient_id) {
            GetRecepientByID(recipient_id, setRecepient, setPost)
        }
    }, [])

    const [submitPost, setSubmitPost] = useState({ loading: false, data: [], message: "" });
    const SubmitForm = (e) => {
        e.preventDefault();

        var formid = e.target.id;
        var target = document.getElementById(formid);
        var myButton = target.getElementsByClassName("btn-submit")[0];
        myButton.textContent = "Processing";
        myButton.disabled = true;

        if (post.name && post.mail_users && post.is_active) {
            const param = RemoveEmptyValue(post);
            const mail_users_arr = param.mail_users.split(/\s*,\s*/);
            param.mail_users = JSON.stringify(mail_users_arr);
            param.name = encodeURIComponent(param.name);
            if (param.recipient_id) {
                UpdateRecepient(param, myButton);
            } else {
                CreateRecepient(param, myButton);
            }
        } else {
            setSubmitPost({ loading: false, data: [], message: "Please fill out the form with correctly" })
        }
    }

    const CreateRecepient = (param, myButton) => {
        setSubmitPost({ loading: true, data: [], message: "" })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/recepients/save"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error })
                myButton.textContent = "Save Changes";
                myButton.disabled = false;
            } else if (response.data && response.status === 200) {
                setSubmitPost({ loading: false, data: response.data, message: "successfully saved" })
                myButton.textContent = "Saved";
                myButton.disabled = true;
                setTimeout(() => {
                    navigate("/recepients");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" })
                myButton.textContent = "Save Changes";
                myButton.disabled = false;
            }
        });
    }

    const UpdateRecepient = (param, myButton) => {
        setSubmitPost({ loading: true, data: [], message: "" })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/recepients/update"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error })
                myButton.textContent = "Save Changes";
                myButton.disabled = false;
            } else if (response.data && response.status === 200) {
                setSubmitPost({ loading: false, data: response.data, message: "successfully saved" })
                myButton.textContent = "Saved";
                myButton.disabled = true;
                setTimeout(() => {
                    navigate("/recepients");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" })
                myButton.textContent = "Save Changes";
                myButton.disabled = false;
            }
        });
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const data = results.data;
                console.log(data);
                ParsingExcel2Param(data, setPost)
            },
        });
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    return (
        (widget_11 === true ? (
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Form Recepeints</span>
                        <span className="text-muted mt-1 fw-semibold fs-7">Please fill up the form with correctly</span>
                    </h3>
                    <div className="card-toolbar">
                        <ButtonTersier
                            className="btn-sm fw-bold"
                            type="button"
                            onClick={handleButtonClick}
                        >
                            <i className="fa fa-upload me-1"></i>
                            <span className="text-capitalize">Import from CSV</span>
                        </ButtonTersier>
                        <input type="file" name="file" accept=".csv" onChange={handleFileUpload} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                </div>
                <div className="card-body">
                    <form method='post' autoComplete='off' id='form-post' onSubmit={(e) => SubmitForm(e)}>
                        <div className="mb-5">
                            <label className='required fw-bolder'>Group Name</label>
                            <input type="text" required name="name" className="form-control" placeholder='ME-1' defaultValue={post.name} onChange={(e) => setPost({ ...post, name: e.target.value })} />
                        </div>
                        <div className="mb-5">
                            <label className='fw-bolder'>Description</label>
                            <input type="text" name="Description" className="form-control" defaultValue={post.description} onChange={(e) => setPost({ ...post, description: e.target.value })} />
                        </div>
                        <div className="mb-5">
                            <label className='required fw-bolder'>Mailing List</label>
                            <textarea required name="mail_users" rows="5" className="form-control" defaultValue={post.mail_users} onChange={(e) => setPost({ ...post, mail_users: e.target.value })}></textarea>
                            <small className="text-muted d-block">Use commas (,) between email in a sentence list. </small>
                            <small className="text-muted">Exp: aa@gmail.com , bb@gmail.com </small>
                        </div>
                        <div className="mb-5 w-150px">
                            <label className="required fw-bolder">Status</label>
                            <select
                                name="status"
                                id=""
                                className="form-select form-select-sm"
                                required
                                defaultValue={post.is_active}
                                onChange={(e) => setPost({ ...post, is_active: parseInt(e.target.value) })}
                            >
                                <option value="0">Not Active</option>
                                <option value="1">Active</option>
                            </select>
                            <small className="text-danger"></small>
                        </div>

                        {submitPost.message ? (
                            <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mb-5">
                                {submitPost.message}
                            </div>
                        ) : ""}

                        <div className="text-end">
                            <Link className="btn btn-lg btn-light fw-bold me-3" to={"/recepients"}>Close</Link>
                            <button className="btn btn-lg btn-primary fw-bold btn-submit" type='submit' disabled={submitPost.loading}>Save Changes</button>
                        </div>
                    </form>
                </div>
            </div>
        ) : <AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />)
    )
}

const ParsingExcel2Param = (data, setPost) => {
    const totalMail = data.length;
    let mail_users = "";

    data.forEach((v, index) => {
        mail_users += v.Email + ((totalMail - 1) !== index ? "," : "");
    });

    const objParam = {
        recipient_id: "",
        name: data[0]['Group Membership'] ? data[0]['Group Membership'] : "",
        description: "",
        mail_users: mail_users,
        is_active: 1
    };

    setPost(objParam);
};

const GetRecepientByID = (recipient_id, setRecepient, setPost) => {
    setRecepient({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "get",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/recepients/" + recipient_id
    ).then((response) => {
        if (response.error) {
            setRecepient({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            const result = response.data;
            setRecepient({
                loading: false,
                data: result,
                message: "Successfully Saved",
            });
            setPost({
                recipient_id: result.recipient_id,
                name: result.name,
                description: result.description,
                mail_users: JSON.parse(result.mail_users).join(","),
                is_active: result.is_active
            })
        } else {
            setRecepient({ loading: false, data: [], message: "Failed saved" });
        }
    });
};