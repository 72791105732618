import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../layouts/datatables/MYDatatables';
import moment from "moment"
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../layouts/modals/Modals';
import { Link, useOutletContext } from 'react-router-dom';
import { RenderAccessWidgets } from '../RenderAccessUser';
import { LoadingBars } from '../../layouts/loadbars/LoadingBars';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { ButtonTersier } from '../../layouts/buttons';
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../layouts/popovers/PopOvers";
import { formatRupiah } from "./components/FormAtributes";

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_delete = RenderAccessWidgets(context.widgets, 37);

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;
  const headers = [
    { name: "No", field: "coupon_id", sortable: false },
    { name: "Coupon Code", field: "coupon_code", sortable: false },
    { name: "Event Id", field: "event_id", sortable: true },
    { name: "Title", field: "short_title", sortable: false },
    { name: "Percentage Discount", field: "discount", sortable: true },
    { name: "Nominal Discount", field: "nominal", sortable: true },
    { name: "Status", field: "status", sortable: true },
    { name: "Participant", field: "status", sortable: false },
    { name: "Actions", field: "coupon_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(listData).length > 0) {
      computedData = listData.map((v, index) => {
        var obj = {};
        //obj.Events = v.Events;
        obj.discount = (v.discount) ? parseInt(v.discount) : "";
        obj.nominal = (v.nominal) ? parseInt(v.nominal) : "";
        obj.coupon_code = (v.coupon_code) ? v.coupon_code : "";
        obj.coupon_id = (v.coupon_id) ? v.coupon_id : "";
        obj.event_id = (Object.values(v.Events).length > 0) ? v.Events[0].event_id : "";
        obj.short_title = (Object.values(v.Events).length > 0) ? v.Events[0].short_title : "";
        obj.status = (v.status) ? v.status : 1;
        obj.registration = v.Registration;
        obj.num = index + 1;
        return obj;
      })
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.values(listData).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          if (typeof value === 'object') {
            return Object.values(value).some(subValue =>
              String(subValue).toLowerCase().includes(search.toLowerCase())
            );
          }
          return String(value).toLowerCase().includes(search.toLowerCase());
        });
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort((a, b) => {
        const aValue = a[sorting.field];
        const bValue = b[sorting.field];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return reversed * aValue.localeCompare(bValue);
        } else if (aValue < bValue) {
          return reversed * -1;
        } else if (aValue > bValue) {
          return reversed * 1;
        } else {
          return 0;
        }
      });
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }

  }, [listData, search, sorting, currentPage]);



  const HandlerRemove = (coupon_id) => {
    const param = { coupon_id: coupon_id };
    const HandlerYes = () => {
      RemoveData(param);
    }

    openModal({ header: "Confirmation", message: <ConfirmRemove message={"Are you sure you want to delete this item?"} handler={HandlerYes} /> })
  };

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const RemoveData = (param) => {
    setRemove({ loading: true, data: [], message: "" });
    openModal({ message: "Please wait while the coupon is being deleted. " + <LoadingBars /> })
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/coupon/remove"
    ).then((response) => {
      if (response.error) {
        setRemove({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setRemove({
          loading: false,
          data: response.data,
          message: "Successfully Removed",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Removed"} />,
        });
        ReloadData();
      } else {
        setRemove({ loading: false, data: [], message: "Failed remove" });
        openModal({
          message: <MessageError message={"Failed remove"} />,
        });
      }
    });
  };

  ;

  return (
    <div className='table-responsive'>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
        <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
      </div>

      <table className="table table-row-dashed table-striped table-row-gray-200 align-middle gs-0 gy-4">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) =>
            setSorting({ field, order })
          }
        />
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>{v.num}</td>
                <td>
                  <div>
                    <span className="fw-bolder d-block fs-7">
                      {v.coupon_code}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span className="fw-bolder d-block fs-7">
                      {v.event_id}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span className="fw-normal d-block fs-8">
                      {decodeURIComponent(v.short_title)}
                    </span>
                  </div>
                </td>
                <td>
                  <span className="fw-normal d-block fs-6">
                    {(v.discount) ? (v.discount + '%') : ''}
                  </span>
                </td>
                <td>
                  <span className="fw-normal d-block fs-6">
                    {(v.nominal) ? formatRupiah(v.nominal) : ''}
                  </span>
                </td>
                <td>
                  {v.status === '0' &&
                    <span className="badge py-3 px-4 fs-7 badge-light-success">Unused</span>
                  }
                  {v.status === '1' &&
                    <div>
                      <span className="badge py-3 px-4 fs-7 d-block badge-light-info">Used</span>
                    </div>
                  }
                </td>
                <td>
                  {v.status === '1' &&
                    <div className="alert bg-light-info p-1">
                      <span className="fw-normal d-block fs-6">{v.registration.customer_id} / {v.registration.fullname}</span>
                      <span className="fw-normal d-block fs-6">{v.registration.email} / {v.registration.phone}</span>
                    </div>
                  }
                </td>
                <td>
                  {(v.status === '0' || v.status === null) ?
                    <div className="btn-group">
                      {widget_delete === true && (
                        <ButtonTersier className="btn-sm btn-icon btn-danger text-hover-danger" type="button" onClick={() => HandlerRemove(v.coupon_id)} title="Remove">
                          <i className="bi bi-trash"></i>
                        </ButtonTersier>
                      )}
                      <OverlayTrigger
                        delay={{ show: 250, hide: 400 }}
                        placement="top"
                        overlay={renderTooltip({ title: "Edit" })}
                      >
                        <Link
                          className="btn btn-sm  btn-light btn-icon"
                          type="button"
                          to={"update/" + v.coupon_id}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </Link>
                      </OverlayTrigger>
                    </div>
                    : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  )
}



