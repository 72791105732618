import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq, AxiosLibBillApp } from "../../helpers/AxiosLibs";
import { RemoveEmptyValue } from "../../helpers/CommonHelpers";
import { Link, Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import {
    MessageError,
    MessageSuccess,
    openModal,
} from "../layouts/modals/Modals";

import { AlertNofif } from "../layouts/alerts/AlertsUI";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../layouts/popovers/PopOvers";
import moment from "moment";
import { formatRupiah, toTitleCase } from "./components/FormAtributes";

export default function Banner({data}) {
    const context = useOutletContext();
   
    const navigate = useNavigate()

    const momentTime = (time) => {
        const [hours, minutes, seconds] = time.split(":");
        const timeMoment = moment({ hours, minutes, seconds });
        const formattedTime = timeMoment.format("h:mm a");
        return formattedTime;
      };
    
    const contacts = (contact) => {
        return Object.values(contact).length > 0
          ? contact.map((v, index) => (
            <div className="d-flex align-items-center" key={index}>
              <div className="symbol symbol-30px symbol-circle me-3">
                <span className="symbol-label" style={{ background: "#35C7FF" }}>
                  <i className="ki-duotone ki-user-square fs-5 text-white">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                  </i>
                </span>
              </div>
              <div className="text-white">
                <span className="fw-semibold opacity-100 d-block fs-8">Contact</span>
                <div className="fw-bold text-white fs-7">{v.fullname}</div>
                <div className="fw-bold text-white fs-7">{v.email}</div>
                <div className="fw-bold text-white fs-7">{v.phone}</div>
                <div className="fw-bold text-white fs-7">{v.dept_name}</div>
              </div>
            </div>
          ))
          : "";
      };
    return (
            <div
            className="card card-flush w-lg-800px"
            style={{
               background: 'linear-gradient(112.14deg, #3A7BD5 0%, #00D2FF 100%)'
            }}>
            <div className="card-body">
              <div className="row align-items-center h-100">
                <div className="col-12 ps-xl-10">
                  <div className="text-white mb-2 pt-1">
                  <span className="fs-2qx fw-bold">SWISS GERMAN UNIVERSITY</span>
                    <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-100"> {decodeURIComponent(data.short_title)}</span>
                    <span className="fs-1qx fw-bold">{decodeURIComponent(data.full_title)}</span>
                  </div>

                  <div className="m-0 mb-3">
                    <span className="fw-bold text-white d-block fs-8">
                      Location Address
                    </span>
                    <span className="fw-bold text-white fs-7">
                      {data.info_location}
                    </span>
                    <br />
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement="top"
                      overlay={renderTooltip({
                        title: "Click to open Google Maps",
                      })}
                    >
                      <div className="d-inline-block">
                        <span
                          className="fw-bold text-warning fs-7 cursor-pointer d-flex"
                          style={{ gap: 2 }}
                          onClick={() =>
                            window.open(data.info1, "_blank")
                          }
                        >
                          <i className="fas fa-map-marker-alt text-warning mt-1"></i>
                          View on Google Maps
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-3 mb-xl-3">
                    <div className="d-flex align-items-center me-5 me-xl-13">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label" style={{ background: "#35C7FF" }}>
                          <i className="ki-duotone ki-abstract-41 fs-5 text-white">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div className="text-white">
                        <span className="fw-semibold d-block fs-7 opacity-100">Event start | end</span>
                        <span className="fw-bold fs-7">
                          {moment(data.event_start).format("DD MMM YYYY")},{" "}
                          {momentTime(data.info_time_start)} |{" "}
                          {moment(data.event_end).format("DD MMM YYYY")},{" "}
                          {momentTime(data.info_time_end)}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label" style={{ background: "#35C7FF" }}>
                          <i className="ki-duotone ki-abstract-26 fs-5 text-white">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                      </div>
                      <div className="text-white">
                        <span className="fw-semibold opacity-100 d-block fs-8">Fee</span>
                        {data.Items.map((item, index) => (
                          <div className="fw-bold fs-7" key={index}>
                            <span>
                              <span className="fw-bold g-title">
                                {item.label ?? item.item_name}
                              </span>
                              <span className="g-dot fw-bold">:</span>
                              <span className="fw-bold g-value text-wrap p-0 m-0">
                                {formatRupiah(item.price)}
                              </span>
                            </span>
                          </div>
                        ))}

                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap d-grid gap-1 mb-0 mb-xl-0">
                    {contacts(data.Contacts)}
                  </div>

                </div>

              </div>
            </div>
          </div>
        
    );
}


