import React from 'react'
export function Footer() {

    return (
        <div id="kt_footer" className="app-footer">
            <div className="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
                <div className="text-gray-900 order-2 order-md-1">
                    <span className="text-muted fw-semibold me-1">2024&copy;</span>
                    <a href="https://sgu.ac.id" target="_blank" className="text-gray-800 text-hover-primary">SGU</a>
                </div>
            </div>
        </div>
    )
}
