import React, { useEffect, useRef, useState } from "react";
import {
  AxiosLibGlobalReq,
  AxiosLibBillApp,
} from "../../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../../helpers/CommonHelpers";
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../../RenderAccessUser";
import { AlertNofif } from "../../../layouts/alerts/AlertsUI";
import Moment from "moment";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "./Time.css";
import { Editor } from "@tinymce/tinymce-react";


const Token = AuthDecodeToken();

export function FormEventDetail(props) {
  const context = useOutletContext();
  const widget_create = RenderAccessWidgets(context.widgets, 30);
  const navigate = useNavigate();
  //let params = useParams();
  const item_id = props.id;
  let post = props.post;
  let setPost = props.setPost;
  const editorRef = useRef(null);
  const maxCharacters = 2000;
  const [characterCount, setCharacterCount] = useState(0);

  const countCharacters = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent.length;
  };

  const [other, setOther] = useState({
    status: false,
    data: "",
  });
  const [indexLocation, setIndexLocation] = useState(-1);

  const maps = [
    {
      name: "SGU Prominance Tower",
      link: "https://www.google.com/maps/place/The+Prominence+Office+Tower+Alam+Sutera/@-6.2256469,106.6556613,564m/data=!3m1!1e3!4m6!3m5!1s0x2e69fbde36d33045:0x61eda0942090e2fd!8m2!3d-6.2252031!4d106.6548729!16s%2Fg%2F11f2wdpdy8?entry=ttu",
    },
    {
      name: "Other",
      link: "",
    },
  ];

  const dateNow = Moment().toDate();
  const toUpperCase = (str) => {
    return str.toUpperCase();
  };

  const handleShort = (event) => {
    const inputValue = event.target.value;
    const formattedValue = toUpperCase(inputValue);
    setPost({ ...post, short_title: formattedValue });
  };
  const handleFull = (event) => {
    const inputValue = event.target.value;
    const formattedValue = toUpperCase(inputValue);
    setPost({ ...post, full_title: formattedValue });
  };
  const handleTimeStart = (newTime) => {
    setPost((prevState) => ({ ...post, info_time_start: newTime }));
  };
  const handleTimeEnd = (newTime) => {
    setPost((prevState) => ({ ...post, info_time_end: newTime }));
  };



  return widget_create === true ? (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Detail Event</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Please fill up the form with correctly
          </span>
        </h3>
      </div>
      <div className="card-body">
        <div className="mb-0">
          <div className="mb-3">
            <label className="required fw-bolder">Short Title </label>
            <input
              type="text"
              name="ShortTitle"
              className="form-control form-control-sm"
              value={decodeURIComponent(post.short_title)}
              onChange={handleShort}
            />
          </div>
          <div className="mb-3">
            <label className="required fw-bolder">Full Title</label>
            <input
              type="text"
              name="FullTitle"
              className="form-control form-control-sm"
              value={decodeURIComponent(post.full_title)}
              onChange={handleFull}
            />
          </div>
          <div className="mb-3">
            <label className="required fw-bolder">Description</label>


            <Editor
              apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
              className="required"
              value={decodeURIComponent(post.description)}
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={(newValue, editor) => {
                setPost({ ...post, description: newValue });
              }}
              init={{
                height: 500,
                menubar: false,
                plugins: "link",
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic link alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | " +
                  "removeformat | help",
                automatic_uploads: true,
                default_link_target: "_blank",
                link_assume_external_targets: true,
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                setup: (editor) => {
                  editor.on("keydown", (e) => {
                    const content = editor.getContent();
                    const currentLength = countCharacters(content);

                    if (currentLength >= maxCharacters) {
                      if (e.key === 'Backspace' || e.key === 'Delete') {
                        // Allow backspace and delete when at or over the limit
                        return;
                      }

                      if (e.metaKey || e.ctrlKey) {
                        // Allow copy/paste operations
                        return;
                      }

                      // Prevent adding more characters
                      e.preventDefault();
                      e.stopPropagation();
                      alert(`Maximum ${maxCharacters} characters allowed. Please remove some content before adding more.`);
                    }
                  });

                  editor.on("keyup change", () => {
                    const content = editor.getContent();
                    const currentLength = countCharacters(content);

                    if (currentLength > maxCharacters) {
                      // If content is over limit after paste, trim it
                      let trimmedContent = content;
                      while (countCharacters(trimmedContent) > maxCharacters) {
                        trimmedContent = trimmedContent.slice(0, -1);
                      }
                      editor.setContent(trimmedContent);
                      setCharacterCount(maxCharacters);
                    } else {
                      setCharacterCount(currentLength);
                    }
                  });
                },
              }}
            />

            <p className="text-muted fst-italic">
              Maximum Characters: {characterCount}/{maxCharacters}
            </p>
          </div>

          <div className="row gx-10 mb-3">
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Event Date Start
              </label>
              <div className="mb-5">
                <DatePicker
                  dateFormat={"yyyy-MM-dd"}
                  minDate={post.event_start}
                  className="form-control form-control-sm pe-15"
                  selected={post.event_start}
                  placeholderText="Choose Date"
                  onChange={(date) => setPost({ ...post, event_start: date })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Event Date End
              </label>
              <div className="mb-3">
                <DatePicker
                  dateFormat={"yyyy-MM-dd"}
                  minDate={post.event_start}
                  className="form-control form-control-sm pe-15"
                  selected={post.event_end}
                  placeholderText="Choose Date"
                  onChange={(date) => setPost({ ...post, event_end: date })}
                />
              </div>
            </div>
          </div>
          <div className="row gx-10 mb-3">
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Event Time Start
              </label>
              <div className="mb-3">
                <div className="input-group input-time">
                  <input
                    type="time"
                    id="time-select"
                    value={post.info_time_start}
                    onChange={(e) => handleTimeStart(e.target.value)}
                    style={{ display: "none" }} // Initially hidden
                  />

                  <TimePicker
                    onChange={handleTimeStart}
                    value={post.info_time_start}
                    disableClock={true}
                    clearIcon={true}
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Event Time End
              </label>
              <div className="mb-3">
                <div className="input-group input-time">
                  <input
                    type="time"
                    id="time-select"
                    value={post.info_time_end}
                    onChange={(e) => handleTimeEnd(e.target.value)}
                    style={{ display: "none" }} // Initially hidden
                  />

                  <TimePicker
                    onChange={handleTimeEnd}
                    value={post.info_time_end}
                    disableClock={true}
                    clearIcon={true}
                    className="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-10 mb-3">
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Location Name
              </label>
              <div className="mb-3">
                <select
                  name="status"
                  id=""
                  className="form-select form-select-sm"
                  required
                  onChange={(e) => {
                    e.target.value === maps.length - 1 + ""
                      ? setOther({ ...other, status: true })
                      : setOther({ ...other, status: false });
                    e.target.value > -1 &&
                      console.log(e.target.value, maps[e.target.value].name);
                    e.target.value > -1 &&
                      setPost({
                        ...post,
                        info_location: maps[e.target.value].name, info1: maps[e.target.value].link
                      });
                  }}
                >
                  <option value={-1} disabled selected>Choose location</option>
                  {maps.map((map, i) => (
                    <option key={i} value={i}>
                      {map.name}
                    </option>
                  ))}
                </select>
                {other.status && (
                  <div className="mt-5">
                    <input
                      type="text"
                      placeholder="New location name"
                      className="form-control form-control-sm"
                      value={other.data}
                      onChange={(e) => {
                        setOther({ ...other, data: e.target.value });
                        setPost({ ...post, info_location: e.target.value });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <label className={`form-label fs-6 fw-bold mb-3 ${other.status ? "text-success" : "text-danger"}`}>
                Link
              </label>
              <div className="mb-3">
                <input
                  type="text"
                  name="Info1"
                  className={`form-control form-control-sm ${!other.status && "text-gray-500"}`}
                  value={post.info1}
                  readOnly={!other.status}
                  // value={other}
                  placeholder="Google maps link"
                  onChange={(e) => setPost({ ...post, info1: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* <div className="row gx-10 mb-3">
            <div className="col-lg-6">
              <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                Information 2
              </label>
              <div className="mb-3">
                <input
                  type="text"
                  name="Info2"
                  className="form-control form-control-sm"
                  value={post.info2}
                  onChange={(e) => setPost({ ...post, info2: e.target.value })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="form-label fs-6 fw-bold text-gray-700 mb-3">
                Information 3
              </label>
              <div className="mb-3">
                <input
                  type="text"
                  name="Info3"
                  className="form-control form-control-sm"
                  value={post.info3}
                  onChange={(e) => setPost({ ...post, info3: e.target.value })}
                />
              </div>
            </div>
          </div> */}
          <div className="row gx-10 mb-3">
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Registration Open
              </label>
              <div className="mb-5">
                <DatePicker
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  minDate={post.reg_start}
                  className="form-control form-control-sm pe-15"
                  selected={post.reg_start}
                  placeholderText="Choose Date"
                  timeFormat="HH:mm:ss"
                  timeIntervals={15}
                  showTimeSelect
                  onChange={(date) => setPost({ ...post, reg_start: date })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <label className="required form-label fs-6 fw-bold text-gray-700 mb-3">
                Registration Close
              </label>
              <div className="mb-3">
                <DatePicker
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  minDate={post.reg_start}
                  className="form-control form-control-sm pe-15"
                  selected={post.reg_end}
                  placeholderText="Choose Date"
                  timeFormat="HH:mm:ss"
                  timeIntervals={15}
                  showTimeSelect
                  onChange={(date) => setPost({ ...post, reg_end: date })}
                />
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  ) : (
    <AlertNofif
      title={"Information"}
      messages={
        "You don't have permission to access this resource. Please contact the administrator."
      }
      color="danger"
    />
  );
}
