import React from 'react'

export function MGroups() {
    return (
        <div className="row">
            <div className="col-xl-4">
                <div className='card card-xl-stretch mb-5 mb-xl-8 bg-light'>
                    <div className="card-header border-0 py-0">
                        <h3 className="card-title align-items-start flex-column mb-0">
                            <span className="card-label fw-bold fs-3 mb-1">Master Groups</span>
                        </h3>
                    </div>
                    <div className="card-body py-0">
                        ...
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <div className='card card-xl-stretch mb-5 mb-xl-8'>
                    <div className="card-header border-0 py-0">
                        <h3 className="card-title align-items-start flex-column mb-0">
                            <span className="card-label fw-bold fs-3 mb-1">Access Page</span>
                        </h3>
                    </div>
                    <div className="card-body py-0">
                        ...
                    </div>
                </div>
            </div>
            <div className="col-xl-4">
                <div className='card card-xl-stretch mb-5 mb-xl-8'>
                    <div className="card-header border-0 py-0">
                        <h3 className="card-title align-items-start flex-column mb-0">
                            <span className="card-label fw-bold fs-3 mb-1">Access Widget</span>
                        </h3>
                    </div>
                    <div className="card-body py-0">
                        ...
                    </div>
                </div>
            </div>
        </div>
    )
}
