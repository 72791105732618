import React from "react";

export function WNotifications() {
  return (
    <div className="card card-xl-stretch mb-5 mb-xl-8">
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-gray-900">Notifications</h3>
      </div>
      <div className="card-body pt-0">
        <div className="list-notif">
            <ItemNotif title={"Access Request"} value={16} desc={"Pending User Access Requests"} link={""} color="warning" />
            <ItemNotif title={"Request Notif"} value={80} desc={"Pending Campaign Approvals"} link={""} color="info" />
        </div>
      </div>
    </div>
  );
}

const ItemNotif = ({ title, desc, value, link, color="warning" }) => {
  return (
    <div className={"d-flex align-items-center bg-light-"+color+" rounded p-5 mb-7"}>
      <span className={"me-5 text-"+color}>
        <i className={"bi bi-layers-half fs-1 me-5 text-"+color}></i>
      </span>
      <div className="flex-grow-1 me-2">
        <span className="fw-bold text-gray-800 text-hover-primary fs-6">
          {title}
        </span>
        <span className="text-muted fw-semibold d-block">{desc}</span>
      </div>
      <span className={"fw-bold py-1 fs-2x text-"+color}>{value}</span>
    </div>
  );
};
