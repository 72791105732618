import React, { useMemo, useState } from "react";
// import zlib from "zlib"
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../layouts/datatables/MYDatatables";
import moment from "moment";
import {
  ConfirmRemove,
  MessageError,
  MessageSuccess,
  openModal,
} from "../layouts/modals/Modals";
import { Link, useOutletContext } from "react-router-dom";
//import { RenderAccessWidgets } from '../RenderAccessUser';
import { LoadingBars } from "../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReq } from "../../helpers/AxiosLibs";
import { ButtonTersier } from "../layouts/buttons";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../layouts/popovers/PopOvers";
import { formatRupiah } from "./FormAtributes";
import { AuthDecodeToken } from "../authentications/reducers/AuthRedux";
import sign from "jwt-encode";
import packageJSON from "../../../../package.json";

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const Token = AuthDecodeToken();
  const panjang = Object.values(data).length;
  //const widget_delete = RenderAccessWidgets(context.widgets, 32);

  const [colorItems, setColorItems] = useState([]);

  const generatePastelColor = () => {
    // Generate a pastel color by using higher base values
    const r = Math.floor(Math.random() * 55 + 200); // 200-255
    const g = Math.floor(Math.random() * 55 + 200); // 200-255
    const b = Math.floor(Math.random() * 55 + 200); // 200-255

    // Convert to hex
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  };

  function generatePastelGradient() {
    // Generate a pastel color
    const pastelColor = () => {
      const hue = Math.floor(Math.random() * 360);
      const saturation = Math.floor(Math.random() * 25) + 70; // 70-95%
      const lightness = Math.floor(Math.random() * 15) + 75; // 75-90%
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    // Generate random angle between 0 and 360 degrees
    const angle = Math.floor(Math.random() * 361);

    // Generate two pastel colors
    const color1 = pastelColor();
    const color2 = pastelColor();

    // Return the style object
    return {
      background: `linear-gradient(${angle}deg, ${color1} 0%, ${color2} 100%)`,
    };
  }

  const momentTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const timeMoment = moment({ hours, minutes, seconds });
    const formattedTime = timeMoment.format("h:mm a");
    return formattedTime;
  };

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const headers = [
    { name: "No", field: "event_id", sortable: false },
    { name: "Title", field: "short_title", sortable: true },
    { name: "Description", field: "description", sortable: true },
    { name: "Event Start", field: "event_start", sortable: true },
    { name: "Location", field: "location", sortable: true },
    { name: "Actions", field: "event_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(listData).length > 0) {
      computedData = listData.map((v, index) => {
        var obj = v;
        //obj.status_name = v.status === 1 ? "Draft":"Sended"
        obj.num = index + 1;
        return obj;
      });
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.values(listData).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          if (typeof value === "object") {
            return Object.values(value).some((subValue) =>
              String(subValue).toLowerCase().includes(search.toLowerCase())
            );
          }
          return String(value).toLowerCase().includes(search.toLowerCase());
        });
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [listData, search, sorting, currentPage]);

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });



  const items_fee = (items) => {
    return Object.values(items).length > 0
      ? items.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-800 fw-bold">
            <span className="ms-n1">
              {v.label ? v.label : v.code_name} : {formatRupiah(v.price)}
            </span>
          </span>
        </div>
      ))
      : "";
  };
  const firstLetter = (nm) => {
    return nm[0];
  };

  const createRegistrationLink = (eventId) => {
    const payload = { event_id: eventId };
    //const token = sign(payload, packageJSON.system_param.PublicKey);
    const token = btoa(JSON.stringify(payload)).replace(/\//g, '_').replace(/\+/g, '-');
    return `/registration/${token}`;
  };

  const createButtonLink = (tglStart, tglEnd, eventid) => {
    const currentDate = moment(); // dapatkan tanggal saat ini
    const startDate = moment(tglStart);
    const endDate = moment(tglEnd);

    const isWithinDateRange = currentDate.isBetween(startDate, endDate); // periksa apakah tanggal saat ini berada dalam rentang tanggal

    return (
      <div>
        {isWithinDateRange && (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({
              title: "Apply this event",
            })}
          >
            <Link
              className="btn px-1 me-6 mb-1"
              type="button"
              to={createRegistrationLink(eventid)}
            >
              <div className="symbol symbol-35px symbol-circle d-flex flex-column align-items-center justify-content-center">
                <span className="symbol-label bg-light">
                  <i className="ki-duotone ki-verify fs-1 text-success">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                </span>
                <div className="fw-bold fs-4 text-dark">
                  Apply
                </div>
              </div>
            </Link>
          </OverlayTrigger>
        )}
      </div>
    );
  };

  const contact_event = (contacts) => {
    return Object.values(contacts).length > 0
      ? contacts.map((v, index) => (
        <div className="m-0" key={index}>                
        <div
          
          className="px-1 me-1 mb-3"

        >
          <span className="symbol-label fs-8 text-dark fw-bold">
          Contact Person {index+1}
          </span>
          <div className="fw-bold fs-8 text-dark">Name : {v.fullname}</div>
          <div className="fw-bold fs-8 text-dark">Department : {v.dept_name}</div>
          <div className="fw-bold fs-8 text-dark">Email : {v.email}</div>
          <div className="fw-bold fs-8 text-dark">Phone : {v.phone}</div>
        </div>
        </div>
      ))
      : "";
  };

  const coupons = (coupon) => {
    return Object.values(coupon).length > 0
      ? coupon.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-700 fw-bold">
            <span className="ms-n1 p-1">
              {v.coupon_code} : {v.discount * 100}%
            </span>
          </span>
        </div>
      ))
      : "";
  };



  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>


      {Object.values(ResultData).length > 0 ? (
        ResultData.map((v, index) => (
          <div className="card card-flush h-xl-100 mb-5" key={index}>
            <div
              className="card-body py-2"
              style={generatePastelGradient()}
            >
              <div className="row gx-9 h-100">
                <div className="col-12 col-sm-12 mb-10 mb-sm-0">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-1">
                      <div className="flex-shrink-0 me-0 rounded ">
                        <span
                          style={{
                            background:
                              "linear-gradient(to right, #0e1c26 0%, #0ab6f8 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            display: "inline-block",
                          }}
                        >
                          <span className=" fs-6 fw-bold">
                            {decodeURIComponent(v.short_title)}
                          </span>
                          <span className="fw-bold  fs-7 mb-1 d-block">
                            {decodeURIComponent(v.full_title)}
                          </span>
                        </span>

                      </div>


                      <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px symbol-circle me-3">
                            <span className="symbol-label bg-warning">
                              <i className="ki-duotone ki-route fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                              </i>
                            </span>
                          </div>
                          <div className="m-0">
                            <span className="fw-bold text-gray-700 d-block fs-8">
                            Location Address
                            </span>
                            <span className="fw-bold text-gray-800 fs-7">
                              {v.info_location}
                            </span>
                            <br />
                            <OverlayTrigger
                              delay={{ show: 250, hide: 400 }}
                              placement="top"
                              overlay={renderTooltip({
                                title: "Click to open Google Maps",
                              })}
                            >
                              <div className="d-inline-block">
                                <span
                                  className="fw-bold text-gray-800 fs-7 cursor-pointer d-flex"
                                  style={{ gap: 2 }}
                                  onClick={() =>
                                    window.open(v.info1, "_blank")
                                  }
                                >
                                  <i className="fas fa-map-marker-alt text-black mt-1"></i>
                                  View on Google Maps
                                </span>
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-2">
                      <span className="text-gray-700 fs-8 fw-bold me-2 d-block lh-1 pb-1">
                        Description :
                      </span>
                      <span className="fw-semibold text-gray-800 fs-7 mb-2 d-block">
                        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(v.description) }} />

                      </span>
                      {/* <span className="fw-semibold text-gray-800 fs-7 mb-2 d-block">
                                  {v.info1
                                    ? decodeURIComponent(v.info1)
                                    : v.info1}
                                </span>
                                <span className="fw-semibold text-gray-800 fs-7 mb-2 d-block">
                                  {v.info2
                                    ? decodeURIComponent(v.info2)
                                    : v.info2}
                                </span>
                                <span className="fw-semibold text-gray-800 fs-7 mb-2 d-block">
                                  {v.info3
                                    ? decodeURIComponent(v.info3)
                                    : v.info3}
                                </span> */}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="border border-gray-600 border-dashed rounded min-w-90px w-90 py-4 px-1 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px symbol-circle me-3">
                            <span className="symbol-label bg-info">
                              <i className="ki-duotone ki-calendar-tick fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                              </i>
                            </span>
                          </div>
                          <div className="m-0">
                            <span className="fs-6 text-gray-800 fw-bold">
                              {moment(v.event_start).format(
                                "DD MMM YYYY"
                              )}
                              , {momentTime(v.info_time_start)}
                            </span>
                            <div className="fw-semibold text-gray-700">
                              Event Start
                            </div>
                            <span className="fs-6 text-gray-800 fw-bold">
                              {moment(v.event_end).format("DD MMM YYYY")},{" "}
                              {momentTime(v.info_time_end)}
                            </span>
                            <div className="fw-semibold text-gray-700">
                              Event End
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="border border-gray-600 border-dashed rounded min-w-90px w-90 py-2 px-1 me-6 mb-3 ">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px symbol-circle me-3">
                            <span className="symbol-label bg-info">
                              <i className="ki-duotone ki-calendar-tick fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                              </i>
                            </span>
                          </div>
                          <div className="m-0">
                            <span className="fs-6 text-gray-800 fw-bold">
                              {moment(v.reg_start).format(
                                "DD MMM YYYY, hh:mm A"
                              )}
                            </span>
                            <div className="fw-semibold text-gray-700">
                              Registration Open
                            </div>
                            <span className="fs-6 text-gray-800 fw-bold">
                              {moment(v.reg_end).format(
                                "DD MMM YYYY, hh:mm A"
                              )}
                            </span>
                            <div className="fw-semibold text-gray-700">
                              Registration Close
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="min-w-90px w-90 py-2 px-1 mb-3">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-30px symbol-circle me-3">
                            <span className="symbol-label bg-info">
                              <i className="ki-duotone ki-bill fs-1 text-white">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                              </i>
                            </span>
                          </div>
                          <div className="m-0">
                            {items_fee(v.Items)}
                            <div className="fw-semibold text-gray-700">
                            Event Fees
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>


                    <div className="d-flex justify-content-center flex-wrap">
                            <div className="d-flex align-items-center">
                              {v.logo && (
                                <img
                                  src={v.logo} // The Base64 string is used directly in the `src` attribute
                                  alt="Event Logo"
                                  style={{ maxWidth: '500px', maxHeight: '500px' }} // Restrict image size
                                />
                              )}
                              </div>
                            </div>

                    <div className="d-flex justify-content-between flex-wrap">
                    {contact_event(v.Contacts)}
                     
                      <div className="m-0">
                        {createButtonLink(v.reg_start, v.reg_end, v.event_id)}

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <span className="fs-6 text-gray-800 fw-bold">No record found</span>

      )}
      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <tbody>
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={1}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  );
}
