const AlertNofif = ({title, messages, color="danger"}) => {
  return (
    <div className={"alert alert-"+color+" d-flex align-items-center p-5 mb-5"}>
      <span className={"svg-icon svg-icon-2hx svg-icon-"+color+" me-3"}>
        <i className={"bi bi-shield-fill-exclamation fs-2x text-"+color+""}></i>
      </span>

      <div className="d-flex flex-column">
        <h5 className="mb-1">{title}</h5>
        <span>{messages}</span>
      </div>

      <button
        type="button"
        className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
        data-bs-dismiss="alert"
      >
        <span className="svg-icon svg-icon-2x svg-icon-light">
          <i className="bi bi-x-lg"></i>
        </span>
      </button>
    </div>
  );
};

export {AlertNofif}