import React from 'react'
import { Link } from 'react-router-dom'
//import { toAbsoluteUrl } from '../../../helpers/AssetsHelpers'
import errImg from "../../../../_metronic//media/auth/404-error.png";


export default function Error404() {
    return (
        <div className="d-flex flex-column flex-center text-center p-10">
            <div className='card card-flush  w-lg-650px py-5'>
                <div className="card-body py-15 py-lg-20">
                    <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">Oops!</h1>
                    <div className="fw-semibold fs-6 text-gray-500 mb-7">We can't find that page.</div>
                    <div className="mb-3">
                        <img src={errImg} alt="Error 404" className='mw-100 mh-300px theme-light-show' />
                    </div>
                    <div className="mb-0">
                        <Link to={'/dashboard'} className='btn btn-sm btn-primary'>Return Dashboard</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
