import React, { useEffect, useState } from "react";
import TableData from "./TableData";
//import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../../layouts/loadbars/LoadingBars";
import { ButtonCreateContact, NavTabs } from "./components/FormAtributes";
import { useOutletContext } from "react-router-dom";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { GET_ALL_DATA } from "./Apis";
import { CardBox } from "../../layouts/cards/CardUI";
import { openModal } from '../../layouts/modals/Modals';
import GenerateItem from './GenerateItem';


export function Items() {
    const context = useOutletContext();
    //console.log(context)
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const ReloadData = async () => {
        const results = await GET_ALL_DATA();
        if (results.data && Object.values(results.data).length > 0) {
            setCampaigns({ loading: false, data: results.data, message: "" });
        } else if (results.message) {
            setCampaigns({ loading: false, data: [], message: results.message });
        } else {
            setCampaigns({ loading: false, data: [], message: "No record found" });
        }
    };

    const HandlerSendItem = (jmlh) => {
        return openModal({ header: "Import Item Accurate", message: <GenerateItem jmlh={jmlh} ReloadData={ReloadData}/> });
    }

    useEffect(() => {
        ReloadData();
    }, []);

    const widget_list = RenderAccessWidgets(context.widgets, 24);
    const widget_create = RenderAccessWidgets(context.widgets, 25);
    const widget_import = RenderAccessWidgets(context.widgets, 28);
    return (
        (widget_list === true ? (
            <CardBox>
                <CardBox.Header>
                    <span className="card-label fw-bold fs-3 mb-1">Accurate items</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">List of all accurate items</span>
                </CardBox.Header>
                <CardBox.Toolbar>
                    {widget_import === true &&
                        <div className="btn-group">
                            <button className="btn btn-sm bg-info fs-7" type="button" onClick={() => HandlerSendItem(Object.values(campaigns.data).length)}>
                                <span className="text-white fw-bold">
                                    Import
                                </span>
                            </button>
                            <button className="btn btn-sm btn-icon btn-light" type="button" onClick={() => HandlerSendItem(Object.values(campaigns.data).length)}>
                                <i className="bi-layer-backward"></i>
                            </button>
                        </div>



                    }
                </CardBox.Toolbar>
                <CardBox.Body>
                    {campaigns.loading ? (
                        <LoadingBars />
                    ) : campaigns.message ? (
                        <div className="p-3 border rounded text-danger bg-light-danger">
                            {campaigns.message}
                        </div>
                    ) : Object.values(campaigns.data).length > 0 ? (
                        <TableData data={campaigns.data} ReloadData={ReloadData} />
                    ) : (
                        <NoRecordFound message="No record found" />
                    )}
                </CardBox.Body>
            </CardBox >
        ) : widget_list)
    );


}


