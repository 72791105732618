import React, { Component } from "react";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { WParticipants, WNotifications } from "./widgets";

export class Dashboards extends Component {
  constructor(props) {
    super(props);
    var Token = AuthDecodeToken();
    this.state = {
      token: Token,
    };
  }
  render() {
    return (
      <div id="dashboard">
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
          <div className="col-xl-8">
            <WParticipants />
          </div>
          <div className="col-xl-4">
            <WNotifications />
          </div>
        </div>
      </div>
    );
  }
}
