import React, { useEffect, useState } from 'react'
import { AxiosLibGlobalReqModule } from '../../../../helpers/AxiosLibs';
import { AuthDecodeToken } from '../../../authentications/reducers/AuthRedux';
import { LoadingBars, NoRecordFound } from '../../../layouts/loadbars/LoadingBars';
import TableData from './TableData';

export function MGroup() {
    const Token = AuthDecodeToken();
    const [mails, setMails] = useState({ loading: false, data: [], message: "" });
    const RDB_GROUP_GOOGLE = () => {
        setMails({ loading: true, data: [], message: "" })
        var keys = {
            AppId: Token.AppID,
            AppEnv: Token.AppEnv,
            Secretkey: Token.Secretkey,
        };
        const parameter = {
            header: keys,
            body: { target_data: "RDB_GROUP_GOOGLE" },
            method: "post",
        };

        AxiosLibGlobalReqModule(parameter, process.env.REACT_APP_ESTES_API + process.env.REACT_APP_ESTES_API_PATH_REQUEST).then((response) => {
            if (response.error) {
                setMails({ loading: false, data: [], message: 'Your session has been expired. Please RE-SIGN IN again.' })
            } else if ("message" in response.response) {
                setMails({ loading: false, data: [], message: response.response.message.user })
            } else {
                if (response.response.data) {
                    setMails({ loading: false, data: response.response.data, message: "" })
                } else {
                    setMails({ loading: false, data: response.response.data, message: "No record found" })
                }
            }
        });
    }

    useEffect(() => {
        RDB_GROUP_GOOGLE();
    }, [])

    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Google Group</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">List of recepients by group user</span>
                </h3>
            </div>
            <div className="card-body py-3">
                {mails.loading ? <LoadingBars /> : mails.message ? (
                    <div className="p-3 border rounded text-danger bg-light-danger">
                        {mails.message}
                    </div>
                ) : (
                    Object.values(mails.data).length > 0 ? (
                        <TableData data={mails.data} ReloadData={RDB_GROUP_GOOGLE} />
                    ) : <NoRecordFound message="No record found" />
                )}
            </div>
        </div>
    )
}
