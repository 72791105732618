import React from 'react'

const CardBox = ({ children }) => {
    let header, toolbar, body;

    React.Children.forEach(children, child => {
        if (child.type === CardBox.Header) {
            header = child;
        } else if (child.type === CardBox.Toolbar) {
            toolbar = child;
        } else if (child.type === CardBox.Body) {
            body = child;
        }
    });

    return (
        <div className="card card-xl-stretch mb-5 mb-xl-8">
            {header && (
                <div className="card-header border-0 py-0">
                    {header}
                    {toolbar && (
                        <div className="card-toolbar">
                            {toolbar}
                        </div>
                    )}
                </div>
            )}
            <div className="card-body pt-0">
                {body}
            </div>
        </div>
    );
};

CardBox.Header = ({ children }) => (
    <h3 className="card-title align-items-start flex-column mb-0">
        {children}
    </h3>
);

CardBox.Toolbar = ({ children }) => (
    <div>{children}</div>
);

CardBox.Body = ({ children }) => (
    <div>{children}</div>
);

export { CardBox }