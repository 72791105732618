import React from 'react'

const ButtonPrimary = (props) =>{
    const { children, className, ...others } = props;
    const class_name = `btn btn-primary ${className}`;
    return (
        <button className={class_name}  {...others}>
            {children}
        </button>
    )
}

const ButtonDanger = (props) =>{
    const { children, className, ...others } = props;
    const class_name = `btn btn-danger ${className}`;
    return (
        <button className={class_name}  {...others}>
            {children}
        </button>
    )
}

const ButtonTersier = (props) =>{
    const { children, className, ...others } = props;
    const class_name = `btn btn-light ${className}`;
    return (
        <button className={class_name}  {...others}>
            {children}
        </button>
    )
}

export {ButtonPrimary, ButtonDanger, ButtonTersier}
