import React, { useEffect, useState } from "react";
import TableData from "./TableData";
//import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../../layouts/loadbars/LoadingBars";
import { ButtonCreateContact, NavTabs } from "./components/FormAtributes";
import { useOutletContext } from "react-router-dom";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { GET_ALL_DATA } from "./Apis";
import { CardBox } from "../../layouts/cards/CardUI";

export function Contacts() {
    const context = useOutletContext();
    //console.log(context)
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const ReloadData = async () => {
        const results = await GET_ALL_DATA();
        if (results.data && Object.values(results.data).length > 0) {
            setCampaigns({ loading: false, data: results.data, message: "" });
        } else if (results.message) {
            setCampaigns({ loading: false, data: [], message: results.message });
        } else {
            setCampaigns({ loading: false, data: [], message: "No record found" });
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    const widget_list = RenderAccessWidgets(context.widgets, 20);
    const widget_create = RenderAccessWidgets(context.widgets, 21);

    return (
        (widget_list === true ? (
            <CardBox>
                <CardBox.Header>
                    <span className="card-label fw-bold fs-3 mb-1">Master contacts</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">List of all contacts</span>
                </CardBox.Header>
                <CardBox.Toolbar>
                    {widget_create === true && <ButtonCreateContact />}
                </CardBox.Toolbar>
                <CardBox.Body>
                    {campaigns.loading ? (
                        <LoadingBars />
                    ) : campaigns.message ? (
                        <div className="p-3 border rounded text-danger bg-light-danger">
                            {campaigns.message}
                        </div>
                    ) : Object.values(campaigns.data).length > 0 ? (
                        <TableData data={campaigns.data} ReloadData={ReloadData} />
                    ) : (
                        <NoRecordFound message="No record found" />
                    )}
                </CardBox.Body>
            </CardBox>
        ) : widget_list)
    );


}


