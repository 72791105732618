import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { LoadingBars } from "../../../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReqModule } from "../../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { GroupByKey } from "../../../../helpers/CommonHelpers";
import moment from "moment";
import { AlertNofif } from "../../../layouts/alerts/AlertsUI";
import { ButtonPrimary } from "../../../layouts/buttons";
import { AxiosLibGlobalReq } from "../../../../helpers/AxiosLibs";

export function WParticipants() {
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const [dataset, setDataSet] = useState([]);
    const Token = AuthDecodeToken();
    const GET_NOTIF = () => {
        setCampaigns({ loading: true, data: [], message: "" });
        var keys = {
            AppId: Token.AppID,
            AppEnv: Token.AppEnv,
            Secretkey: Token.Secretkey,
        };
        const parameter = {
            header: keys,
            body: {},
            method: "get",
        };

        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/regis"
        ).then((response) => {

            if (response.error) {
                setCampaigns({
                    loading: false,
                    data: [],
                    message: "error.",
                });
            } else if (response.data && response.status === 200) {
                var results = response.data;
                console.log(results)
                if (results) {
                    var updateParam = results.map((v) => {
                        var obj = v;
                        obj.created_at = moment(v.created_at).format(
                            "YYYY-MM-DD"
                        );
                        return obj;
                    });
                    updateParam.sort((a, b) =>
                        a.created_at > b.created_at ? 1 : -1
                    );
                    setCampaigns({
                        loading: false,
                        data: response.data,
                        message: "",
                    });
                    var groupByDate = GroupByKey(updateParam, "created_at");
                    if (Object.keys(groupByDate).length > 0) {
                        var labels = [];
                        var datas = [];
                        Object.keys(groupByDate).map((k) => {
                            labels.push(k);
                            datas.push(Object.values(groupByDate[k]).length);
                        });
                        setDataSet({ labels, datas });
                    }
                } else {
                    setCampaigns({
                        loading: false,
                        data: response.data,
                        message: "No record found",
                    });
                }
            }
        });
    };

    useEffect(() => {
        GET_NOTIF();
    }, []);


    console.log(dataset)

    return (
        <div className="card card-xl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
                        {Object.values(campaigns.data).length}
                    </span>
                    <span className="text-muted mt-1 fw-semibold fs-7">
                        Total Registration
                    </span>
                </h3>
            </div>
            <div className="card-body py-3">
                {campaigns.loading ? (
                    <LoadingBars />
                ) : campaigns.message ? (
                    <>
                        <AlertNofif messages={campaigns.message} title={"Error"} />
                        <div className="text-center">
                            <ButtonPrimary onClick={() => GET_NOTIF()}>
                                <i className="bi bi-arrow-clockwise fs-2x"></i>
                                Click here to try again
                            </ButtonPrimary>
                        </div>
                    </>
                ) : Object.values(campaigns.data).length > 0 ? (
                    <>
                        <CampaignChart data={dataset} />
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

const CampaignChart = ({ data }) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
            },
        },
    };
    const labels = data.labels;
    const dataset = {
        labels,
        datasets: [
            {
                fill: true,
                label: "Registration",
                data: data.datas,
                borderColor: "rgb(53, 162, 235)",
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
        ],
    };
    return <Line options={options} data={dataset} />;
};

const CampaignTables = ({ data }) => {
    return (
        <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                <thead>
                    <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                        <th width={100}>Date Publish</th>
                        <th>Campaigns</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map((v, index) => (
                        <tr key={index}>
                            <td>{moment(v).format("DD MMM YYYY")}</td>
                            <td>
                                {Object.values(data[v]).length > 0
                                    ? data[v].map((i, index) => (
                                        <span
                                            className={
                                                "badge m-1 " +
                                                (i.status === "FINISH"
                                                    ? "badge-light-primary"
                                                    : "badge-danger")
                                            }
                                            key={index}
                                        >
                                            {decodeURIComponent(i.subject)}
                                        </span>
                                    ))
                                    : ""}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
