import React, { useEffect, useState } from "react";
import TableData from "./TableData";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../../layouts/loadbars/LoadingBars";
import { ButtonCreateCampaign, NavTabs } from "./components/CampaignAtributes";
import { useOutletContext } from "react-router-dom";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { GET_ALL_CAMPAIGN } from "./Apis";
import { CardBox } from "../../layouts/cards/CardUI";

export function Campaigns() {
  const context = useOutletContext();
  //console.log(context)
  const [campaigns, setCampaigns] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [campaignsSend, setCampaignSend] = useState([]);
  const [campaignsDraft, setCampaignDraft] = useState([]);

  const FetchAllData = () => {
    setCampaigns({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: {},
      method: "get",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/campaigns"
    ).then((response) => {
      if (response.error) {
        setCampaigns({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        var results = response.data;
        results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
        const sendedData = results.filter((item) => item.status === 2);
        setCampaignSend(sendedData);
        const draftData = results.filter((item) => item.status === 1);
        setCampaignDraft(draftData);
        setCampaigns({ loading: false, data: results, message: "" });
      } else {
        setCampaigns({ loading: false, data: [], message: "No record found" });
      }
    });
  };

  const ReloadData = async () => {
    const results = await GET_ALL_CAMPAIGN();
    if (results.data && Object.values(results.data).length > 0) {
      setCampaignSend(results.data.send);
      setCampaignDraft(results.data.draft);
      setCampaigns({ loading: false, data: results.data.all, message: "" });
    } else if (results.message) {
      setCampaigns({ loading: false, data: [], message: results.message });
    } else {
      setCampaigns({ loading: false, data: [], message: "No record found" });
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const widget_1 = RenderAccessWidgets(context.widgets, 1);
  const widget_2 = RenderAccessWidgets(context.widgets, 2);

  return (
    (widget_1 === true ? (
      <CardBox>
        <CardBox.Body>
          <div className="d-flex justify-content-between align-items-center my-8">
            <NavTabs campaigns={campaigns.data} campaignsDraft={campaignsDraft} campaignsSend={campaignsSend} />
            <div className="actions">
              {widget_2 === true && <ButtonCreateCampaign />}
            </div>
          </div>
          {campaigns.loading ? (
            <LoadingBars />
          ) : campaigns.message ? (
            <div className="p-3 border rounded text-danger bg-light-danger">
              {campaigns.message}
            </div>
          ) : Object.values(campaigns.data).length > 0 ? (
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="all-campaigns"
                role="tabpanel"
              >
                <TableData data={campaigns.data} ReloadData={ReloadData} />
              </div>
              <div className="tab-pane fade" id="pending" role="tabpanel">
                <TableData data={campaignsDraft} ReloadData={ReloadData} />
              </div>
              <div className="tab-pane fade" id="completed" role="tabpanel">
                <TableData data={campaignsSend} ReloadData={ReloadData} />
              </div>
            </div>
          ) : (
            <NoRecordFound message="No record found" />
          )}
        </CardBox.Body>
      </CardBox>
    ) : widget_1)
  );


}




export { ButtonCreateCampaign };
