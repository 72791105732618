import React from 'react'
import { Link } from 'react-router-dom'
import SignIn from './SignIn'
import Modals from '../layouts/modals/Modals'
import logoSgu from "../../../_metronic/media/logo-sgu.png";

export default function AuthLayout() {
    var datetime = new Date();
    var year = datetime.getFullYear();
    return (
        <div
            className='auth-bg bgi-size-cover bgi-attachment-fixed bgi-position-center d-flex flex-column flex-column-fluid'
        
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 bg-dark bg-gradient'>
                {/* begin::Logo */}
                <Link to={"/sign-in"} className='mb-12 bg-white rounded px-5 py-3'>
                    <img alt='Logo' src={logoSgu} className='h-45px' />
                </Link>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <SignIn />
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10 bg-dark'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a className='text-muted text-hover-primary px-2'>
                        SGU &copy; {year}
                    </a>

                    <a href='https://wiki.sgu.ac.id/' className='text-muted text-hover-primary px-2'>
                        Help
                    </a>

                    <a href='https://helpdesk.sgu.ac.id/' className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>
                </div>
            </div>
            {/* end::Footer */}

            <Modals />
        </div>
    )
}
