import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../layouts/datatables/MYDatatables';
import moment from "moment"
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../layouts/modals/Modals';
import DetailCampaign from './DetailCampaign';
import { useOutletContext } from 'react-router-dom';
import { RenderAccessWidgets } from '../RenderAccessUser';
import { LoadingBars } from '../../layouts/loadbars/LoadingBars';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { ButtonTersier } from '../../layouts/buttons';
import { FetchMailUser } from '../components/mailing-list';

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_4 = RenderAccessWidgets(context.widgets, 4);
  const widget_5 = RenderAccessWidgets(context.widgets, 5);
  const widget_18 = RenderAccessWidgets(context.widgets, 18);

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;
  const headers = [
    { name: "No", field: "campaign_id", sortable: false },
    { name: "Subject", field: "subject", sortable: true },
    { name: "Recepients", field: "recipient_id", sortable: false },
    { name: "Created At", field: "createdAt", sortable: true },
    { name: "Actions", field: "campaign_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if(Object.values(listData).length > 0){
      computedData = listData.map((v,index)=>{
        var obj = v;
        obj.status_name = v.status === 1 ? "Draft":"Sended"
        obj.num=index+1;
        return obj;
      })
    }

    if (search) {
      computedData = computedData.filter(
        listData => {
          return Object.keys(listData).some(key =>
            listData[key].toString().toLowerCase().includes(search)
          );
        }
      );
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }

  }, [listData, search, sorting, currentPage]);

  const HandlerSendCampaign = (campaign_id) => {
    return openModal({ header: "Detail Campaign", message: <DetailCampaign campaign_id={campaign_id} access={{widget_4,widget_5}} /> });
  }

  const HandlerRemove = (campaign_id) => {
    const param = { campaign_id: campaign_id };
    const HandlerYes = () => {
      RemoveCampaign(param);
    }

    openModal({ header: "Confirmation", message: <ConfirmRemove message={"Are you sure you want to delete this item?"} handler={HandlerYes} /> })
  };

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const RemoveCampaign = (param) => {
    setRemove({ loading: true, data: [], message: "" });
    openModal({ message: "Please wait while the item is being deleted. " + <LoadingBars /> })
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/campaigns/remove"
    ).then((response) => {
      if (response.error) {
        setRemove({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setRemove({
          loading: false,
          data: response.data,
          message: "Successfully Removed",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Removed"} />,
        });
        ReloadData();
      } else {
        setRemove({ loading: false, data: [], message: "Failed remove" });
        openModal({
          message: <MessageError message={"Failed remove"} />,
        });
      }
    });
  };

  return (
    <div className='table-responsive'>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
        <ButtonItemRefresh totalItems={totalItems} onClick={()=>ReloadData()} />
      </div>

      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) =>
            setSorting({ field, order })
          }
        />
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>{v.num}</td>
                <td>
                  <div>
                    <span className="fw-bolder d-block fs-6">
                      {decodeURIComponent(v.subject)}
                    </span>
                    <span className={"badge " + (v.status === 1 ? "badge-light-info" : "badge-light-success")}>
                      {v.status_name}
                    </span>
                  </div>
                </td>
                <td><FetchMailUser data={v.recepients} /></td>
                <td>
                  <span className="d-block fw-bolder fs-6">{moment(v.createdAt).format("DD MMM YYYY, hh:mm A")}</span>
                  <span className="text-muted">{v.created_by}</span>
                </td>
                <td>
                  <div className="btn-group">
                    {widget_18 === true && (
                        <ButtonTersier className="btn-sm btn-icon text-hover-danger" type="button" onClick={() => HandlerRemove(v.campaign_id)} title="Remove">
                          <i className="bi bi-trash"></i>
                        </ButtonTersier>
                    )}
                      <ButtonTersier className="btn-sm btn-icon text-hover-primary" type="button" onClick={() => HandlerSendCampaign(v.campaign_id)} title="View">
                        <i className="fa fa-arrow-right"></i>
                      </ButtonTersier>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  )
}



