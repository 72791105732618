import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq,AxiosLibBillApp } from "../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";
import { Link, Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import {
    MessageError,
    MessageSuccess,
    openModal,
} from "../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";
import Select from 'react-select';

const Token = AuthDecodeToken();

export function ContactForm() {
    const context = useOutletContext();
    const widget_create = RenderAccessWidgets(context.widgets, 21);
    const navigate = useNavigate()
    let params = useParams();
    const contact_id = params.contact_id;
    const [error, setError] = useState('');
    const [isClearable, setIsClearable] = useState(true);
    const editorRef = useRef(null);

    const objParam = {
        contact_id: 0,
        fullname: "",
        email: "",
        phone: "",
        sp_code: "",
        sp_name: "",
        dept_code: "",
        dept_name: "",
        created_by: Token.identity.given_name,
    };
    const [post, setPost] = useState(objParam);
    const [temp, setTemp] = useState({ loading: false, data: [], message: "" });
    const [spList, setSpList] =  useState({ loading: false, data: [], message: "" });
    const [deptList, setDeptList] =  useState({ loading: false, data: [], message: "" });
    useEffect(() => {
        if (contact_id) {
            GetContactByID(contact_id, setTemp, setPost , setSelectedOption , setSelectedOptionDept);
        }
        GetStudyProgram(setSpList);
        GetDepartment(setDeptList);
    }, []);

    const [submitPost, setSubmitPost] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const CreateContact= (param) => {
        setSubmitPost({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/contact/save"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                setSubmitPost({
                    loading: false,
                    data: response.data,
                    message: "Successfully Saved",
                });
                openModal({
                    message: <MessageSuccess message={"Successfully Saved"} />,
                });
                setTimeout(() => {
                    openModal({ open: false });
                    navigate("/contacts");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" });
            }
        });
    };

    const UpdateContact = (param) => {
        setSubmitPost({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/contact/update"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                setSubmitPost({
                    loading: false,
                    data: response.data,
                    message: "Successfully Updated",
                });
                openModal({
                    message: <MessageSuccess message={"Successfully Updated"} />,
                });
                setTimeout(() => {
                    openModal({ open: false });
                    navigate("/contacts");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" });
            }
        });
    };

    const SubmitForm = (e) => {
        e.preventDefault();
        if (post.fullname && post.email && post.phone) {
            const param = RemoveEmptyValue(post);
            //param.dept_name = encodeURIComponent(param.dept_name);
            if (post.contact_id) {
                param.contact_id = param.contact_id
                UpdateContact(param);
            } else {
                CreateContact(param);
            }

        } else {
            openModal({
                message: (
                    <MessageError message={"Please fill out the form with correctly"} />
                ),
            });
        }
    };

    
    const options = spList.data.map(item => ({
        value: item.sp_code,
        label: item.sp_name
    }));

    const [selectedOption, setSelectedOption] = useState(null);

    const optionsDept = deptList.data.map(item => ({
        value: item.department_id,
        label: item.department
    }));

    const [selectedOptionDept, setSelectedOptionDept] = useState(null);

    const handleStudyProgram = (selected) => {
        if (selected) {
            const selectedCode = selected.value;  // This is the sp_code
            const selectedName = selected.label;
            setSelectedOption(selected);
            setPost({ ...post, sp_code: selectedCode, sp_name : selectedName })
        }else{
            setSelectedOption(null);
        }
       
    };

    const handleDepartment = (selected) => {
        if (selected) {
            const selectedCode = selected.value;  // This is the sp_code
            const selectedName = selected.label;
            setSelectedOptionDept(selected);
            setPost({ ...post, dept_code: selectedCode, dept_name : selectedName })
        }else{
            setSelectedOptionDept(null);
        }
       
    };

    const toTitleCase = (str) => {
        return str.toLowerCase().split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      };

      const handleName = (event) => {
        const inputValue = event.target.value;
        const formattedValue = toTitleCase(inputValue);
        setPost({ ...post, fullname: formattedValue })
        //console.log(formattedValue)
    };

    const handleDept = (event) => {
        const inputValue = event.target.value;
        const formattedValue = toTitleCase(inputValue);
        setPost({ ...post, dept_name: formattedValue })
       // console.log(formattedValue)
    };

    const validateEmail = (email) => {
        if (!email) {
          return '';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
          return 'Please enter a valid email address';
        }
        return '';
      };

      const handleEmail = (e) => {
        const newEmail = e.target.value;
        setPost({ ...post, email: newEmail })
      };

      useEffect(() => {
        const error = validateEmail(post.email);
        setError(error);
      }, [post.email]);
      
    return (
        (widget_create === true ? (
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Form Contact</span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            Please fill up the form with correctly
                        </span>
                    </h3>
                </div>
                <div className="card-body">
                    <form
                        method="post"
                        autoComplete="off"
                        id="form-post"
                        onSubmit={(e) => SubmitForm(e)}
                    >
                        <div className="mb-5">
                            <label className="required fw-bolder">Fullname</label>
                            <input
                                type="text"
                                required
                                name="Fullname"
                                className="form-control"
                                value={post.fullname}
                                //onChange={(e) => setPost({ ...post, fullname: e.target.value })}
                                onChange={handleName}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="required fw-bolder">Email</label>
                            <input
                                type="text"
                                required
                                name="Email"
                                className="form-control"
                                value={post.email}
                                onChange={handleEmail}
                                //onChange={(e) => setPost({ ...post, email: e.target.value })}
                            />
                        </div>

                        <div className="mb-5">
                            <label className="required fw-bolder">Phone</label>
                            <input
                                type="text"
                                required
                                name="Phone"
                                className="form-control"
                                value={post.phone}
                                onChange={(e) => setPost({ ...post, phone: e.target.value })}
                            />
                        </div>
                        {/* <div className="mb-5">
                            <label className="required fw-bolder">Study Program</label>
                            <Select
                                value={selectedOption}
                                onChange={handleStudyProgram}
                                options={options}
                                isClearable={true}
                                isSearchable={true}
                                placeholder="Select an Study program..."
                            />
                        </div> */}
                        <div className="mb-5">
                            <label className="required fw-bolder">Department</label>
                            <Select
                                value={selectedOptionDept}
                                onChange={handleDepartment}
                                options={optionsDept}
                                isClearable={true}
                                isSearchable={true}
                                placeholder="Select an Department..."
                            />
                        </div>
                        {error && <div className="bg-light-danger p-3 rounded border border-danger text-danger text-capitalize mb-5">
                            {error}
                        </div>}

                        {submitPost.message ? (
                            <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mb-5">
                                {submitPost.message}
                            </div>
                        ) : (
                            ""
                        )}

                        <div className="text-end mt-10 d-flex justify-content-between">
                            <Link
                                className="btn btn-lg btn-light fw-bold me-3"
                                to={"/contacts"}
                            >
                                Cancel
                            </Link>
                            <button
                                className="btn btn-lg btn-primary fw-bold btn-submit"
                                type="submit"
                                disabled={submitPost.loading}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        ) : <AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />)
    );
}

const GetContactByID = (contact_id, setTemp, setPost , setSelectedOption, setSelectedOptionDept) => {
    setTemp({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "get",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/contact/" + contact_id
    ).then((response) => {
        if (response.error) {
            setTemp({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            const result = response.data;
            setTemp({
                loading: false,
                data: result,
                message: "Successfully Saved",
            });
            setPost({
                contact_id: result.contact_id,
                fullname: result.fullname,
                email: result.email,
                phone: result.phone,
                sp_code: result.sp_code,
                sp_name: result.sp_name,
                dept_code: result.dept_code,
                dept_name: result.dept_name,
                created_by: Token.identity.given_name,
            })
            let dt1={}
            dt1.label=result.sp_name;
            dt1.value=result.sp_code;
            setSelectedOption(dt1);
            let dt2={}
            dt2.label=result.dept_name;
            dt2.value=result.dept_code;
            setSelectedOptionDept(dt2);
            //console.log(response.data);
        } else {
            setTemp({ loading: false, data: [], message: "Failed saved" });
        }
    });
};

const GetStudyProgram = (setSpList) => {
    setSpList({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "post",
    };
    AxiosLibBillApp(
        parameter,
        process.env.REACT_APP_BILLAPP_API + "/api/external/studyprog/list"
    ).then((response) => {
        //console.log(response);
        if (response.error) {
            setSpList({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === true) {
            const result = response.data.list;
            setSpList({
                loading: false,
                data: result,
                message: "Successfully",
            });
            
        } else {
            setSpList({ loading: false, data: [], message: "Failed saved" });
        }
    });
};

const GetDepartment= (setDeptList) => {
    setDeptList({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "get",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/department" 
    ).then((response) => {
        //console.log(response);
        if (response.error) {
            setDeptList({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            const result = response.data;
            setDeptList({
                loading: false,
                data: result,
                message: "Successfully",
            });
            
        } else {
            setDeptList({ loading: false, data: [], message: "Failed saved" });
        }
    });
};
