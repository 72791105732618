import React, { Component } from 'react'
import { AuthDecodeToken } from '../../authentications/reducers/AuthRedux';
import { Link } from 'react-router-dom';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import moment from 'moment';

export class Profiles extends Component {
    constructor(props) {
        super(props);
        this.GetDetail = this.GetDetail.bind(this);
        var Token = AuthDecodeToken();
        this.state = {
            token: Token,
            profile:{loading:false, data:[], message:""}
        }
    }

    GetDetail(){
        this.setState({profile:{loading:true, data:[], message:""} });
        const parameter = {
            header:{},
            method:"get"
        };
        const user_id = this.state.token.identity.entityid;
        AxiosLibGlobalReq(parameter, process.env.REACT_APP_EXPRESS_API+"/api/user/"+user_id+"/detail").then((response) => {
            console.log(response);
            if(response.error){
                this.setState({profile:{loading:false, data:[], message:response.error} });
            }else if(response.data && response.status === 200){
                this.setState({profile:{loading:false, data:response.data, message:""} });
            }else{
                this.setState({profile:{loading:false, data:[], message:"No record found"} });
            }
        });
    }

    componentDidMount(){
        //this.GetDetail();
    }

    render() {
        const current_user = this.state.token.identity ? this.state.token.identity.email : "-";
        const current_empid = this.state.token.identity ? this.state.token.identity.empid : "-";
        const current_given_name = this.state.token.identity ? this.state.token.identity.given_name : "-";
        const current_family_name = this.state.token.identity ? this.state.token.identity.family_name : "-";
        const current_roleName = this.state.token.RoleName ? this.state.token.RoleName : "-";
        const expired = this.state.token.Expired ? moment(this.state.token.Expired).format("Do MMMM YYYY, HH:mm") : "-";

        return (
            <div className="d-flex flex-column flex-center text-center">
                <div className='card card-flush  w-lg-650px py-5'>
                    <div className="card-body py-15 py-lg-20">
                        <div className="mb-5">
                            <div className="symbol symbol-75px symbol-circle">
                                <span className="symbol-label bg-light-danger text-danger fs-5 fw-bolder text-uppercase">{current_user ? current_user.charAt(0) : "X"}</span>
                                <div className="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n3 mt-n3"></div>
                            </div>
                        </div>

                        <span className="fs-4 text-gray-800 text-hover-primary fw-bolder mb-0 text-capitalize">{current_given_name} {current_family_name} </span>
                        <div className="fw-bold text-gray-500 mb-6">{current_roleName}</div>
                        <div className="d-flex flex-center flex-wrap mb-5">
                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-start">
                                <div className="fs-6 fw-bolder text-gray-700">{current_user}</div>
                                <div className="fw-bold text-gray-500">Email</div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-start">
                                <div className="fs-6 fw-bolder text-gray-700">{current_empid}</div>
                                <div className="fw-bold text-gray-500">Employee ID</div>
                            </div>

                            <div className="border border-danger border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3 text-start">
                                <div className="fs-6 text-danger">{expired}</div>
                                <div className="fw-bold text-danger">Expired Session</div>
                            </div>
                            
                        </div>
                        <Link className="btn btn-sm btn-light" to={"/sign-out"}>
                            <i className="bi bi-power"></i>
                            <span className="ms-2">Sign Out</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
