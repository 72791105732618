import React, { useEffect, useState } from "react";
import TableData from "./TableData";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../../layouts/loadbars/LoadingBars";
import { Link, useOutletContext } from "react-router-dom";
import { MGroup } from "./google-groups/MGroup";
import { RenderAccessWidgets } from "../RenderAccessUser";

export function Recepients() {
  const context = useOutletContext();
  const widget_10 = RenderAccessWidgets(context.widgets, 10);
  const widget_11 = RenderAccessWidgets(context.widgets, 11);
  const [recepients, setRecepients] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const FetchAllData = () => {
    setRecepients({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: {},
      method: "get",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/recepients"
    ).then((response) => {
      if (response.error) {
        setRecepients({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setRecepients({ loading: false, data: response.data, message: "" });
      } else {
        setRecepients({ loading: false, data: [], message: "No record found" });
      }
    });
  };

  useEffect(() => {
    FetchAllData();
  }, []);

  return (
    (widget_10 === true ? (
      <div id="recepients" className="row g-5 g-xxl-8">
        <div className="col-xl-6">
          <MGroup />
        </div>
        <div className="col-xl-6">
          <div className="card card-xl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Master Recepeints
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  List of recepients by group user
                </span>
              </h3>
              {widget_11 === true && (
                <div className="card-toolbar">
                  <Link
                    className="nav-link btn btn-sm btn-primary fw-bold px-4 me-1"
                    to={"/recepients/create"}
                  >
                    <i className="bi bi-plus fs-2"></i>
                    Create new
                  </Link>
                </div>
              )}
            </div>
            <div className="card-body py-3">
              {recepients.loading ? (
                <LoadingBars />
              ) : recepients.message ? (
                <div className="p-3 border rounded text-danger bg-light-danger">
                  {recepients.message}
                </div>
              ) : Object.values(recepients.data).length > 0 ? (
                <TableData data={recepients.data} ReloadData={FetchAllData} />
              ) : (
                <NoRecordFound message="No record found" />
              )}
            </div>
          </div>
        </div>
      </div>
    ) : widget_10)
  );
}
