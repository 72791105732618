import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Dashboards } from "./components/modules/dashboards";
import { Profiles } from "./components/modules/profiles";
import { UserManagement, UserManagementLayout } from "./components/modules/user-manages";
import { CampaignLayout, CampaignShortForm, Campaigns } from "./components/modules/campaigns";
import { RecepientForm, Recepients, RecepientsLayout } from "./components/modules/recepients";
import { TemplateForm, TemplateLayout, Templates } from "./components/modules/templates";
import { ContactLayout, Contacts, ContactForm } from "./components/modules/contact";
import { ItemLayout, Items, ItemForm } from "./components/modules/items";
import { EventLayout, Events, EventForm } from "./components/modules/events";
import { CouponLayout, CouponForm,  Coupons } from "./components/modules/coupon";
import { ParticipantLayout, Participants } from "./components/modules/participants";




export default function BasePage({ routes }) {
    const COMPONENT_MAP = {
        UserManagementLayout:UserManagementLayout,
        UserManagement: UserManagement,

        CampaignLayout: CampaignLayout,
        Campaigns: Campaigns,
        CampaignShortForm: CampaignShortForm,

        RecepientsLayout: RecepientsLayout,
        Recepients: Recepients,
        RecepientForm: RecepientForm,

        TemplateLayout: TemplateLayout,
        Templates: Templates,
        TemplateForm: TemplateForm,

        ContactLayout: ContactLayout,
        Contacts:Contacts,
        ContactForm: ContactForm,

        ItemLayout: ItemLayout,
        Items:Items,
        ItemForm: ItemForm,

        EventLayout: EventLayout,
        Events:Events,
        EventForm:EventForm,

        CouponLayout : CouponLayout,
        Coupons : Coupons,
        CouponForm : CouponForm,
  
        ParticipantLayout :  ParticipantLayout,
        Participants : Participants,
    }
    return (
        <React.Suspense>
            <Routes>
                <Route path='sign-in' element={<Navigate to='/dashboard' />} />
                <Route path="dashboard" element={<Dashboards />} />
                <Route path="profile" element={<Profiles />} />

                {routes.map((v, index) => {
                    const SlugComponent = COMPONENT_MAP[v.slug];
                    if (v.child) {
                        if (Object.values(v.child).length > 0) {
                            return (
                                <Route path={v.slug_uri} element={<SlugComponent />} key={index} >
                                    {v.child.map((c, index) => {
                                        const SlugComponent = COMPONENT_MAP[c.slug];
                                        if (c.slug_uri === "index") {
                                            return <Route index element={<SlugComponent />} key={index} />
                                        } else {
                                            return <Route path={c.slug_uri} element={<SlugComponent />} key={index} />
                                        }
                                    })}
                                </Route>
                            )
                        }
                    } else {
                        return <Route path={v.slug_uri} key={index} element={<SlugComponent />} />
                    }
                })}

                <Route path='*' element={<Navigate to='/error' />} />
            </Routes>
        </React.Suspense>
    )
}
