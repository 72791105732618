import React, { useEffect } from 'react'
import Modals, { openModal } from './modals/Modals';
import { Aside, AsideHeader, Footer } from './asides';
import { AuthDecodeToken } from '../authentications/reducers/AuthRedux';
import { Link } from 'react-router-dom';


export default function LayoutInit({ children, menu }) {
  var body = document.getElementById("kt_body");
  body.className = "";
  body.className = "header-tablet-and-mobile-fixed aside-enabled";
  var root = document.getElementById("root");
  root.style.height = "auto";

  const CheckExpired = () => {
    var currDatetime = new Date();
    currDatetime = currDatetime.getTime();
    const Token = AuthDecodeToken();
    //var timeExpired = moment(Token.expired).format('Do MMMM YYYY HH:mm');
    if (currDatetime >= Token.Expired) {
      window.location.replace('/sign-out');
      openModal({
        header: "You've been signed out",
        message: (
          <div className="message">
            <p>Please wait a seccond.</p>
            <p className="text-center">
              <Link to={{ pathname: "/sign-out" }} className="btn btn-primary">Re-Sign in here</Link>
            </p>
          </div>
        )
      });
    }
  }

  useEffect(() => {
    CheckExpired();
  }, [])

  return (

    <div className='app-page flex-column flex-column-fluid' id='kt_page'>
      <AsideHeader />
      <div className='app-wrapper flex-column flex-row-fluid' id='kt_wrapper'>
        <Aside menu={menu} />
        <div className='app-main flex-column flex-row-fluid' id='kt_main'>
          <div className='d-flex flex-column flex-column-fluid'>
            <div id="kt_content" className="app-content flex-column-fluid">
              <div id="kt_content_container" className="app-container container-xxl">
                {children}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <Modals />
    </div>


  )
}
