import React, { useState, useEffect } from 'react';
import { toAbsoluteUrl } from '../../helpers/AssetsHelpers'
import TableData from "./TableData";
//import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../layouts/loadbars/LoadingBars";
import { ButtonCreateEvent, NavTabs } from "./FormAtributes";
//import { useOutletContext } from "react-router-dom";
//import { RenderAccessWidgets } from "../RenderAccessUser";
import { GET_ALL_DATA } from "./Apis";
//import { CardBox } from "../../layouts/cards/CardUI";
//import { openModal } from '../../layouts/modals/Modals';


export default function ComingEvent({ count = 5 }) {
    var datetime = new Date();
    var year = datetime.getFullYear();
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const ReloadData = async () => {
        const results = await GET_ALL_DATA();
        if (results.data && Object.values(results.data).length > 0) {
            //console.log(results.data)
            setCampaigns({ loading: false, data: results.data, message: "" });
        } else if (results.message) {
            setCampaigns({ loading: false, data: [], message: results.message });
        } else {
            setCampaigns({ loading: false, data: [], message: "No record found" });
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    return (
       
        <div className="row g-5 g-xl-5 mb-5 mb-xl-10">
           
            <div className="card card-flush h-md-100 w-100">
                {/* <div className="card-header border-0 p-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-900">

                            <span
                                style={{
                                    background:
                                        "linear-gradient(to right, #3A7BD5 0%, #00D2FF 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    display: "inline-block",
                                }}
                            >
                                <span id="kt_landing_hero_text">All Events</span>
                            </span>
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">Events and Training</span>
                    </h3>
                    <div className="card-toolbar">

                    </div>
                </div> */}
                <div className="card-body p-5">
                    {campaigns.loading ? (
                        <LoadingBars />
                    ) : campaigns.message ? (
                        <div className="p-3 border rounded text-danger bg-light-danger">
                           <div className="no-events">No events available</div>
                        </div>
                    ) : Object.values(campaigns.data).length > 0 ? (
                        <TableData data={campaigns.data} ReloadData={ReloadData} />
                    ) : (
                        <NoRecordFound message="No record found" />
                    )}
                </div>
                </div>
            
       </div>


    )
}
