import React, { useMemo, useState } from 'react'
import { HeaderDatatables, SearchBarTable } from '../../../layouts/datatables/MYDatatables';
import { OverlayTrigger } from 'react-bootstrap';
import moment from "moment"
import { renderTooltip } from '../../../layouts/popovers/PopOvers';
import { AxiosLibGlobalReq } from '../../../../helpers/AxiosLibs';
import { MessageError, MessageSuccess, openModal } from '../../../layouts/modals/Modals';
import { ButtonTersier } from '../../../layouts/buttons';

export default function AccountReqTableData({ data, refresh }) {
    const listData = data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toString().toLowerCase().includes(search)
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [listData, search, sorting]);

    const [removePost, setRemovePost] = useState({ loading: false, data: [], message: "" });
    const HandlerRemoveAccount = (user_id) => {
        if (window.confirm("Are you sure ?")) {
            var param = { user_id: user_id };
            RemoveUser(param);
        }
    }

    const RemoveUser = (param) => {
        setRemovePost({ loading: true, data: [], message: "" })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/user/delete"
        ).then((response) => {
            if (response.error) {
                openModal({ message: <MessageError message={response.error} /> });
            } else if (response.status === 200) {
                openModal({ message: <MessageSuccess message={"Successfully Updated"} /> });
                refresh();
            } else {
                openModal({ message: <MessageError message={"Failed remove"} /> });
            }
        });
    }

    const HandlerAssign = (user_id) => {
        const param = { user_id: user_id, group_id: 3 };
        AssignUser(param);
    }

    const AssignUser = (param) => {
        setRemovePost({ loading: true, data: [], message: "" })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/user/assign"
        ).then((response) => {
            if (response.error) {
                openModal({ message: <MessageError message={response.error} /> });
            } else if (response.data && response.status === 200) {
                openModal({ message: <MessageSuccess message={"Successfully Updated"} /> });
                refresh();
            } else {
                openModal({ message: <MessageError message={"Failed updated"} /> });
            }
        });
    }

    return (
        <div className="list-user-request">
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-100"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            {removePost.message ? (
                <div className="p-5 bg-light-danger rounded border border-danger text-danger text-capitalize">
                    {removePost.message}
                </div>
            ) : ""}

            <div className="hover-scroll-overlay-y pe-6 me-n6">
                {Object.values(ResultData).length > 0 ? (
                    ResultData.map((v, index) => (
                        <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6" key={index}>
                            <div className="mb-3">
                                <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">{v.email}</span>
                                <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">{v.employeeid}</span>
                                <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">{moment(v.createdAt).format("DD MMM YYYY, hh:mm A ")}</span>
                            </div>
                            <div className="btn-group w-100">
                                <ButtonTersier
                                    className="btn-sm btn-icon"
                                    type="button"
                                    title="Approve"
                                    onClick={() => HandlerAssign(v.user_id)}
                                >
                                    <i className="bi bi-check2-circle"></i>
                                </ButtonTersier>
                                <ButtonTersier
                                    className="btn-sm btn-icon btn-light-danger"
                                    type="button"
                                    title="Reject"
                                    onClick={() => HandlerRemoveAccount(v.user_id)}
                                >
                                    <i className="bi bi-trash"></i>
                                </ButtonTersier>

                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center">No record found</p>
                )}
            </div>
        </div>
    )
}
