import React from "react";
import { AxiosLibGlobalReq,AxiosLibTokenReq } from "../../helpers/AxiosLibs";

const GET_ALL_DATA = async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "post",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/event/get_published"
        );

        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            //console.log(results)
            //results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            return { loading: false, data: results , message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
       // console.log(error);
        return { loading: true, data: [], message: "" };
    }
}

const GET_RECOM = async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "post",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/event/get_recomend"
        );

        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            //console.log(results)
            //results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            return { loading: false, data: results , message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
       // console.log(error);
        return { loading: true, data: [], message: "" };
    }
}

const GET_ALL_ITEM = async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "get",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/item"
        );

        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            //console.log(results)
            //results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            return { loading: false, data: results , message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
        //console.log(error);
        return { loading: true, data: [], message: "" };
    }
}

const GET_ALL_CONTACT = async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "get",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/contact"
        );

        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            //console.log(results)
            //results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            return { loading: false, data: results , message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
        console.log(error);
        return { loading: true, data: [], message: "" };
    }
}

const GET_ALL_COUPON= async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "post",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/coupon/free"
        );

        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            //console.log(results)
            //results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            return { loading: false, data: results , message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
        //console.log(error);
        return { loading: true, data: [], message: "" };
    }
}
export { GET_ALL_DATA, GET_ALL_ITEM, GET_ALL_CONTACT, GET_ALL_COUPON, GET_RECOM }