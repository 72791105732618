import React, { useEffect, useState } from "react";
import { LoadingBars } from "../../../layouts/loadbars/LoadingBars";
import { AlertNofif } from "../../../layouts/alerts/AlertsUI";
import { OverlayTrigger } from "react-bootstrap";
import { ButtonPrimary, ButtonTersier } from "../../../layouts/buttons";
import { AxiosLibGlobalReq } from "../../../../helpers/AxiosLibs";
import { renderTooltip } from "../../../layouts/popovers/PopOvers";
import { openModal } from "../../../layouts/modals/Modals";

export default function WLookUpTemp({ HandlerChangeTemp }) {
  const [templates, setTemplates] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const FetchAllData = () => {
    setTemplates({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: {},
      method: "get",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/templates"
    ).then((response) => {
      if (response.error) {
        setTemplates({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setTemplates({ loading: false, data: response.data, message: "" });
      } else {
        setTemplates({ loading: false, data: [], message: "No record found" });
      }
    });
  };

  useEffect(() => {
    FetchAllData();
  }, []);

  const [viewTemp, setViewTemp] = useState("");
  if (viewTemp) {
    return <ViewTemp data={viewTemp} setViewTemp={setViewTemp} HandlerChangeTemp={HandlerChangeTemp} />;
  } else {
    return (
      <div className="look-up-temp table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0">
          <thead className="fw-bold text-muted">
            <tr>
              <th>No</th>
              <th>Subject</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {templates.loading ? (
              <tr>
                <td colSpan={3}>
                  <LoadingBars />
                </td>
              </tr>
            ) : templates.message ? (
              <tr>
                <td colSpan={3}>
                  <AlertNofif messages={templates.message} color="danger" />
                </td>
              </tr>
            ) : Object.values(templates.data).length > 0 ? (
              templates.data.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{decodeURIComponent(v.subject)}</td>
                  <td>
                    <div className="btn-group">
                        <ButtonTersier
                          className="btn-sm btn-icon"
                          type="button"
                          title="Add"
                          onClick={() => HandlerAddTemp(HandlerChangeTemp, v)}
                        >
                          <i className="fa fa-plus text-dark"></i>
                        </ButtonTersier>
                        <ButtonTersier
                          className="btn-sm btn-icon"
                          title="View"
                          type="button"
                          onClick={() => setViewTemp(v)}
                        >
                          <i className="fa fa-arrow-right text-dark"></i>
                        </ButtonTersier>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const ViewTemp = ({ HandlerChangeTemp, setViewTemp, data }) => {
  return (
    <div className="detail-template">
      <h3>{decodeURIComponent(data.subject)}</h3>
      <div className="alert bg-light">
        <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(data.messages) }} />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <ButtonTersier onClick={()=>setViewTemp("")} >
          <i className="fa fa-arrow-left fs-1x"></i>
          <span className="fw-bold">Back</span>
        </ButtonTersier>
        <ButtonPrimary onClick={() => HandlerAddTemp(HandlerChangeTemp, data)}>
          <i className="fa fa-plus fs-1x"></i>
          <span className="fw-bolder">Choose Template</span>
        </ButtonPrimary>
      </div>
    </div>
  );
};

const HandlerAddTemp = (HandlerChangeTemp, data) => {
  HandlerChangeTemp(data.messages);
  openModal({ open: false });
};
