import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../layouts/datatables/MYDatatables';
import moment from "moment"
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../layouts/modals/Modals';
import { Link, useOutletContext } from 'react-router-dom';
import { RenderAccessWidgets } from '../RenderAccessUser';
import { LoadingBars } from '../../layouts/loadbars/LoadingBars';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { ButtonTersier } from '../../layouts/buttons';
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../layouts/popovers/PopOvers";
import { formatRupiah } from "./components/FormAtributes";

export default function TableData({ data, ReloadData }) {
    const context = useOutletContext();
    const widget_delete = RenderAccessWidgets(context.widgets, 41);

    const listData = data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 15;
    const headers = [
        { name: "No", field: "coupon_id", sortable: false },
        { name: "Name", field: "fullname", sortable: true },
        { name: "Email", field: "email", sortable: false },
        { name: "Phone", field: "phone", sortable: false },
        { name: "Amount", field: "inv_amount", sortable: true },
        { name: "Persent", field: "dsc_persent", sortable: true },
        { name: "Nominal", field: "dsc_nominal", sortable: true },
        { name: "Charged", field: "inv_amount_charged", sortable: true },
        { name: "Status", field: "status", sortable: true },
        { name: "Actions", field: "coupon_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = [];
        if (Object.values(listData).length > 0) {
            computedData = listData.map((v, index) => {
                var obj = v;
                //obj.status_name = v.status === 1 ? "Draft":"Sended"
                obj.num = index + 1;
                return obj;
            })
        }
        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.values(listData).some((value) => {
                    if (value === null || value === undefined) {
                        return false;
                    }
                    if (typeof value === 'object') {
                        return Object.values(value).some(subValue =>
                            String(subValue).toLowerCase().includes(search.toLowerCase())
                        );
                    }
                    return String(value).toLowerCase().includes(search.toLowerCase());
                });
            });
        }

        setTotalItems(computedData.length);
        /*
                if (sorting.field) {
                    const reversed = sorting.order === "asc" ? 1 : -1;
                    computedData = computedData.sort(
                        (a, b) =>
                            reversed * a[sorting.field].localeCompare(b[sorting.field])
                    );
                }
        */

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort((a, b) => {
                const aValue = a[sorting.field];
                const bValue = b[sorting.field];

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return reversed * aValue.localeCompare(bValue);
                } else if (aValue < bValue) {
                    return reversed * -1;
                } else if (aValue > bValue) {
                    return reversed * 1;
                } else {
                    return 0;
                }
            });
        }
        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }

    }, [listData, search, sorting, currentPage]);


    const HandlerSendInv = (registration_id) => {
        const param = { registration_id: registration_id };
        const HandlerYes = () => {
            SendData(param);
        }

        openModal({ header: "Confirmation", message: <ConfirmRemove message={"Are you sure you want to resend this invoice?"} handler={HandlerYes} /> })
    };

    const [remove, setRemove] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const SendData = (param) => {
        setRemove({ loading: true, data: [], message: "" });
        openModal({ message: "Please wait while the invoice is being send. " })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/regis/resend"
        ).then((response) => {
            if (response.error) {
                setRemove({ loading: false, data: [], message: response.error });
                openModal({
                    message: <MessageError message={response.error} />,
                });
            } else if (response.data && response.status === 200) {
                setRemove({
                    loading: false,
                    data: response.data,
                    message: "Successfully Resend",
                });
                openModal({
                    message: <MessageSuccess message={"Successfully Resend"} />,
                });
                ReloadData();
            } else {
                setRemove({ loading: false, data: [], message: "Failed Resend" });
                openModal({
                    message: <MessageError message={"Failed Resend"} />,
                });
            }
        });
    };

    ;

    return (
        <div className='table-responsive'>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
            </div>

            <table className="table table-row-dashed table-striped table-row-gray-200 align-middle gs-0 gy-4">
                <HeaderDatatables
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {Object.values(ResultData).length > 0 ? (
                        ResultData.map((v, index) => (
                            <tr key={index}>
                                <td>{v.num}</td>
                                <td>
                                    <div>
                                        <span className="fw-bolder d-block fs-6">
                                            {(v.fullname)}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span className="fw-normal d-block fs-6">
                                            {v.email}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <span className="fw-normal d-block fs-6">
                                            {v.phone}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span className="fw-normal d-block fs-6">
                                        {formatRupiah(v.inv_amount)}
                                    </span>
                                </td>
                                <td>
                                    <span className="fw-normal d-block fs-6">
                                    {(v.dsc_persent) ? (v.dsc_persent + '%') : ''}
                                    </span>
                                </td>
                                <td>
                                    <span className="fw-normal d-block fs-6">
                                    {(v.dsc_nominal) ? (v.dsc_nominal) : ''}
                                    </span>
                                </td>
                                <td>
                                    <span className="fw-normal d-block fs-6">
                                    {formatRupiah(v.inv_amount_charged)}
                                    </span>
                                </td>
                                <td>
                                    {v.status === 'PAID' &&
                                        <span className="badge py-3 px-4 fs-7 badge-light-success"> Paid </span>
                                    }
                                    {v.status === 'PENDING' &&
                                        <span className="badge py-3 px-4 fs-7 badge-light-secondary"> API failed </span>
                                    }
                                    {v.status === 'SENT' &&
                                        <span className="badge py-3 px-4 fs-7 badge-light-danger"> Unpaid </span>
                                    }
                                </td>
                                <td>
                                    <div className="btn-group">
                                        {widget_delete === true && (
                                            <></>
                                        )}

                                        <ButtonTersier className="btn-sm btn-icon btn-primary text-hover-primary" type="button" onClick={() => HandlerSendInv(v.registration_id)} title="Resend Invoice">
                                            <i class="bi bi-send-arrow-down"></i>
                                        </ButtonTersier>

                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7}>No record found</td>
                        </tr>
                    )}
                </tbody>
                {totalItems > 0 ? (
                    <tfoot>
                        <tr>
                            <td colSpan={7}>
                                <div className="footer">
                                    <PaginationComponent
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                ) : (
                    ""
                )}
            </table>
        </div>
    )
}



