import React, { useMemo, useState } from "react";
// import zlib from "zlib"
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../layouts/datatables/MYDatatables";
import moment from "moment";
import {
  ConfirmRemove,
  MessageError,
  MessageSuccess,
  openModal,
} from "../layouts/modals/Modals";
import { Link, useOutletContext } from "react-router-dom";
//import { RenderAccessWidgets } from '../RenderAccessUser';
import { LoadingBars } from "../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReq } from "../../helpers/AxiosLibs";
import { ButtonTersier } from "../layouts/buttons";
import { OverlayTrigger, Container, Row, Col, Button } from "react-bootstrap";
//import { renderTooltip } from "../layouts/popovers/PopOvers";
import { formatRupiah } from "./FormAtributes";
import { AuthDecodeToken } from "../authentications/reducers/AuthRedux";
import sign from "jwt-encode";
import packageJSON from "../../../../package.json";

import './TableData.css';
import { Tooltip } from 'react-tooltip';

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const Token = AuthDecodeToken();
  const panjang = Object.values(data).length;
  //const widget_delete = RenderAccessWidgets(context.widgets, 32);

  const [colorItems, setColorItems] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  const generatePastelColor = () => {
    // Generate a pastel color by using higher base values
    const r = Math.floor(Math.random() * 55 + 200); // 200-255
    const g = Math.floor(Math.random() * 55 + 200); // 200-255
    const b = Math.floor(Math.random() * 55 + 200); // 200-255

    // Convert to hex
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  };

  function generatePastelGradient() {
    // Generate a pastel color
    const pastelColor = () => {
      const hue = Math.floor(Math.random() * 360);
      const saturation = Math.floor(Math.random() * 25) + 70; // 70-95%
      const lightness = Math.floor(Math.random() * 15) + 75; // 75-90%
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    // Generate random angle between 0 and 360 degrees
    const angle = Math.floor(Math.random() * 361);

    // Generate two pastel colors
    const color1 = pastelColor();
    const color2 = pastelColor();

    // Return the style object
    return {
      background: `linear-gradient(${angle}deg, ${color1} 0%, ${color2} 100%)`,
    };
  }

  const momentTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const timeMoment = moment({ hours, minutes, seconds });
    const formattedTime = timeMoment.format("h:mm a");
    return formattedTime;
  };

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 3;
  const headers = [
    { name: "No", field: "event_id", sortable: false },
    { name: "Title", field: "short_title", sortable: true },
    { name: "Description", field: "description", sortable: true },
    { name: "Event Start", field: "event_start", sortable: true },
    { name: "Location", field: "location", sortable: true },
    { name: "Actions", field: "event_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(listData).length > 0) {
      computedData = listData.map((v, index) => {
        var obj = v;
        //obj.status_name = v.status === 1 ? "Draft":"Sended"
        obj.num = index + 1;
        return obj;
      });
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.values(listData).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          if (typeof value === "object") {
            return Object.values(value).some((subValue) =>
              String(subValue).toLowerCase().includes(search.toLowerCase())
            );
          }
          return String(value).toLowerCase().includes(search.toLowerCase());
        });
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [listData, search, sorting, currentPage]);

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });



  const items_fee = (items) => {
    return Object.values(items).length > 0
      ? items.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-800 fw-bold">
            <span className="ms-n1">
              {v.label ? v.label : v.code_name} : {formatRupiah(v.price)}
            </span>
          </span>
        </div>
      ))
      : "";
  };
  const firstLetter = (nm) => {
    return nm[0];
  };

  const createRegistrationLink = (eventId) => {
    const payload = { event_id: eventId };
    //const token = sign(payload, packageJSON.system_param.PublicKey);
    const token = btoa(JSON.stringify(payload)).replace(/\//g, '_').replace(/\+/g, '-');
    return `/registration/${token}`;
  };

  const createButtonLink = (tglStart, tglEnd, eventid) => {
    const currentDate = moment(); // dapatkan tanggal saat ini
    const startDate = moment(tglStart);
    const endDate = moment(tglEnd);

    const isWithinDateRange = currentDate.isBetween(startDate, endDate); // periksa apakah tanggal saat ini berada dalam rentang tanggal

    return (
      <div>
        {isWithinDateRange ? (
          <Link
            className="btn px-1 me-6 mb-1 tt-date"
            type="button"
            to={createRegistrationLink(eventid)}
          >
            <button className="btn btn-primary btn-lg mb-3" id="registerBtn">Register Now</button>
          </Link>

        ) :

          <span className="d-inline-block tt-date" tabIndex="0">
            <button className="btn btn-primary btn-lg mb-3" disabled id="registerBtn2">Register Now</button>
          </span>


        }
        <Tooltip anchorSelect=".tt-date" place="right">
          Registration period:<br />
          {moment(tglStart).format("DD MMM YYYY H:mm(a)")} -
          {moment(tglEnd).format("DD MMM YYYY H:mm(a)")}
        </Tooltip>
      </div>
    );
  };

  const contact_event = (contacts) => {
    return Object.values(contacts).length > 0
      ? contacts.map((v, index) => (
        <div className="m-0" key={index}>
          <div className="px-1 me-1 mb-3">
            <div className="fw-bold fs-8 text-gray-800"> {v.fullname}</div>
            <div className="fw-bold fs-8 text-gray-800"> {v.email}</div>
            <div className="fw-bold fs-8 text-gray-800"> {v.phone}</div>
          </div>
        </div>
      ))
      : "";
  };

  const coupons = (coupon) => {
    return Object.values(coupon).length > 0
      ? coupon.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-700 fw-bold">
            <span className="ms-n1 p-1">
              {v.coupon_code} : {v.discount * 100}%
            </span>
          </span>
        </div>
      ))
      : "";
  };



  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>


      {Object.values(ResultData).length > 0 ? (
        ResultData.map((v, index) => (
          <div className="table-responsive card card-flush h-xl-100" key={index} style={{ backgroundColor: 'transparent' }}>
            <div className="card-body py-0">
              <div className="row mb-0">
                <div className="col-12 col-md-10 event-bg" >
                  <div className="shortTitle" >{decodeURIComponent(v.short_title)}</div>
                  <div className="longTitle" >{decodeURIComponent(v.full_title)}</div>
                  <div className="mb-3">
                    <span className="fw-semibold text-gray-800 fs-7 mb-2 d-block">
                      <div className="description-container">
                        {(() => {
                          const decodedDescription = decodeURIComponent(v.description);
                          const words = decodedDescription.split(' ');
                          if (words.length > 50) {
                            const shortDescription = words.slice(0, 50).join(' ');
                            const isExpanded = expandedDescriptions[v.event_id];
                            return (
                              <>
                                <div dangerouslySetInnerHTML={{ __html: isExpanded ? decodedDescription : shortDescription + '...' }} />
                                <button
                                  className="btn btn-link p-0"
                                  onClick={() => setExpandedDescriptions(prev => ({ ...prev, [v.event_id]: !prev[v.event_id] }))}
                                >
                                  {isExpanded ? 'Read Less' : 'Read More'}
                                </button>
                              </>
                            );
                          } else {
                            return <div dangerouslySetInnerHTML={{ __html: decodedDescription }} />;
                          }
                        })()}
                      </div>
                    </span>
                  </div>
                  {createButtonLink(v.reg_start, v.reg_end, v.event_id)}
                  <div className="info-container">
                    <table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td className="icon-cell"><i className="bi bi-geo-alt-fill"></i></td>
                          <td className="label-cell"><span className="fs-8 text-gray-800 ">Location</span></td>
                          <td className="colon-cell">:</td>
                          <td><span className="fs-8 text-gray-800 ">{v.info_location}</span></td>
                        </tr>
                        <tr>
                          <td className="icon-cell"><i className="bi bi-calendar-event"></i></td>
                          <td className="label-cell"><span className="fs-8 text-gray-800 ">Date</span></td>
                          <td className="colon-cell">:</td>
                          <td>
                            <span className="fs-8 text-gray-800 ">
                              {moment(v.event_start).format("DD MMM YYYY")}, {momentTime(v.info_time_start)}
                            </span> - <span className="fs-8 text-gray-800">
                              {moment(v.event_end).format("DD MMM YYYY")}, {momentTime(v.info_time_end)}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td className="icon-cell"><i className="bi bi-person-fill"></i></td>
                          <td className="label-cell"><span className="fs-8 text-gray-800 ">Contact</span></td>
                          <td className="colon-cell">:</td>
                          <td>{contact_event(v.Contacts)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className="image-placeholder d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    {v.logo ? (
                      <img
                        src={v.logo}
                        alt="Event Logo"
                        style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                      />
                    ) : (
                      <div className="no-image-placeholder text-center">
                        <i className='bi bi-image' style={{ fontSize: '4rem', color: '#ccc' }}></i>
                        <p>No image available</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        ))
      ) : (
        <span className="fs-6 text-gray-800 fw-bold">No record found</span>

      )}
      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <tbody>
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={1}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  );
}
