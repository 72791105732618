import React from "react";
import { Link } from "react-router-dom";
import Landing from "./Landing";
import Modals from "../layouts/modals/Modals";
import ListEvent from "./ListEvent";
import ComingEvent from "./ComingEvent";
import LogoImg from "../../../_metronic/media/logo-sgu-white.png";
import BgImg from "../../../_metronic/media/auth/bg6.jpg";
import packageJSON from "../../../../package.json";

export default function LandingPage() {
  var datetime = new Date();
  var year = datetime.getFullYear();
  return (
    <div
      className="d-flex flex-column flex-center flex-column-fluid"
      style={{
        backgroundImage: `url(${BgImg})`
      }}
    >
      <div className="d-flex flex-column flex-center w-100 min-h-100px min-h-lg-100px px-9">
        <div className="d-flex align-items-center justify-content-center mb-1 mb-lg-1 py-1 py-lg-1">
          <img alt="Logo" src={LogoImg} className="h-80px me-5" />
          <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-0">
            Build An Outstanding Solutions <br /> with{" "}
            <span
              style={{
                background:
                  "linear-gradient(to right, #3A7BD5 0%, #00D2FF 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                display: "inline-block",
              }}
            >
              <span id="kt_landing_hero_text">The Best University</span>
            </span>
          </h1>
        </div>
      </div>

      <div
        id="kt_app_content_container"
        className="app-container container-xxl w-100"
      >
         {/*
         <div className="row">
         <div className="col-xl-2">
         <img alt="Logo" src={LogoImg} className="h-50px me-3 mb-3" />
          </div>
         </div>*/}
        <div className="row gy-10 gx-xl-10">
         {/*  <div className="col-xl-3 ">
            <ListEvent />
          </div>
          */}
          
          <div className="col-xl-12">
            <ComingEvent />
          </div>
          <span
                style={{
                  background:
                    "linear-gradient(to right, #3A7BD5 0%, #00D2FF 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  display: "inline-block",
                }}
              >
                <span>Swiss German University - v.{packageJSON.version}</span>
              </span>
        </div>
        
      </div>


    </div>
  );
}
