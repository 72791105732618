import React, { useEffect, useState } from "react";
import TableData from "./TableData";
//import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { LoadingBars, NoRecordFound } from "../../layouts/loadbars/LoadingBars";
import { ButtonCreateCoupon, NavTabs } from "./components/FormAtributes";
import { useOutletContext } from "react-router-dom";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { GET_ALL_DATA } from "./Apis";
import { CardBox } from "../../layouts/cards/CardUI";
import { openModal } from '../../layouts/modals/Modals';
import GenerateItem from './GenerateItem';
import FilterData from "./components/FilterData";
import { useAtom } from 'jotai';
import { counterAtom } from '../../../helpers/Atoms';
import DownloadReport from "./components/DownloadReport";

export function Coupons() {
    const context = useOutletContext();
    //console.log(context)
    const [campaigns, setCampaigns] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const [count, setCount] = useAtom(counterAtom);
    const [filterDt, setFilterDt] = useState({
        event_id: (count) ? count : 0,
        event_label: '',
    });
    const ReloadData = async () => {
        const results = await GET_ALL_DATA(filterDt);
        if (results.data && Object.values(results.data).length > 0) {
            setCampaigns({ loading: false, data: results.data, message: "" });
        } else if (results.message) {
            setCampaigns({ loading: false, data: [], message: results.message });
        } else {
            setCampaigns({ loading: false, data: [], message: "No record found" });
        }
    };

    const HandlerSendItem = (jmlh) => {
        return openModal({ header: "Import Item Accurate", message: <GenerateItem jmlh={jmlh} ReloadData={ReloadData} /> });
    }
    const HandlerFilter = () => {
        return openModal({ header: "Filter Data", message: <FilterData filterDt={filterDt} setFilterDt={setFilterDt} ReloadData={ReloadData} /> });
    }
    const HandlerClear = () => {
        setCount(0);
        setFilterDt(prevState => ({
            ...prevState,
            event_id: 0,
            event_label: ''
        }));
        ReloadData();
    }
    useEffect(() => {
        ReloadData();
    }, [filterDt]);

    const widget_list = RenderAccessWidgets(context.widgets, 34);
    const widget_create = RenderAccessWidgets(context.widgets, 35);
    //const widget_import = RenderAccessWidgets(context.widgets, 28);
    return (
        (widget_list === true ? (
            <CardBox>
                <CardBox.Header>
                    <span className="card-label fw-bold fs-3 mb-1">Master coupons</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">List of all coupons</span>
                </CardBox.Header>
                <CardBox.Toolbar>

                    <div className="btn-group">

                        <button className="nav-link btn btn-sm btn-secondary fw-bold px-4 me-1" type="button" onClick={() => HandlerClear()}>
                            <span className="text-black fw-bold p-2">
                                Refresh
                            </span>
                            <i class="bi bi-repeat"></i>
                        </button>
                        <button className="nav-link btn btn-sm btn-info fw-bold px-4 me-1" type="button" onClick={() => HandlerFilter()}>
                            <span className="text-white fw-bold p-2">
                                Filter
                            </span>
                            <i class="bi bi-funnel"></i>
                        </button>
                        {widget_create === true && <ButtonCreateCoupon />}
                        <button
                            className="nav-link btn btn-sm btn-secondary fw-bold px-2 me-1"
                            type="button"
                            onClick={(e) => DownloadReport(campaigns.data, ("list Coupon"))}
                        >
                            <i className="bi bi-download"></i> 
                            <span className="text-black fw-bold p-2">
                            Export to excel
                            </span>
                            
                        </button>
                    </div>
                </CardBox.Toolbar>
                <CardBox.Body>
                    {campaigns.loading ? (
                        <LoadingBars />
                    ) : campaigns.message ? (
                        <div className="p-3 border rounded text-danger bg-light-danger">
                            {campaigns.message}
                        </div>
                    ) : Object.values(campaigns.data).length > 0 ? (
                        <TableData data={campaigns.data} ReloadData={ReloadData} />
                    ) : (
                        <NoRecordFound message="No record found" />
                    )}
                </CardBox.Body>
            </CardBox >
        ) : widget_list)
    );


}


