import React from "react";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";

const GET_ALL_CAMPAIGN = async () => {
    try {
        const parameter = {
            header: {},
            body: {},
            method: "get",
        };
        const response = await AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/campaigns"
        );
        if (response.error) {
            return { loading: false, data: [], message: response.error };
        } else if (response.data && response.status === 200) {
            var results = response.data;
            results.sort((a, b) => (a.campaign_id < b.campaign_id ? 1 : -1));
            const sendedData = results.filter((item) => item.status === 2);
            const draftData = results.filter((item) => item.status === 1);
            return { loading: false, data: { all: results, draft: draftData, send: sendedData }, message: "" };
        } else {
            return { loading: false, data: [], message: "No record found" };
        }
    } catch (error) {
        console.log(error);
        return { loading: true, data: [], message: "" };
    }
}

export { GET_ALL_CAMPAIGN }