import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { ButtonPrimary, ButtonTersier } from "../buttons";
import { useNavigate } from "react-router-dom";

const states = {
  setState: null,
  changeState(data) {
    if (!this.setState) return;
    this.setState((prevData) => {
      return {
        ...prevData,
        ...data,
      };
    });
  },
};

const handleClose = () => {
  states.changeState({
    open: false,
  });
};

const Modals = () => {
  const [data, setData] = useState({
    open: false,
    header: "ini header default",
    message: "ini message default",
    size: "md",
    footer: "",
    onClose: handleClose,
  });

  states.setState = setData;

  return (
    <Modal show={data.open} onHide={data.onClose} size={data.size}>
      {data.header ? (
        <Modal.Header closeButton>
          <h3 className="modal-title">{data.header}</h3>
        </Modal.Header>
      ) : (
        ""
      )}
      <Modal.Body>{data.message}</Modal.Body>
      {data.footer ? (
        <Modal.Footer>
          <button onClick={data.onClose} className="btn btn-secondary px-10">
            No
          </button>
          {data.footer}
        </Modal.Footer>
      ) : (
        ""
      )}
    </Modal>
  );
};

const openModal = ({
  open = true,
  message,
  header,
  size,
  footer,
  onClose = () => {},
}) => {
  states.changeState({
    message,
    header,
    size,
    open,
    footer,
    onClose: () => {
      onClose();
      handleClose();
    },
  });
};

const MessageError = ({ message }) => {
  return (
    <div className="text-center">
      <div className="icon mb-5">
        <i
          className="bi bi-x-circle text-danger"
          style={{ fontSize: "5em" }}
        ></i>
      </div>
      <p className="mb-10">{message}</p>
      <button
        className="btn btn-lg btn-primary fw-bolder"
        type="button"
        onClick={() => openModal({ open: false })}
      >
        Ok, got it!
      </button>
    </div>
  );
};

const MessageSuccess = ({ message }) => {
  return (
    <div className="text-center">
      <div className="icon mb-5">
        <i
          className="bi bi-check-circle text-success"
          style={{ fontSize: "5em" }}
        ></i>
      </div>
      <p className="mb-10">{message}</p>
      <button
        className="btn btn-lg btn-primary fw-bolder"
        type="button"
        onClick={() => openModal({ open: false })}
      >
        Ok, got it!
      </button>
    </div>
  );
};

const ConfirmLeave = ({ message, url }) => {
  const navigate = useNavigate();
  const HandlerLeave = () =>{
    openModal({ open: false });
    navigate(url);
  }
  return (
    <div className="text-center">
      <p className="mb-10 fs-3">{message}</p>
      <div className="">
        <ButtonTersier type="button" className="me-5" onClick={() => openModal({ open: false })} >Cancel</ButtonTersier>
        <ButtonPrimary type="button" onClick={HandlerLeave}>Leave Page</ButtonPrimary>
      </div>
    </div>
  );
};

const ConfirmRemove = ({ message, handler }) => {
  
  return (
    <div className="text-center">
      <p className="mb-10 fs-3">{message}</p>
      <div className="">
        <ButtonTersier type="button" className="me-5" onClick={() => openModal({ open: false })} >Cancel</ButtonTersier>
        <ButtonPrimary type="button" onClick={handler}>Confirm</ButtonPrimary>
      </div>
    </div>
  );
};

export default Modals;
export { openModal, MessageError, MessageSuccess, ConfirmLeave, ConfirmRemove };
