import React from 'react'
import Popover from 'react-bootstrap/Popover';
import { Tooltip } from "react-bootstrap";


const renderPopOver = (props) => (
    <Popover id="popover-basic">
        <Popover.Header as="h3">{props.header}</Popover.Header>
        <Popover.Body>
            {props.body}
        </Popover.Body>
    </Popover>
);


const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" className="custom-tooltip" {...props}>
        {props.title}
      </Tooltip>
    );
  };

export {renderPopOver, renderTooltip}
