import React from "react";
import moment from "moment";
import { useState } from "react";
import {
  AxiosLibGlobalReq,
  AxiosLibGlobalReqModule,
} from "../../../helpers/AxiosLibs";
import { useEffect } from "react";
import { LoadingBars } from "../../layouts/loadbars/LoadingBars";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { ButtonPrimary } from "../../layouts/buttons";

export default function DetailCampaign({ campaign_id, access }) {

  var Token = AuthDecodeToken();
  const [campaigns, setCampaigns] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ShowCampaign = (param) => {
    setCampaigns({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/campaigns/detail"
    ).then((response) => {
      if (response.error) {
        setCampaigns({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setCampaigns({ loading: false, data: response.data, message: "" });
      } else {
        setCampaigns({ loading: false, data: [], message: "No record found" });
      }
    });
  };

  useEffect(() => {
    ShowCampaign({ campaign_id: campaign_id });
  }, []);

  const HandlerPublish = (data, type) => {
    var datapush = {};
    datapush.channel = "notif.channel.type.mobile";
    datapush.schd_type = "direct"; //direct / schedule
    datapush.subject = data.subject;
    datapush.target_user = JSON.parse(data.recepients);
    datapush.body = encodeURIComponent(data.messages);

    PublishCampaigns(datapush, type);
  };

  const [publish, setPublish] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const PublishCampaigns = (data, type) => {
    setPublish({ loading: true, data: [], message: "" });
    var headers = {
      AppId: Token.AppID,
      AppEnv: Token.AppEnv,
      Secretkey: Token.Secretkey,
    };
    var bodys = {
      parameters: data,
      target_data: "CREATE_NOTIFICATION",
    };
    const parameter = {
      header: headers,
      body: bodys,
      method: "post",
    };

    AxiosLibGlobalReqModule(
      parameter,
      process.env.REACT_APP_ESTES_API +
      process.env.REACT_APP_ESTES_API_PATH_REQUEST
    ).then((response) => {
      if (response.error) {
        setPublish({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        setPublish({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        if (response.response.data) {
          if (type === 1) {
            UpdateStatusCampaigns({ status: 2, campaign_id: campaign_id });
          }
          setPublish({
            loading: false,
            data: response.response.data,
            message: "The notification has been successfully published.",
          });
        } else {
          setPublish({ loading: false, data: [], message: "Failed publish" });
        }
      }
    });
  };
  const [updStatus, setUpdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const UpdateStatusCampaigns = (param) => {
    setUpdStatus({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/campaigns/update-status"
    ).then((response) => {
      if (response.error) {
        setUpdStatus({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        if (param.status === 2) {
          setUpdStatus({
            loading: false,
            data: response.data,
            message: "Success saved",
          });
        } else {
          setUpdStatus({
            loading: false,
            data: response.data,
            message: "Successfully Saved",
          });
          openModal({
            message: <MessageSuccess message={"Successfully Saved"} />,
          });
        }
      } else {
        setUpdStatus({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  return campaigns.loading ? (
    <LoadingBars />
  ) : campaigns.message ? (
    <MessageError message={campaigns.message} />
  ) : Object.values(campaigns.data).length > 0 ? (
    <div className="detail-campaign">
      <div className="mb-3">
        <h1 className="fw-bolder">
          {decodeURIComponent(campaigns.data.subject)}
        </h1>
      </div>
      <div className="mb-3">
        <label>
          To: ({Object.values(JSON.parse(campaigns.data.recepients)).length}{" "}
          mails)
        </label>
        <FetchMail data={JSON.parse(campaigns.data.recepients)} />
      </div>
      <div className="mb-3">
        <label>Messages:</label>
        <div className="d-block border rounded p-3 bg-light">
          <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(campaigns.data.messages) }} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="mb-3">
            <label>Created:</label>
            <p>
              <span className="d-block fw-bold">
                {moment(campaigns.data.createdAt).format(
                  "DD MMM YYYY, hh:mm A"
                )}
              </span>
              <span className="text-muted">{campaigns.data.created_by}</span>
            </p>
          </div>
        </div>
        <div className="col">
          <div className="mb-3">
            <label>Status:</label>
            <p>
              <span className="d-block fw-bold">
                {campaigns.data.status === 1 ? "Draft" : "Already Publish"}
              </span>
            </p>
          </div>
        </div>
      </div>

      {publish.message || updStatus.message ? (
        <div
          className={
            "p-3 border rounded " +
            (Object.values(publish.data).length > 0 && publish.message
              ? "bg-info border-info"
              : "bg-danger border-danger")
          }
        >
          <i className="bi bi-exclamation-triangle me-2 text-white"></i>{" "}
          <span className="text-white">
            {publish.message || updStatus.message}
          </span>
        </div>
      ) : (
        ""
      )}

      <div className="my-10">
        <div className="d-flex justify-content-end align-items-center">
          {/* <button className="btn btn-lg btn-light fw-bold" type="button" onClick={()=>ModifyCampaign(campaigns.data.campaign_id)}>Modify</button> */}
          {campaigns.data.status === 1 ? (
            access.widget_4 === true && (
              <div className="btn-group">
                <ButtonPrimary
                  className="btn-icon btn-lg"
                  disabled={publish.loading || updStatus.loading}
                >
                  <i className="bi bi-send fs-1"></i>
                </ButtonPrimary>
                <ButtonPrimary
                  className="btn-lg"
                  type="button"
                  disabled={publish.loading || updStatus.loading}
                  onClick={() => HandlerPublish(campaigns.data, 1)}
                >
                  {publish.loading || updStatus.loading ? (
                    <span>Processing...</span>
                  ) : (
                    <span className="me-2">Approve and Publish</span>
                  )}
                </ButtonPrimary>
              </div>
            )
          ) : (
            access.widget_5 === true && (
              <div className="btn-group">
                <ButtonPrimary
                  className="btn-icon btn-lg"
                  disabled={publish.loading || updStatus.loading}
                >
                  <i className="bi bi-send fs-1"></i>
                </ButtonPrimary>

                <ButtonPrimary
                  className="btn-lg"
                  type="button"
                  disabled={publish.loading || updStatus.loading}
                  onClick={() => HandlerPublish(campaigns.data, 2)}
                >
                  {publish.loading || updStatus.loading ? (
                    <span>Processing...</span>
                  ) : (
                    <span className="me-2">Resend</span>
                  )}
                </ButtonPrimary>

              </div>
            )
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

const FetchMail = ({ data }) => {
  const totaldata = Object.values(data).length;
  const totalshow = 3;
  console.log(totaldata);
  const dataToShow = data.slice(0, totalshow);
  const [expand, setExpand] = useState(false);

  return (
    <div>
      {Object.values(dataToShow).length > 0 ? (
        <div className={"d-flex flex-wrap " + (!expand ? "d-block" : "d-none")}>
          {dataToShow.map((v, index) => (
            <span className="me-1" key={index}>
              {v.email}{(index + 1) !== totalshow ? "," : ""}
            </span>
          ))}

          {Object.values(dataToShow).length >= 3 && (
            <span className="cursor-pointer" onClick={() => setExpand(!expand)}>
              <i className="bi bi-caret-down-fill"></i>
            </span>
          )}
        </div>
      ) : ""}

      <div className={"expand-mail " + (expand ? "d-block" : "d-none")}>
        <div className="d-flex flex-wrap">
          {data.map((v, index) => (
            <span className="me-1" key={index}>
              {v.email}{(index + 1) !== Object.values(data).length ? "," : ""}
            </span>
          ))}
        </div>
        <span className="cursor-pointer text-muted" onClick={() => setExpand(!expand)}>
          Show Less <i className="bi bi-caret-up-fill"></i>
        </span>
      </div>
    </div>
  )
}
