import sign from "jwt-encode";
import { jwtDecode } from "jwt-decode";
import { combineReducers } from "redux";
import { CryptoJSAesJson } from "../../../helpers/Encryptions";

const authReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return action.payload;

    case "SIGN_OUT":
      localStorage.removeItem("token");
      window.location = "/sign-in";
      return 0;
    default:
      return 0;
  }
};

const authStoreData = (value) => {
  const PRIVATE_KEY = process.env.REACT_APP_NOT_SECRET_CODE;

  if (value) {
    var encrypted_password = JSON.parse(
      CryptoJSAesJson.encrypt(value, PRIVATE_KEY)
    );

    const token = sign(encrypted_password, PRIVATE_KEY);
    localStorage.setItem("token", token);
  }
};

const AuthReducers = combineReducers({
  AuthToken: authReducer,
});

const AuthDecodeToken = () => {
  const PRIVATE_KEY = process.env.REACT_APP_NOT_SECRET_CODE;
  var results;
  var mytoken = localStorage.getItem("token");
  if (mytoken) {
    var token = mytoken ? jwtDecode(mytoken) : [];
    var decode_token = CryptoJSAesJson.decrypt(
      JSON.stringify(token),
      PRIVATE_KEY
    );
    results = decode_token;

    return results;
  }
};

export { AuthReducers, authReducer, authStoreData, AuthDecodeToken };
