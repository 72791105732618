import React, { useEffect, useState } from 'react'
import { AxiosLibGlobalReq } from '../../../../helpers/AxiosLibs';
import { useOutletContext } from 'react-router-dom';
import { RenderAccessWidgets } from '../../RenderAccessUser';
import { LoadingBars, NoRecordFound } from '../../../layouts/loadbars/LoadingBars';
import TableData from './TableData';

export function UserAccount() {
    const context = useOutletContext();
    console.log(context);
    const widget_14 = RenderAccessWidgets(context.widgets, 14);
    const [accountUser, setAccountUser] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const FetchAllDataUser = () => {
        setAccountUser({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: {},
            method: "get",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/user"
        ).then((response) => {
            if (response.error) {
                setAccountUser({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                var results = response.data;
                setAccountUser({ loading: false, data: results, message: "" });
            } else {
                setAccountUser({ loading: false, data: [], message: "No record found" });
            }
        });
    };

    const ReloadData = () => {
        FetchAllDataUser();
    };

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        (widget_14 === true ? (
            <div className='card card-xl-stretch mb-5 mb-xl-8'>
                <div className="card-header border-0 py-0">
                    <h3 className="card-title align-items-start flex-column mb-0">
                        <span className="card-label fw-bold fs-3 mb-1">Current User</span>
                    </h3>
                </div>
                <div className="card-body py-0">
                    {accountUser.loading ? <LoadingBars /> : accountUser.message ? (
                        <div className="p-3 border rounded text-danger bg-light-danger">
                            {accountUser.message}
                        </div>
                    ) : (
                        Object.values(accountUser.data).length > 0 ? (
                            <TableData data={accountUser.data} ReloadData={ReloadData} />
                        ) : (
                            <NoRecordFound message="No record found" />
                        )
                    )}
                </div>
            </div >
        ) : widget_14)
    )
}
