import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";
import { Link, Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";

const Token = AuthDecodeToken();

export function TemplateForm() {
  const context = useOutletContext();
  const widget_7 = RenderAccessWidgets(context.widgets, 7);
  const navigate = useNavigate()
  let params = useParams();
  const template_id = params.template_id;

  const editorRef = useRef(null);
  
  const objParam = {
    template_id: 0,
    name: "",
    subject: "",
    messages: "",
    created_by: Token.identity.given_name,
  };
  const [post, setPost] = useState(objParam);
  const [temp, setTemp] = useState({loading:false, data:[], message:""});

  useEffect(() => {
    if (template_id) {
      GetTemplateByID(template_id, setTemp, setPost)
    }
  }, []);

  const [submitPost, setSubmitPost] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const CreateTemplates = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/templates/save"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Saved",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Saved"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/templates");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const UpdateTemplate = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/templates/update"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Updated",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Updated"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/templates");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    if (post.name && post.subject && post.messages) {
      const param = RemoveEmptyValue(post);
      param.name = encodeURIComponent(param.name);
      param.subject = encodeURIComponent(param.subject);
      param.messages = encodeURIComponent(param.messages);
      if(post.template_id){
        param.template_id = param.template_id
        UpdateTemplate(param);
      }else{
        CreateTemplates(param);
      }
      
    } else {
      openModal({
        message: (
          <MessageError message={"Please fill out the form with correctly"} />
        ),
      });
    }
  };

  return (
    (widget_7 === true ? (
    <div className="card">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Form Templates</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Please fill up the form with correctly
          </span>
        </h3>
      </div>
      <div className="card-body">
        <form
          method="post"
          autoComplete="off"
          id="form-post"
          onSubmit={(e) => SubmitForm(e)}
        >
          <div className="mb-5">
            <label className="required fw-bolder">Name of Templates</label>
            <input
              type="text"
              required
              name="name"
              className="form-control"
              defaultValue={decodeURIComponent(post.name)}
              onChange={(e) => setPost({ ...post, name: e.target.value })}
            />
          </div>
          <div className="mb-5">
            <label className="required fw-bolder">Subject</label>
            <input
              type="text"
              required
              name="Subject"
              className="form-control"
              defaultValue={decodeURIComponent(post.subject)}
              onChange={(e) => setPost({ ...post, subject: e.target.value })}
            />
          </div>

          <div className="mb-5">
            <label className="required fw-bolder">Messages</label>
            <Editor
              apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
              className="required"
              value={post.messages}
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={(newValue, editor) => {
                setPost({ ...post, messages: newValue });
              }}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor  link | alignleft aligncenter |" +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                content_style:
                  "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
              }}
            />
          </div>

          {submitPost.message ? (
            <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mb-5">
              {submitPost.message}
            </div>
          ) : (
            ""
          )}

          <div className="text-end mt-10 d-flex justify-content-between">
            <Link
              className="btn btn-lg btn-light fw-bold me-3"
              to={"/templates"}
            >
              Cancel
            </Link>
            <button
              className="btn btn-lg btn-primary fw-bold btn-submit"
              type="submit"
              disabled={submitPost.loading}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
    ):<AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />)
  );
}

const GetTemplateByID = (template_id, setTemp, setPost) => {
  setTemp({ loading: true, data: [], message: "" });
  const parameter = {
    header: {},
    method: "get",
  };
  AxiosLibGlobalReq(
    parameter,
    process.env.REACT_APP_EXPRESS_API + "/api/templates/"+ template_id
  ).then((response) => {
    if (response.error) {
        setTemp({ loading: false, data: [], message: response.error });
    } else if (response.data && response.status === 200) {
      const result = response.data;
        setTemp({
          loading: false,
          data: result,
          message: "Successfully Saved",
        });
        setPost({
          template_id: result.template_id,
          name: result.name,
          subject: result.subject,
          messages: result.messages,
          created_by: Token.identity.given_name,
        })
        console.log(response.data);
    } else {
        setTemp({ loading: false, data: [], message: "Failed saved" });
    }
  });
};
