import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../layouts/datatables/MYDatatables";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../layouts/popovers/PopOvers";
import moment from "moment";
import { ButtonTersier } from "../../layouts/buttons";
import { Link, useOutletContext } from "react-router-dom";
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from "../../layouts/modals/Modals";
import { LoadingBars } from "../../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { RenderAccessWidgets } from "../RenderAccessUser";

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_8 = RenderAccessWidgets(context.widgets, 8);
  const widget_9 = RenderAccessWidgets(context.widgets, 9);

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "No", field: "campaign_id", sortable: false },
    { name: "Subject", field: "subject", sortable: false },
    { name: "Created At", field: "createdAt", sortable: true },
    { name: "Actions", field: "campaign_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = listData;

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key].toString().toLowerCase().includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [listData, search, sorting]);

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const HandlerRemove = (template_id) => {
    const param = { template_id: template_id };
    const HandlerYes = () => {
      RemoveTemplate(param);
    }

    openModal({ header: "Confirmation", message: <ConfirmRemove message={"Are you sure you want to delete this item?"} handler={HandlerYes} /> })
  };

  const RemoveTemplate = (param) => {
    setRemove({ loading: true, data: [], message: "" });
    openModal({ header: "", message: "Please wait while the item is being deleted. " + <LoadingBars /> })
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/templates/remove"
    ).then((response) => {
      if (response.error) {
        setRemove({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setRemove({
          loading: false,
          data: response.data,
          message: "Successfully Removed",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Removed"} />,
        });
        ReloadData();
      } else {
        setRemove({ loading: false, data: [], message: "Failed remove" });
        openModal({
          message: <MessageError message={"Failed remove"} />,
        });
      }
    });
  };

  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>

      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td width={"5%"} className="text-center">
                  {index + 1}
                </td>
                <td>
                  <span className="fw-bold">
                    {decodeURIComponent(v.subject)}
                  </span>
                  <div className="alert bg-light p-1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeURIComponent(v.messages),
                      }}
                    />
                  </div>
                </td>
                <td width={"20%"}>
                  <span className="d-block fw-bolder">
                    {moment(v.createdAt).format("DD MMM YYYY, hh:mm A")}
                  </span>
                  <span className="text-muted">{v.created_by}</span>
                </td>
                <td width={"10%"}>
                  <div className="btn-group">
                    {widget_8 === true && (
                      <OverlayTrigger
                        delay={{ show: 250, hide: 400 }}
                        placement="top"
                        overlay={renderTooltip({ title: "Edit" })}
                      >
                        <Link
                          className="btn btn-sm  btn-light btn-icon"
                          type="button"
                          to={"update/" + v.template_id}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </Link>
                      </OverlayTrigger>
                    )}
                    {widget_9 === true && (
                      <OverlayTrigger
                        delay={{ show: 250, hide: 400 }}
                        placement="top"
                        overlay={renderTooltip({ title: "Remove" })}
                      >
                        <ButtonTersier
                          className="btn-sm btn-icon"
                          type="button"
                          onClick={() => HandlerRemove(v.template_id)}
                        >
                          <i className="bi bi-trash"></i>
                        </ButtonTersier>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

