import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './app/App';
import {createStore} from 'redux';
import { authReducer } from './app/components/authentications/reducers/AuthRedux';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SplashScreenProvider } from './app/components/layouts/core/SplashScreen';



const { PUBLIC_URL } = process.env.REACT_APP_BASE_URL;

const store = createStore(
  authReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

//const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SplashScreenProvider>
      <App store={store} basename={PUBLIC_URL} />
    </SplashScreenProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
