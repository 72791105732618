import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./components/authentications";
import BasePage from "./BasePage";
import LayoutInit from "./components/layouts/LayoutInit";
import Error404 from "./components/layouts/errors/Error404";
import { AxiosLibGlobalReq } from "./helpers/AxiosLibs";
import { AuthDecodeToken } from "./components/authentications/reducers/AuthRedux";
import RegisterLayout from "./components/registration";
import LandingPage from "./components/landing-page";



var Token = AuthDecodeToken();
console.log(Token);

export default function AppRoutes() {

    const isAuthorized = localStorage.getItem("token");
    const dispatch = useDispatch();
    const SignOut = () => {
        dispatch({ type: "SIGN_OUT" });
    };

    const [results, setResults] = useState({ loading: false, data: [], message: "" });
    const [menu, setMenu] = useState([]);
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        if (isAuthorized && results && results.data) {
            const param = { group_id: Token.RoleID }
            FeetchRoutes(param, setRoutes, setMenu, setResults)
        }
    }, [])

    return (
        <Routes>
            <Route path="/error/*" element={<LayoutInit><Error404 /></LayoutInit>} />
            <Route path="/sign-out" element={<SignOut />} />
            <Route
                path="/registration/*"
                element={
                        <RegisterLayout />
                    
                }
            />
            <Route path="/landing-page" element={<LandingPage />} />

            {(isAuthorized) ? (
                results && results.data && results.data.length > 0 ? (
                    <>
                        <Route path="/*" element={<LayoutInit menu={menu}><BasePage routes={routes} /></LayoutInit>} />
                        <Route index element={<Navigate to='/dashboard' />} />
                    </>
                ) : ""
            ) : (
                <>
                    <Route path='/sign-in' element={<AuthLayout />} />
                    <Route path='*' element={<Navigate to='/landing-page' />} />
                </>
            )}

            {/* <Route path='*' element={<Navigate to='/error' />} /> */}
        </Routes>
    )
}

const FeetchRoutes = (param, setRoutes, setMenu, setResults) => {
    setRoutes([]); setMenu([]);
    setResults({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        body: param,
        method: "post",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/menu/by-group"
    ).then((response) => {
        if (response.error) {
            setResults({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            var results = response.data;
            const menuData = results.map(({ routes, ...rest }) => rest);
            const routesData = results.flatMap(item => item.routes);
            const routesMap = routesData.reduce((acc, route) => {
                if (route.parent_id !== 0) {
                    const parentRoute = acc.find(r => r.route_id === route.parent_id);
                    if (parentRoute) {
                        parentRoute.child = parentRoute.child || [];
                        parentRoute.child.push(route);
                    }
                } else {
                    acc.push(route);
                }
                return acc;
            }, []);
            setMenu(menuData);
            setRoutes(routesMap);
            setResults({ loading: false, data: results, message: "" });
        } else {
            setResults({ loading: false, data: [], message: "No record found" });
        }
    });
};
