import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq, AxiosLibBillApp } from "../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";
import { FormEventDetail } from "./components/FormEventDetail";
import Moment from "moment";
import { FormEventContact } from "./components/FormEventContact";
import { FormEventItem } from "./components/FormEventItem";
import { GET_ALL_ITEM, GET_ALL_CONTACT, GET_ALL_COUPON } from "./Apis";
import { FormEventCoupon } from "./components/FormEventCoupon";

const Token = AuthDecodeToken();

export function EventForm() {
  const context = useOutletContext();
  const widget_create = RenderAccessWidgets(context.widgets, 30);
  const navigate = useNavigate();
  let params = useParams();
  const event_id = params.event_id;
  const objParam = {
    event_id: 0,
    short_title: "",
    full_title: "",
    description: "",
    event_start: Moment().toDate(),
    event_end: null,
    info_time_start: "",
    info_time_end: "",
    info_location: "",
    info1: "",
    info2: "",
    info3: "",
    logo: "",
    logo_path: "",
    reg_start: Moment().toDate(),
    reg_end: null,
    is_active: 0,
    created_by: Token.identity.given_name,
  };
  const [post, setPost] = useState(objParam);
  const [postItem, setPostItem] = useState([]);
  const [lookUpItem, setLookUpItem] = useState([]);
  const [postContact, setPostContact] = useState([]);
  const [lookUpContact, setLookUpContact] = useState([]);
  const [postCoupon, setPostCoupon] = useState([]);
  const [lookUpCoupon, setLookUpCoupon] = useState([]);
  const [temp, setTemp] = useState({ loading: false, data: [], message: "" });

  const [submitPost, setSubmitPost] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const CreateEvent = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/event/save"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Saved",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Saved"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/events");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const UpdateEvent = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/event/update"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setSubmitPost({
          loading: false,
          data: response.data,
          message: "Successfully Updated",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Updated"} />,
        });
        setTimeout(() => {
          openModal({ open: false });
          navigate("/events");
        }, 1000);
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const handleImageResize = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set the max dimensions to 500x500
        const maxDimension = 300;
        let width = img.width;
        let height = img.height;

        // Resize the image if it's larger than 500x500
        if (width > height) {
          if (width > maxDimension) {
            height = Math.round((height *= maxDimension / width));
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width = Math.round((width *= maxDimension / height));
            height = maxDimension;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        // Convert the resized image to Base64
        //const base64String = canvas.toDataURL('image/jpeg'); // or 'image/jpeg'
        const base64String = canvas.toDataURL('image/jpeg', 0.5);
        //setLogoBase64(base64String);
        setPost({ ...post, logo: base64String });
      };
    };
  };

  const handleImageChange = (e) => {
    //setImage(e.target.files[0]);
    const file = e.target.files[0];
    if (file) {
      handleImageResize(file);
    }
    //setPost({ ...post, logo: e.target.files[0] });
  };

  const submitEvent = async  () => {
    console.log("post ", post);
    if (
      post.short_title &&
      post.full_title &&
      post.description &&
      post.info_time_start &&
      post.info_time_end &&
      post.info_location &&
      post.info1 &&
      post.reg_start &&
      post.reg_end &&
      Object.values(postItem).length > 0 &&
      Object.values(postContact).length > 0
    ) {
      const param = RemoveEmptyValue(post);

      if (post.event_id) {
        param.event_id = param.event_id;
        param.edited_by = Token.identity.given_name;
        if (!param.is_active) {
          param.is_active = 0;
        }
        let sendData;
        if (Object.values(postCoupon).length > 0) {
          sendData = {
            event: param,
            item: postItem,
            contact: postContact,
            coupon: postCoupon,
          };
        } else {
          sendData = {
            event: param,
            item: postItem,
            contact: postContact,
          };
        }
        UpdateEvent(sendData);
        console.log(sendData);
      } else {
        let sendData;
        param.short_title = encodeURIComponent(param.short_title);
        param.full_title = encodeURIComponent(param.full_title);
        param.description = encodeURIComponent(param.description);
        if (!param.is_active) {
          param.is_active = 0;
        }
        if (Object.values(postCoupon).length > 0) {
          sendData = {
            event: param,
            item: postItem,
            contact: postContact,
            coupon: postCoupon,
          };
        } else {
          sendData = {
            event: param,
            item: postItem,
            contact: postContact,
          };
        }
        CreateEvent(sendData);
        console.log(sendData);
      }
    } else {
      openModal({
        message: (
          <MessageError message={"Please fill out the form with correctly"} />
        ),
      });
    }
  };

  useEffect(() => {
    ItemData();
    ContactData();
    CouponData();
    if (event_id) {
      GetDataByID(
        event_id,
        setTemp,
        setPost,
        setPostItem,
        setPostContact,
        setPostCoupon
      );
    }
  }, []);

  const ItemData = async () => {
    const results = await GET_ALL_ITEM();
    if (results.data && Object.values(results.data).length > 0) {
      setLookUpItem(results.data);
    }
  };

  const ContactData = async () => {
    const results = await GET_ALL_CONTACT();
    if (results.data && Object.values(results.data).length > 0) {
      setLookUpContact(results.data);
    }
  };

  const CouponData = async () => {
    const results = await GET_ALL_COUPON();
    if (results.data && Object.values(results.data).length > 0) {
      setLookUpCoupon(results.data);
    }
  };

  return widget_create === true ? (
    <>
      <div className="d-flex flex-column flex-lg-row mb-5">
        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-6 me-xl-9">
          <FormEventDetail id={event_id} post={post} setPost={setPost} />
        </div>

        <div className="flex-lg-auto min-w-lg-350px">
          <div className="card mb-5">
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Create Event
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Please fill up the form with correctly
                </span>
              </h3>
            </div>
            <div className="card-body">
              <div className="mb-5">
                <label className="required fw-bolder">Status</label>
                <select
                  name="status"
                  id=""
                  className="form-select form-select-sm"
                  required
                  value={post.is_active}
                  onChange={(e) =>
                    setPost({ ...post, is_active: parseInt(e.target.value) })
                  }
                >
                  {post.event_id === 0 ? (
                    <option value="0">Draft</option>
                  ) : (
                    <>
                      <option value="0">Draft</option>
                      <option value="1">Published</option>
                      <option value="3">Archived</option>
                      <option value="5">Complete</option>
                    </>
                  )}
                </select>
              </div>
              <div className="mb-5">
              <input type="file" onChange={handleImageChange} required />
         
                </div>
              <div className="mb-5">
                <div className="text-end mt-10 d-flex justify-content-between">
                  <Link
                    className="btn btn-lg btn-light fw-bold me-3"
                    to={"/events"}
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn btn-primary fw-bold fs-6 "
                    type="button"
                    onClick={() => submitEvent()}
                    disabled={submitPost.loading}
                  >
                    <i className="bi bi-save"> </i>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* 
          <FormEventCoupon
            id={event_id}
            postCoupon={postCoupon}
            setPostCoupon={setPostCoupon}
            data={lookUpCoupon}
          />
          */}
          {submitPost.message ? (
            <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mt-5">
              {submitPost.message}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row mb-5">
        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-6 me-xl-6">
          <FormEventItem
            id={event_id}
            postItem={postItem}
            setPostItem={setPostItem}
            data={lookUpItem}
          />
        </div>

        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-6 me-xl-6">
          <FormEventContact
            id={event_id}
            postContact={postContact}
            setPostContact={setPostContact}
            data={lookUpContact}
          />
        </div>
      </div>

      <div className="d-flex flex-column flex-lg-row mb-5">
        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10"></div>

        <div className="flex-lg-auto min-w-lg-300px"></div>
      </div>
    </>
  ) : (
    <AlertNofif
      title={"Information"}
      messages={
        "You don't have permission to access this resource. Please contact the administrator."
      }
      color="danger"
    />
  );
}

const GetDataByID = (
  event_id,
  setTemp,
  setPost,
  setPostItem,
  setPostContact,
  setPostCoupon
) => {
  setTemp({ loading: true, data: [], message: "" });
  const parameter = {
    header: {},
    method: "get",
  };
  AxiosLibGlobalReq(
    parameter,
    process.env.REACT_APP_EXPRESS_API + "/api/event/" + event_id
  ).then((response) => {
    if (response.error) {
      setTemp({ loading: false, data: [], message: response.error });
    } else if (response.data && response.status === 200) {
      const result = response.data;
      setTemp({
        loading: false,
        data: result,
        message: "Successfully Saved",
      });
      setPost({
        event_id: result.event_id,
        short_title: result.short_title,
        full_title: result.full_title,
        description: result.description,
        event_start: result.event_start
          ? Moment(result.event_start).toDate()
          : Moment().toDate(),
        event_end: result.event_end
          ? Moment(result.event_end).toDate()
          : Moment().toDate(),
        info_time_start: result.info_time_start ? result.info_time_start : "",
        info_time_end: result.info_time_end ? result.info_time_end : "",
        info_location: result.info_location,
        info1: result.info1 ? result.info1 : "",
        info2: result.info2 ? result.info2 : "",
        info3: result.info3 ? result.info3 : "",
        reg_start: result.reg_start
          ? Moment(result.reg_start).toDate()
          : Moment().toDate(),
        reg_end: result.reg_end
          ? Moment(result.reg_end).toDate()
          : Moment().toDate(),
        is_active: result.is_active,
        created_by: Token.identity.given_name,
      });
      setPostItem(result.Items);
      setPostContact(result.Contacts);
      //setPostCoupon(result.EventsCoupons);
      console.log(result);
    } else {
      setTemp({ loading: false, data: [], message: "Failed saved" });
    }
  });
};
