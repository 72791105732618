import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { LoadingBars } from "../../../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReqModule } from "../../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { GroupByKey } from "../../../../helpers/CommonHelpers";
import moment from "moment";
import { AlertNofif } from "../../../layouts/alerts/AlertsUI";
import { ButtonPrimary } from "../../../layouts/buttons";

export function WCampaign() {
  const [campaigns, setCampaigns] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [dataset, setDataSet] = useState([]);
  const Token = AuthDecodeToken();
  const GET_NOTIF = () => {
    setCampaigns({ loading: true, data: [], message: "" });
    var keys = {
      AppId: Token.AppID,
      AppEnv: Token.AppEnv,
      Secretkey: Token.Secretkey,
    };
    const parameter = {
      header: keys,
      body: { target_data: "GET_NOTIF" },
      method: "post",
    };

    AxiosLibGlobalReqModule(
      parameter,
      process.env.REACT_APP_ESTES_API +
        process.env.REACT_APP_ESTES_API_PATH_REQUEST
    ).then((response) => {
      if (response.error) {
        setCampaigns({
          loading: false,
          data: [],
          message: "Your session has been expired. Please RE-SIGN IN again.",
        });
      } else if ("message" in response.response) {
        setCampaigns({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        var results = response.response.data;
        if (results) {
          var updateParam = results.map((v) => {
            var obj = v;
            obj.audit_created_fmt = moment(v.audit_created).format(
              "YYYY-MM-DD"
            );
            return obj;
          });
          updateParam.sort((a, b) =>
            a.audit_created > b.audit_created ? 1 : -1
          );
          setCampaigns({
            loading: false,
            data: response.response.data,
            message: "",
          });
          var groupByDate = GroupByKey(updateParam, "audit_created_fmt");
          if (Object.keys(groupByDate).length > 0) {
            var labels = [];
            var datas = [];
            Object.keys(groupByDate).map((k) => {
              labels.push(k);
              datas.push(Object.values(groupByDate[k]).length);
            });
            setDataSet({ labels, datas });
          }
        } else {
          setCampaigns({
            loading: false,
            data: response.response.data,
            message: "No record found",
          });
        }
      }
    });
  };

  useEffect(() => {
    GET_NOTIF();
  }, []);

  return (
    <div className="card card-xl-stretch mb-5 mb-xl-8">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">
            {Object.values(campaigns.data).length}
          </span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Total Campaigns Sent
          </span>
        </h3>
      </div>
      <div className="card-body py-3">
        {campaigns.loading ? (
          <LoadingBars />
        ) : campaigns.message ? (
          <>
            <AlertNofif messages={campaigns.message} title={"Error"} />
            <div className="text-center">
              <ButtonPrimary onClick={() => GET_NOTIF()}>
                <i className="bi bi-arrow-clockwise fs-2x"></i>
                Click here to try again
              </ButtonPrimary>
            </div>
          </>
        ) : Object.values(campaigns.data).length > 0 ? (
          <>
            <CampaignChart data={dataset} />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

const CampaignChart = ({ data }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
      },
    },
  };
  const labels = data.labels;
  const dataset = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Campaigns",
        data: data.datas,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return <Line options={options} data={dataset} />;
};

const CampaignTables = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
        <thead>
          <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
            <th width={100}>Date Publish</th>
            <th>Campaigns</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map((v, index) => (
            <tr key={index}>
              <td>{moment(v).format("DD MMM YYYY")}</td>
              <td>
                {Object.values(data[v]).length > 0
                  ? data[v].map((i, index) => (
                      <span
                        className={
                          "badge m-1 " +
                          (i.status === "FINISH"
                            ? "badge-light-primary"
                            : "badge-danger")
                        }
                        key={index}
                      >
                        {decodeURIComponent(i.subject)}
                      </span>
                    ))
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
