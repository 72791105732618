import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../layouts/datatables/MYDatatables';
import { OverlayTrigger } from 'react-bootstrap';
import { renderTooltip } from '../../layouts/popovers/PopOvers';
import moment from "moment"
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../layouts/modals/Modals';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { Link, useOutletContext } from 'react-router-dom';
import { ButtonTersier } from '../../layouts/buttons';
import downloadCSV from './DownloadCSV';
import { RenderAccessWidgets } from '../RenderAccessUser';

const FetchMailUser = ({ data, detail }) => {
  const totalshow = 5;
  const mails = JSON.parse(data);
  const dataToShow = mails.slice(0, totalshow);

  const Mailing = ({ mails }) => {
    return (
      <div className="mailing">
        <div className="fs-8">{decodeURIComponent(detail.description)}</div>
        <ol>
          {mails.map((v, index) => (
            <li key={index}>{v}</li>
          ))}
        </ol>
      </div>
    )
  }

  return (
    <div className='d-flexs justify-content-start align-items-center'>
      <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 w-20px pe-2 mb-2">
        {Object.values(dataToShow).length > 0 ? (
          dataToShow.map((v, index) => (
            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: v })} key={index}>
              <div className="symbol symbol-circle symbol-25px">
                <div className="symbol-label fs-8 fw-semibold bg-light text-primary border border-primary text-uppercase">
                  {v.charAt(0)}
                </div>
              </div>
            </OverlayTrigger>
          ))
        ) : "-"}
      </div >
      <div>
        <span className='badge badge-light text-muted cursor-pointer' onClick={() => openModal({ header: "Group " + decodeURIComponent(detail.name), message: <Mailing mails={mails} /> })}>
          {(Object.values(mails).length < totalshow) ? (
            <span>view <i className="bi bi-chevron-double-right fs-9"></i></span>
          ) : (
            <span>
              {Object.values(mails).length - totalshow} more
              <i className="bi bi-chevron-double-right fs-9"></i>
            </span>
          )}
        </span>
      </div>
    </div>
  )
}

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_12 = RenderAccessWidgets(context.widgets, 12);
  const widget_13 = RenderAccessWidgets(context.widgets, 13);

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    { name: "No", field: "recipient_id", sortable: false },
    { name: "Mail Group", field: "name", sortable: true },
    { name: "Created At", field: "createdAt", sortable: true },
    { name: "Actions", field: "recipient_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = listData;

    if (search) {
      computedData = computedData.filter(
        listData => {
          return Object.keys(listData).some(key =>
            listData[key].toString().toLowerCase().includes(search)
          );
        }
      );
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [listData, search, sorting]);

  const [removePost, setRemovePost] = useState({ loading: false, data: [], message: "" });
  const HandlerRemoveAccount = (recipient_id) => {

    var param = { recipient_id: recipient_id };
    const HandlerYes = () => {
      RemoveData(param);
    }

    openModal({ header: "Confirmation", message: <ConfirmRemove message={"Are you sure you want to delete this item?"} handler={HandlerYes} /> })

  }


  const RemoveData = (param) => {
    setRemovePost({ loading: true, data: [], message: "" })
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/recepients/delete"
    ).then((response) => {
      if (response.error) {
        setRemovePost({ loading: false, data: [], message: response.error })
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.status === 200) {
        openModal({
          message: <MessageSuccess message={"Successfully Removed"} />,
        });
        ReloadData();
      } else {
        setRemovePost({ loading: false, data: [], message: "Failed remove" })
        openModal({
          message: <MessageError message={"Failed remove"} />,
        });
      }
    });
  }

  const HandlerDownload = () => {
    let mailing_list = [];
    listData.map((item) => {
      const membership = JSON.parse(item.mail_users);
      membership.map(v => {
        const objmember = { email: v, group: decodeURIComponent(item.name) }
        mailing_list.push(objmember)
      })
    })

    if (mailing_list.length > 0) {
      downloadCSV(mailing_list);
    } else {
      alert("Failed Generate");
    }
  }

  return (
    <div className='table-responsive'>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex justify-content-end">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={() => ReloadData()}
          />

          <ButtonTersier
            className="btn-sm btn-icon ms-2"
            type="button"
            title="Download CSV"
            onClick={() => HandlerDownload()}
          >
            <i className="fa fa-download me-1"></i>
          </ButtonTersier>
        </div>
      </div>

      {removePost.message ? (
        <div className="p-5 bg-light-danger rounded border border-danger text-danger text-capitalize">
          {removePost.message}
        </div>
      ) : ""}

      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) =>
            setSorting({ field, order })
          }
        />
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <span className="text-gray-900 text-hover-primary fw-bolder me-1">
                      {decodeURIComponent(v.name)}
                    </span>
                    <i className="fa fa-circle-check text-primary fs-1x"></i>
                  </div>
                  {v.description && <span className="fs-8">{decodeURIComponent(v.description)}</span>}
                  <span className="d-block fw-bolder fs-8 text-muted">{moment(v.createdAt).format("DD MMM YYYY, hh:mm A")}</span>
                </td>
                <td>
                  <FetchMailUser data={v.mail_users} detail={v} />
                </td>
                <td>
                  <div className="btn-group">
                    {widget_12 === true && (
                      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Edit" })}>
                        <Link className="btn btn-sm btn-light btn-icon" to={"update/" + v.recipient_id} >
                          <i className="bi bi-pencil-square"></i>
                        </Link>
                      </OverlayTrigger>
                    )}
                    {widget_13 === true && (
                      <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Remove" })}>
                        <ButtonTersier className="btn-sm btn-icon" type="button" onClick={() => HandlerRemoveAccount(v.recipient_id)} >
                          <i className="bi bi-trash"></i>
                        </ButtonTersier>
                      </OverlayTrigger>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export { FetchMailUser }