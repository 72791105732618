import React, { useMemo, useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import { renderTooltip } from '../../../layouts/popovers/PopOvers';
import { openModal } from '../../../layouts/modals/Modals';
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../../layouts/datatables/MYDatatables';

export default function TableData({ data, ReloadData }) {
    const listData = data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const headers = [
        { name: "No", field: "recipient_id", sortable: false },
        { name: "Name", field: "name", sortable: true },
        { name: "Email", field: "email", sortable: true }
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toString().toLowerCase().includes(search)
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }

    }, [listData, search, sorting, currentPage]);

    return (
        <div id='google-groups'>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <ButtonItemRefresh
                    totalItems={totalItems}
                    onClick={() => ReloadData()}
                />
            </div>

            <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) =>
                            setSorting({ field, order })
                        }
                    />
                    <tbody>
                        {Object.values(ResultData).length > 0 ? (
                            ResultData.map((v, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><span className="d-block fw-bold">{v.name}</span>
                                        <span className="fs-8">{v.description}</span></td>
                                    <td>
                                        <span className="d-block fw-semibold">{v.email}</span>
                                        <span className="text-muted">Members:</span>
                                        <FetchMailUser data={v.members} />
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4}>No record found</td>
                            </tr>
                        )}
                    </tbody>
                    {totalItems > 0 ? (
                        <tfoot>
                            <tr>
                                <td colSpan={4}>
                                    <div className="footer">
                                        <span className="d-block mb-5">
                                            <i className="fas fa-poll"></i> Total item{" "}
                                            {totalItems > 0 ? totalItems : 0}
                                        </span>
                                        <PaginationComponent
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tfoot>
                    ) : (
                        ""
                    )}
                </table>
            </div>
        </div>
    )
}


const FetchMailUser = ({ data }) => {
    const totalshow = 10;
    const mails = data;
    const dataToShow = mails.slice(0, totalshow);

    const Mailing = ({ mails }) => {
        return (
            <ol>
                {mails.map((v, index) => (
                    <li key={index}>{v.email}</li>
                ))}
            </ol>
        )
    }

    return (
        <div className=''>
            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 w-20px pe-2 mb-2">
                {Object.values(dataToShow).length > 0 ? (
                    dataToShow.map((v, index) => (
                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: v.email })} key={index}>
                            <div className="symbol symbol-circle symbol-25px">
                                <div className="symbol-label fs-8 fw-semibold bg-light text-primary border border-primary text-uppercase">
                                    {v.email.charAt(0)}
                                </div>
                            </div>
                        </OverlayTrigger>
                    ))
                ) : "-"}
            </div >
            <span className='badge badge-light text-muted cursor-pointer' onClick={() => openModal({ header: "Mailing List ", message: <Mailing mails={mails} /> })}>
                {(Object.values(mails).length < totalshow) ? (
                    <span>view <i className="bi bi-chevron-double-right fs-9"></i></span>
                ) : (
                    <span>
                        {Object.values(mails).length - totalshow} more
                        <i className="bi bi-chevron-double-right fs-9"></i>
                    </span>
                )}
            </span>
        </div>
    )
}