import React, { useEffect, useRef, useState } from "react";
import { AxiosLibGlobalReq, AxiosLibBillApp } from "../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";
import { Link, Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import {
    MessageError,
    MessageSuccess,
    openModal,
} from "../../layouts/modals/Modals";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";
import Select from 'react-select';

const Token = AuthDecodeToken();

export function CouponForm() {
    const context = useOutletContext();
    const widget_create = RenderAccessWidgets(context.widgets, 35);
    const navigate = useNavigate()
    let params = useParams();
    const coupon_id = params.coupon_id;
    const [error, setError] = useState('');

    const editorRef = useRef(null);

    const objParam = {
        coupon_id: 0,
        event_id: "",
        total: "",
        coupon_code: "",
        discount: "",
        nominal: "",
        created_by: Token.identity.given_name,
    };
    const [post, setPost] = useState(objParam);
    const [temp, setTemp] = useState({ loading: false, data: [], message: "" });
    const [labelDiscount, setlabelDiscount] = useState('');
    const [typeDsc, setTypeDsc] = useState(0);

    const [eventList, setEventList] = useState({ loading: false, data: [], message: "" });
    const optionsEvent = eventList.data.map(item => ({
        value: item.event_id,
        label: item.event_id + ' - ' + decodeURIComponent(item.short_title)
    }));
    const [selectedOptionEvent, setSelectedOptionEvent] = useState();
    const handleEvent = (selected) => {
        if (selected) {
            const selectedCode = selected.value;  // This is the sp_code
            const selectedName = selected.label;
            setSelectedOptionEvent(selected);
            setPost({ ...post, event_id: selectedCode })
        } else {
            setSelectedOptionEvent(null);
        }

    };

    useEffect(() => {
        if (coupon_id) {
            GetDataByID(coupon_id, setTemp, setPost, setlabelDiscount, setSelectedOptionEvent);
        }
        GetEvent(setEventList);
    }, []);

    const [submitPost, setSubmitPost] = useState({
        loading: false,
        data: [],
        message: "",
    });



    const CreateCoupon = (param) => {
        setSubmitPost({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        console.log(parameter)
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/coupon/save"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                setSubmitPost({
                    loading: false,
                    data: response.data,
                    message: "Successfully Saved",
                });
                openModal({
                    message: <MessageSuccess message={"Successfully Saved"} />,
                });
                setTimeout(() => {
                    openModal({ open: false });
                    navigate("/coupons");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" });
            }
        });
    };

    const UpdateCoupon = (param) => {
        setSubmitPost({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        console.log(parameter)
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/coupon/update"
        ).then((response) => {
            if (response.error) {
                setSubmitPost({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                setSubmitPost({
                    loading: false,
                    data: response.data,
                    message: "Successfully Updated",
                });
                openModal({
                    message: <MessageSuccess message={"Successfully Updated"} />,
                });
                setTimeout(() => {
                    openModal({ open: false });
                    navigate("/coupons");
                }, 1000);
            } else {
                setSubmitPost({ loading: false, data: [], message: "Failed saved" });
            }
        });
    };

    const SubmitForm = (e) => {
        e.preventDefault();
        if(coupon_id){
            if (post.event_id && (post.discount || post.nominal)) {
                const param = RemoveEmptyValue(post);
                if(post.discount==0){
                param.discount = 0;
                }if(post.nominal==0){
                    param.nominal = 0;
                }
                param.coupon_id = param.coupon_id
                UpdateCoupon(param);
            } else {
                openModal({
                    message: (
                        <MessageError message={"Please fill out the form with correctly"} />
                    ),
                });
            }
        }else{
            if (post.event_id && post.total && (post.discount || post.nominal)) {
                const param = RemoveEmptyValue(post);
                    CreateCoupon(param);            
            } else {
                openModal({
                    message: (
                        <MessageError message={"Please fill out the form with correctly"} />
                    ),
                });
            }
        }
        
    };


    const generateCouponCode = () => {
        const prefix = 'SGU-';
        const timestamp = Date.now();
        const base = timestamp.toString(36);
        const random = Math.random().toString(36).substr(2, 4);
        const newCode = prefix + (base + random).toUpperCase();
        setPost({ ...post, coupon_code: newCode })
    };

    const handleDiscount = (e) => {
        const { value } = e.target;
        const onlyNumbers = value.replace(/[^0-9]/g, ''); // remove non-numeric characters
        const intValue = parseInt(onlyNumbers, 10);
        if (intValue >= 101) {
            e.target.value = '100'; // set the value to 100 if it's 101 or more
        } else {
            e.target.value = onlyNumbers; // update the input value
        }
        //setlabelDiscount(onlyNumbers);
        //const discount = parseInt(onlyNumbers) / 100;
        setPost({ ...post, discount: onlyNumbers, nominal: 0 })
    };

    const handleNominal = (e) => {
        const { value } = e.target;
        const onlyNumbers = value.replace(/[^0-9]/g, ''); // remove non-numeric characters
        e.target.value = onlyNumbers; // update the input value
        setPost(postOld => ({ ...postOld, discount: 0, nominal: onlyNumbers }))
    };
    const handleTotal = (e) => {
        const { value } = e.target;
        const onlyNumbers = value.replace(/[^0-9]/g, ''); // remove non-numeric characters
        e.target.value = onlyNumbers; // update the input value
        setPost(postOld => ({ ...postOld, total: parseInt(onlyNumbers) }))
    };

    return (
        (widget_create === true ? (
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Form Coupon</span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            Please fill up the form with correctly
                        </span>
                    </h3>
                </div>
                <div className="card-body">
                    <form
                        method="post"
                        autoComplete="off"
                        id="form-post"
                        onSubmit={(e) => SubmitForm(e)}
                    >

                        <div className="mb-5">
                            <label className="required fw-bolder">Event</label>
                            <div className="input-group input-group-solid">
                                <Select
                                    value={selectedOptionEvent}
                                    className="form-select form-select-sm"
                                    onChange={handleEvent}
                                    options={optionsEvent}
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder="Select an Event..."
                                />
                            </div>
                        </div>
                        {(!coupon_id) &&
                            <div className="mb-5">
                                <label className="required fw-bolder">How many coupon create</label>
                                <div className="input-group ">

                                    <input
                                        type="text"

                                        name="Discount"
                                        className="form-control"
                                        value={post.total}
                                        onChange={handleTotal}

                                    />

                                </div>
                            </div>
                        }
                        {(coupon_id) &&
                            <div className="mb-5">
                                <label className="required fw-bolder">Coupon Code</label>
                                <div className="input-group input-group-solid">
                                    <input
                                        type="text"
                                        required
                                        name="Coupon code"
                                        className="form-control form-control-solid"
                                        value={post.coupon_code}
                                        readOnly="true"
                                    />
                                    {(!coupon_id) &&
                                        <button
                                            className="btn btn-lg btn-info fw-bold btn-submit"
                                            type="button"
                                            disabled={submitPost.loading}
                                            onClick={() => generateCouponCode()}
                                        >
                                            Generate
                                        </button>
                                    }
                                </div>
                            </div>
                        }
                        {(!coupon_id) &&
                            <div className="mb-5">
                                <label className="required fw-bolder">Type</label>
                                <select
                                    name="status"
                                    id=""
                                    className="form-select form-select-sm"
                                    required
                                    value={typeDsc}
                                    onChange={(e) =>
                                        setTypeDsc(parseInt(e.target.value))
                                    }
                                >
                                    <option value="0">Select Type</option>
                                    <option value="1">Percentage Discount</option>
                                    <option value="2">Nominal Discount</option>
                                </select>
                            </div>
                        }
                        {(typeDsc === 1 || (coupon_id)) &&
                            <div className="mb-5">
                                <label className="required fw-bolder">Percentage Discount</label>
                                <div className="input-group ">
                                    <span className="input-group-text" >%</span>
                                    <input
                                        type="text"

                                        name="Discount"
                                        className="form-control"
                                        value={post.discount}
                                        onChange={handleDiscount}

                                    />

                                </div>
                            </div>
                        }
                        {(typeDsc === 2 || (coupon_id)) &&
                            <div className="mb-5">
                                <label className="required fw-bolder">Nominal Discount</label>
                                <div className="input-group ">
                                    <span className="input-group-text" >Rp</span>
                                    <input
                                        type="text"

                                        name="Nominal"
                                        className="form-control"
                                        value={post.nominal}
                                        onChange={handleNominal}

                                    />

                                </div>
                            </div>
                        }

                        {submitPost.message ? (
                            Object.values(submitPost.data).length > 0 ? (
                                <div className="bg-light-info p-3 rounded border border-info text-info text-capitalize mb-5">
                                    {submitPost.message}
                                </div>
                            ) : <div className="bg-light-danger p-3 rounded border border-danger text-danger text-capitalize mb-5">
                                {submitPost.message}
                            </div>
                        ) : (
                            ""
                        )}

                        <div className="text-end mt-10 d-flex justify-content-between">
                            <Link
                                className="btn btn-lg btn-light fw-bold me-3"
                                to={"/coupons"}
                            >
                                Cancel
                            </Link>
                            <button
                                className="btn btn-lg btn-primary fw-bold btn-submit"
                                type="submit"
                                disabled={submitPost.loading}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        ) : <AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />)
    );
}

const GetDataByID = (coupon_id, setTemp, setPost, setlabelDiscount, setSelectedOptionEvent) => {
    setTemp({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "get",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/coupon/" + coupon_id
    ).then((response) => {
        if (response.error) {
            setTemp({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            const result = response.data;
            console.log(result);
            setTemp({
                loading: false,
                data: result,
                message: "Successfully Saved",
            });
            setPost({
                coupon_id: result.coupon_id,
                coupon_code: result.coupon_code,
                event_id: result.Events[0].event_id,
                discount: result.discount,
                nominal: result.nominal,
                created_by: Token.identity.given_name,
            })
            setSelectedOptionEvent({ value: result.Events[0].event_id, label: decodeURIComponent(result.Events[0].short_title) });
  
            setlabelDiscount(result.discount * 100);
            //console.log(response.data);
        } else {
            setTemp({ loading: false, data: [], message: "Failed saved" });
        }
    });
};



const GetEvent = (setEventList) => {
    setEventList({ loading: true, data: [], message: "" });
    const parameter = {
        header: {},
        method: "post",
    };
    AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/event/get_master"
    ).then((response) => {
        if (response.error) {
            setEventList({ loading: false, data: [], message: response.error });
        } else if (response.data && response.status === 200) {
            const result = response.data;
            setEventList({
                loading: false,
                data: result,
                message: "Successfully",
            });

        } else {
            setEventList({ loading: false, data: [], message: "Failed saved" });
        }
    });
};