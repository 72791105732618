import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { openModal } from "../../../layouts/modals/Modals";
import { renderTooltip } from "../../../layouts/popovers/PopOvers";

const FetchMailUser = ({ data, detail }) => {
    const totalshow = 5;
    const mails = JSON.parse(data);
    const dataToShow = mails.slice(0, totalshow);

    const Mailing = ({ mails }) => {
        return (
            <div className="mailing">
                {detail && detail.description && <div className="fs-8">{decodeURIComponent(detail.description)}</div> }
                <ol>
                    {mails.map((v, index) => (
                        <li key={index}>{v.email}</li>
                    ))}
                </ol>
            </div>
        );
    };

    return (
        <div className="d-flexs justify-content-start align-items-center">
            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 w-20px pe-2 mb-2">
                {Object.values(dataToShow).length > 0
                    ? dataToShow.map((v, index) => (
                        <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            placement="top"
                            overlay={renderTooltip({ title: v.email })}
                            key={index}
                        >
                            <div className="symbol symbol-circle symbol-25px">
                                <div className="symbol-label fs-8 fw-semibold bg-light text-primary border border-primary text-uppercase">
                                    {v.email.charAt(0)}
                                </div>
                            </div>
                        </OverlayTrigger>
                    ))
                    : "-"}
            </div>
            <div>
                <span
                    className="badge badge-light text-muted cursor-pointer"
                    onClick={() =>
                        openModal({
                            header: detail && detail.name && decodeURIComponent(detail.name),
                            message: <Mailing mails={mails} />,
                        })
                    }
                >
                    {Object.values(mails).length < totalshow ? (
                        <span>
                            view <i className="bi bi-chevron-double-right fs-9"></i>
                        </span>
                    ) : (
                        <span>
                            {Object.values(mails).length - totalshow} more
                            <i className="bi bi-chevron-double-right fs-9"></i>
                        </span>
                    )}
                </span>
            </div>
        </div>
    );
};

export {FetchMailUser}