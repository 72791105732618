// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TimeSelector.css */
.time-selector-container {
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  .time-selector-label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .time-selector {
    width: 200px;
    margin-bottom: 20px;
  }
  
  
  div[class*="-picker__wrapper"] {
    background: white;
    border: 0;
    font-size: 1em;
  }
.input-time{
    width: 40% !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/modules/events/components/Time.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,YAAY;IACZ,8BAA8B;EAChC;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;;EAGA;IACE,iBAAiB;IACjB,SAAS;IACT,cAAc;EAChB;AACF;IACI,qBAAqB;AACzB","sourcesContent":["/* TimeSelector.css */\n.time-selector-container {\n    margin: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .time-selector-label {\n    display: block;\n    margin-bottom: 8px;\n    font-size: 16px;\n    font-weight: bold;\n  }\n  \n  .time-selector {\n    width: 200px;\n    margin-bottom: 20px;\n  }\n  \n  \n  div[class*=\"-picker__wrapper\"] {\n    background: white;\n    border: 0;\n    font-size: 1em;\n  }\n.input-time{\n    width: 40% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
