import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../layouts/datatables/MYDatatables";
import moment from "moment";
import {
  ConfirmRemove,
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { Link, useOutletContext } from "react-router-dom";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { LoadingBars } from "../../layouts/loadbars/LoadingBars";
import { AxiosLibGlobalReq } from "../../../helpers/AxiosLibs";
import { ButtonTersier } from "../../layouts/buttons";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../layouts/popovers/PopOvers";
import { formatRupiah } from "./components/FormAtributes";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { atom } from 'jotai'
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { counterAtom } from '../../../helpers/Atoms';

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const Token = AuthDecodeToken();
  const panjang = Object.values(data).length;
  const widget_delete = RenderAccessWidgets(context.widgets, 32);

  const [colorItems, setColorItems] = useState([]);

  const generatePastelColor = () => {
    // Generate a pastel color by using higher base values
    const r = Math.floor(Math.random() * 55 + 200); // 200-255
    const g = Math.floor(Math.random() * 55 + 200); // 200-255
    const b = Math.floor(Math.random() * 55 + 200); // 200-255

    // Convert to hex
    return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
  };

  function generatePastelGradient() {
    // Generate a pastel color
    const pastelColor = () => {
      const hue = Math.floor(Math.random() * 360);
      const saturation = Math.floor(Math.random() * 25) + 70; // 70-95%
      const lightness = Math.floor(Math.random() * 15) + 75; // 75-90%
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    // Generate random angle between 0 and 360 degrees
    const angle = Math.floor(Math.random() * 361);

    // Generate two pastel colors
    const color1 = pastelColor();
    const color2 = pastelColor();

    // Return the style object
    return {
      background: `linear-gradient(${angle}deg, ${color1} 0%, ${color2} 100%)`,
    };
  }

  const momentTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const timeMoment = moment({ hours, minutes, seconds });
    const formattedTime = timeMoment.format("h:mm a");
    return formattedTime;
  };

  const listData = data;
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;
  const headers = [
    { name: "No", field: "event_id", sortable: false },
    { name: "Title", field: "short_title", sortable: true },
    { name: "Description", field: "description", sortable: true },
    { name: "Event Start", field: "event_start", sortable: true },
    { name: "Location", field: "location", sortable: true },
    { name: "Actions", field: "event_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(listData).length > 0) {
      computedData = listData.map((v, index) => {
        var obj = v;
        //obj.status_name = v.status === 1 ? "Draft":"Sended"
        obj.num = index + 1;
        return obj;
      });
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.values(listData).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          if (typeof value === "object") {
            return Object.values(value).some((subValue) =>
              String(subValue).toLowerCase().includes(search.toLowerCase())
            );
          }
          return String(value).toLowerCase().includes(search.toLowerCase());
        });
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [listData, search, sorting, currentPage]);

  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const statusData = (param) => {
    setRemove({ loading: true, data: [], message: "" });
    openModal({
      message:
        "Please wait while the event is being updated. " + <LoadingBars />,
    });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/event/status"
    ).then((response) => {
      if (response.error) {
        setRemove({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setRemove({
          loading: false,
          data: response.data,
          message: "Successfully Change",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Change"} />,
        });
        ReloadData();
      } else {
        setRemove({ loading: false, data: [], message: "Failed Change" });
        openModal({
          message: <MessageError message={"Failed Change"} />,
        });
      }
    });
  };


  const imageData = (param) => {
    setRemove({ loading: true, data: [], message: "" });
    openModal({
      message:
        "Please wait while the event is being updated. " + <LoadingBars />,
    });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/event/gambar"
    ).then((response) => {
      if (response.error) {
        setRemove({ loading: false, data: [], message: response.error });
        openModal({
          message: <MessageError message={response.error} />,
        });
      } else if (response.data && response.status === 200) {
        setRemove({
          loading: false,
          data: response.data,
          message: "Successfully Change",
        });
        openModal({
          message: <MessageSuccess message={"Successfully Change"} />,
        });
        ReloadData();
      } else {
        setRemove({ loading: false, data: [], message: "Failed Change" });
        openModal({
          message: <MessageError message={"Failed Change"} />,
        });
      }
    });
  };

  const items_fee = (items) => {
    return Object.values(items).length > 0
      ? items.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-700 fw-bold">
            <span className="ms-n1">
              {v.label ? v.label : v.code_name} : {formatRupiah(v.price)}
            </span>
          </span>
        </div>
      ))
      : "";
  };
  const firstLetter = (nm) => {
    return nm[0];
  };

  const contact_event = (contacts) => {
    return Object.values(contacts).length > 0
      ? contacts.map((v, index) => (
        <div
          key={index}
          className="symbol symbol-35px symbol-circle min-w-80px w-80 py-2 px-4 me-6 mb-3"
          data-bs-toggle="tooltip"
          title="Susan Redwood"
        >
          <span className="symbol-label bg-primary text-inverse-primary fw-bold">
            {firstLetter(v.fullname)}
          </span>
          <div className="fw-bold text-gray-700">{v.fullname}</div>
          <div className="fw-bold text-gray-700">{v.dept_name}</div>
          <div className="fw-bold text-gray-700">{v.email}</div>
          <div className="fw-bold text-gray-700">{v.phone}</div>
        </div>
      ))
      : "";
  };

  const coupons = (coupon) => {
    return Object.values(coupon).length > 0
      ? coupon.map((v, index) => (
        <div key={index}>
          <span className="fs-6 text-gray-700 fw-bold">
            <span className="ms-n1 p-1">
              {v.coupon_code} : {(v.discount) ? (v.discount + '%') : (v.nominal) ? (formatRupiah(v.nominal)) : ''}
            </span>
          </span>
        </div>
      ))
      : "";
  };

  const updateStatus = (event_id, is_active) => {
    let isactive = is_active === 1 ? parseInt(0) : parseInt(1);
    let edited_by = Token.identity.given_name;
    const param = {
      event_id: event_id,
      is_active: isactive,
      edited_by: edited_by,
    };
    const HandlerYes = () => {
      statusData(param);
    };

    openModal({
      header: "Confirmation",
      message: (
        <ConfirmRemove
          message={"Are you sure you want to change this event?"}
          handler={HandlerYes}
        />
      ),
    });
  };

  const deleteImage = (event_id) => {
    let edited_by = Token.identity.given_name;
    const param = {
      event_id: event_id,
      logo: '',
      edited_by: edited_by,
    };
    const HandlerYes = () => {
      imageData(param);
    };

    openModal({
      header: "Confirmation",
      message: (
        <ConfirmRemove
          message={"Are you sure you want to change this event?"}
          handler={HandlerYes}
        />
      ),
    });
  };
  
  const [, setCount] = useAtom(counterAtom);
  const navigate = useNavigate();
  const handleToEvent = (event_id) => {
    setCount(event_id);
    navigate('/coupons');
  };
  const handleToParticipant = (event_id) => {
    setCount(event_id);
    navigate('/participants');
  };
  const handleShow = (event_id) => {
    const alamat = createRegistrationLink(event_id);
    const baseUrl = window.location.origin;
    const url = `${baseUrl}/${alamat}`;
    navigator.clipboard.writeText(url);
    openModal({
      message: <MessageSuccess message={url} />,
    });
  };
  const createRegistrationLink = (eventId) => {
    const payload = { event_id: eventId };
    //const token = sign(payload, packageJSON.system_param.PublicKey);
    const token = btoa(JSON.stringify(payload)).replace(/\//g, '_').replace(/\+/g, '-');
    return `registration/${token}`;
  };

  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>

      <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>
                  <div className="card card-flush h-xl-100">
                    <div
                      className="card-body py-2"
                      style={generatePastelGradient()}
                    >
                      <div className="row gx-9 h-100">
                        <div className="col-12 col-sm-12 mb-10 mb-sm-0">
                          <div className="d-flex flex-column h-100">
                            <div className="mb-1">

                              <div className="flex-shrink-0 me-5   rounded p-2">
                                <span
                                  style={{
                                    background:
                                      "linear-gradient(to right, #0e1c26 0%, #0ab6f8 100%)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    display: "inline-block",
                                  }}
                                >
                                  <span className="tfs-7 fw-bold me-2 d-block lh-1 pb-1">
                                    EVENT ID : {v.event_id}
                                  </span>
                                  <span className=" fs-6 fw-bold">
                                    {decodeURIComponent(v.short_title)}
                                  </span>
                                  <span className="fw-bold  fs-7 mb-1 d-block">
                                    {decodeURIComponent(v.full_title)}
                                  </span>
                                </span>
                              </div>
                              <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement="top"
                                overlay={renderTooltip({
                                  title:
                                    v.is_active === 1
                                      ? "Click to unpublish"
                                      : "Click to publish",
                                })}
                              >
                                <span
                                  onClick={() =>
                                    updateStatus(v.event_id, v.is_active)
                                  }
                                  className={`badge m-2 w-auto ${v.is_active === 1
                                    ? "badge-light-success"
                                    : "badge-light-secondary"
                                    } flex-shrink-0 align-self-center py-3 px-3 fs-7 position-absolute top-0 end-0 btn`}
                                >
                                  {v.is_active === 1
                                    ? "Published"
                                    : v.is_active === 3 ? "Archived" : v.is_active === 5 ? "Complete" : "Draft"}
                                </span>
                              </OverlayTrigger>

                              <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px symbol-circle me-3">
                                    <span className="symbol-label bg-warning">
                                      <i className="ki-duotone ki-route fs-1 text-white">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                      </i>
                                    </span>
                                  </div>
                                  <div className="m-0">
                                    <span className="fw-bold text-gray-700 d-block fs-8">
                                      Location
                                    </span>
                                    <span className="fw-bold text-gray-800 fs-7">
                                      {v.info_location}
                                    </span>
                                    <br />
                                    <OverlayTrigger
                                      delay={{ show: 250, hide: 400 }}
                                      placement="top"
                                      overlay={renderTooltip({
                                        title: "Open on google maps",
                                      })}
                                    >
                                      <div className="d-inline-block">
                                        <span
                                          className="fw-bold text-gray-800 fs-7 cursor-pointer d-flex"
                                          style={{ gap: 2 }}
                                          onClick={() =>
                                            window.open(v.info1, "_blank")
                                          }
                                        >
                                          <i class="fas fa-map-marker-alt text-black mt-1"></i>
                                          Maps
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mb-2">
                              <span className="text-gray-700 fs-7 fw-bold me-2 d-block lh-1 pb-1">
                                Description :
                              </span>
                              <span className="fw-semibold text-gray-800 fs-6 mb-2 d-block">
                                <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(v.description) }} />
                              </span>
                              {/* <span className="fw-semibold text-gray-800 fs-6 mb-2 d-block">
                                  {v.info1
                                    ? decodeURIComponent(v.info1)
                                    : v.info1}
                                </span>
                                <span className="fw-semibold text-gray-800 fs-6 mb-2 d-block">
                                  {v.info2
                                    ? decodeURIComponent(v.info2)
                                    : v.info2}
                                </span>
                                <span className="fw-semibold text-gray-800 fs-6 mb-2 d-block">
                                  {v.info3
                                    ? decodeURIComponent(v.info3)
                                    : v.info3}
                                </span> */}
                            </div>
                            <div className="d-flex justify-content-between flex-wrap">
                              <div className="border border-gray-600 border-dashed rounded min-w-90px w-90 py-2 px-1 me-6 mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px symbol-circle me-3">
                                    <span className="symbol-label bg-info">
                                      <i className="ki-duotone ki-calendar-tick fs-1 text-white">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                      </i>
                                    </span>
                                  </div>
                                  <div className="m-0">
                                    <span className="fs-6 text-gray-800 fw-bold">
                                      {moment(v.event_start).format(
                                        "DD MMM YYYY"
                                      )}
                                      , {momentTime(v.info_time_start)}
                                    </span>
                                    <div className="fw-semibold text-gray-700">
                                      Event Start
                                    </div>
                                    <span className="fs-6 text-gray-800 fw-bold">
                                      {moment(v.event_end).format("DD MMM YYYY")},{" "}
                                      {momentTime(v.info_time_end)}
                                    </span>
                                    <div className="fw-semibold text-gray-700">
                                      Event End
                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className="border border-gray-600 border-dashed rounded min-w-90px w-90 py-2 px-1 me-6 mb-3 ">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px symbol-circle me-3">
                                    <span className="symbol-label bg-info">
                                      <i className="ki-duotone ki-calendar-tick fs-1 text-white">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                      </i>
                                    </span>
                                  </div>
                                  <div className="m-0">
                                    <span className="fs-6 text-gray-800 fw-bold">
                                      {moment(v.reg_start).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </span>
                                    <div className="fw-semibold text-gray-700">
                                      Registration Open
                                    </div>
                                    <span className="fs-6 text-gray-800 fw-bold">
                                      {moment(v.reg_end).format(
                                        "DD MMM YYYY, hh:mm A"
                                      )}
                                    </span>
                                    <div className="fw-semibold text-gray-700">
                                      Registration Close
                                    </div>
                                  </div>
                                </div>

                              </div>


                              <div className="min-w-90px w-90 py-6 px-1 mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px symbol-circle me-3">
                                    <span className="symbol-label bg-info">
                                      <i className="ki-duotone ki-bill fs-1 text-white">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                        <span className="path4"></span>
                                        <span className="path5"></span>
                                        <span className="path6"></span>
                                      </i>
                                    </span>
                                  </div>
                                  <div className="m-0">
                                    {items_fee(v.Items)}
                                    <div className="fw-semibold text-gray-700">
                                      Event Fees
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="d-flex justify-content-center flex-wrap">
                            {v.logo && (
                            <div className="d-flex align-items-center">
                            
                                <img
                                  src={v.logo} // The Base64 string is used directly in the `src` attribute
                                  alt="Event Logo"
                                  style={{ maxWidth: '500px', maxHeight: '500px' }} // Restrict image size
                                />
                                  {(v.is_active !== 1) &&           
                              <button className="btn btn-sm btn-danger fs-7 mx-2" type="button" onClick={() => deleteImage(v.event_id)}>
                                      <span className="text-white fw-bold p-2">
                                        Delete Image
                                      </span>
                                      <i class="bi bi-trash"></i>
                                    </button>
                                     }
                              </div>
                               )}
                            </div>

                            <div className="d-flex flex-stack mt-auto bd-highlight">
                              <div className="symbol-group symbol-hover flex-nowrap">
                                {contact_event(v.Contacts)}
                              </div>

                              <div
                                className=" rounded min-w-30px w-30 py-2 px-4 mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="btn-group">
                                    <button className="btn btn-sm btn-warning fs-7 mx-2" type="button" onClick={() => handleToEvent(v.event_id)}>
                                      <span className="text-white fw-bold p-2">
                                        Coupons
                                      </span>
                                      <i class="bi bi-file-earmark-binary"></i>
                                    </button>
                                    <button className="btn btn-sm btn-primary fs-7 mx-2" type="button" onClick={() => handleToParticipant(v.event_id)}>
                                      <span className="text-white fw-bold p-2">
                                        Participants
                                      </span>
                                      <i class="bi bi-file-earmark-person"></i>
                                    </button>
                                    <button className="btn btn-sm btn-secondary fs-7 mx-2" type="button" onClick={() => handleShow(v.event_id)}>
                                      <span className="text-black fw-bold p-2">
                                        Get Url
                                      </span>
                                      <i class="bi bi-browser-edge"></i>
                                    </button>
                                  </div>

                                </div >
                              </div>

                              {(v.is_active !== 1) &&
                                <div
                                  className=" rounded min-w-30px w-30 py-2 px-4 mb-3">
                                  <div className="d-flex align-items-center">
                                    <Link
                                      to={"update/" + v.event_id}
                                    >
                                      <div className="btn-group">
                                        <button className="btn btn-sm btn-light fs-7" type="button">
                                          <span className="text-black fw-bold p-2">
                                            Edit
                                          </span>
                                          <i className="bi bi-pencil-square"></i>
                                        </button>

                                      </div>
                                    </Link>
                                    <div className="m-0">
                                      {/*coupons(v.Coupons)*/}
                                    </div>
                                  </div >
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  );
}
