import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../layouts/datatables/MYDatatables';
import { OverlayTrigger } from 'react-bootstrap';
import moment from "moment"
import { renderTooltip } from '../../../layouts/popovers/PopOvers';
import { AxiosLibGlobalReq } from '../../../../helpers/AxiosLibs';
import { ButtonTersier } from '../../../layouts/buttons';
import { useOutletContext } from 'react-router-dom';
import { RenderAccessWidgets } from '../../RenderAccessUser';

export default function TableData({ data, ReloadData }) {
    const context = useOutletContext();
    const widget_15 = RenderAccessWidgets(context.widgets, 15);
    const widget_16 = RenderAccessWidgets(context.widgets, 16);
    const listData = data;
    /*==DATATABELS CONFIG==*/
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const headers = [
        { name: "No", field: "user_id", sortable: false },
        { name: "User", field: "email", sortable: true },
        { name: "Role", field: "rolename", sortable: true },
        { name: "Actions", field: "user_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        if (search) {
            computedData = computedData.filter(
                listData => {
                    return Object.keys(listData).some(key =>
                        listData[key].toString().toLowerCase().includes(search)
                    );
                }
            );
        }
        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [listData, search, sorting]);

    const [removePost, setRemovePost] = useState({ loading: false, data: [], message: "" });
    const HandlerRemoveAccount = (user_id) => {
        if (window.confirm("Are you sure ?")) {
            var param = { user_id: user_id };
            RemoveUser(param);
        }

    }

    const RemoveUser = (param) => {
        setRemovePost({ loading: true, data: [], message: "" })
        const parameter = {
            header: {},
            body: param,
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/user/delete"
        ).then((response) => {
            if (response.error) {
                setRemovePost({ loading: false, data: [], message: response.error })
            } else if (response.status === 200) {
                alert(response.message);
                //setRemovePost({loading:false, data:response.data, message:"successfully saved"})
                ReloadData();
            } else {
                setRemovePost({ loading: false, data: [], message: "Failed saved" })
            }
        });
    }

    return (
        <div className="table-responsive">
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <ButtonItemRefresh
                    totalItems={totalItems}
                    onClick={() => ReloadData()}
                />
            </div>

            {removePost.message ? (
                <div className="p-5 bg-light-danger rounded border border-danger text-danger text-capitalize">
                    {removePost.message}
                </div>
            ) : ""}

            <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                <HeaderDatatables
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {Object.values(ResultData).length > 0 ? (
                        ResultData.map((v, index) => (
                            <tr key={index}>
                                <td width={"5%"}>{index+1}</td>
                                <td>
                                    <div className="ms-2">
                                        <span className="d-block fw-bolder fs-6">{v.email}</span>
                                        <span className="text-muted fs-8">
                                            {v.employeeid}
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <span className={"badge " + (v.rolename === "Super Admin" ? "badge-light-success" : "badge-light-primary")}>{v.rolename}</span>
                                    <span className="d-block text-muted fs-8">{moment(v.createdAt).format("DD MMM YYYY, hh:mm A")}</span>
                                </td>
                                <td>
                                    <div className="btn-group">
                                        {widget_15 === true && (
                                            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Update Account" })}>
                                                <ButtonTersier
                                                    className="btn-sm btn-icon"
                                                    type="button"
                                                >
                                                    <i className="bi bi-pencil-square"></i>
                                                </ButtonTersier>
                                            </OverlayTrigger>
                                        )}
                                        {widget_16 === true && (
                                            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Remove Account" })}>
                                                <ButtonTersier
                                                    className="btn-sm btn-icon"
                                                    type="button"
                                                    onClick={() => HandlerRemoveAccount(v.user_id)}
                                                >
                                                    <i className="bi bi-trash"></i>
                                                </ButtonTersier>
                                            </OverlayTrigger>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={6}>No record found</td>
                        </tr>
                    )}
                </tbody>
            </table>

        </div>
    )
}
