import React, { useEffect, useRef, useState } from "react";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { Link, Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
//import { Editor } from "@tinymce/tinymce-react";
import {
    MessageError,
    MessageSuccess,
    openModal,
} from "../../../layouts/modals/Modals";
import { ButtonTersier } from '../../../layouts/buttons';
import { LookUpCoupon } from "./LookUpCoupon";
import { formatRupiah } from "./FormAtributes";

const Token = AuthDecodeToken();

export function FormEventCoupon(props) {
    const context = useOutletContext();
    const navigate = useNavigate()
    //let params = useParams();
    const coupon_id = props.id;
    let postCoupon = props.postCoupon;
    let setPostCoupon= props.setPostCoupon;
    let data = props.data;

    const Handleropen = () => {
        return openModal({ header: "Coupon", size: "md", message: <LookUpCoupon postCoupon={postCoupon} setPostCoupon={setPostCoupon} data={data} /> });
    }

    return (
        <div className="card">
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-5 mb-0">Coupon List</span>
                </h3>
                <div className="card-toolbar">
                <button className="btn btn-light-info fw-bold fs-6 " onClick={() => Handleropen()} ><i className="fas fa-envelope-open-text fs-6 me-2"></i> Add Coupon</button>
            
                </div>
                </div>
            <div className="card-body">
                <div className="mb-0">

                    <div className='table-responsive'>
                        {postCoupon.length === 0 ? (
                            <p>No coupon added, please select coupon first!</p>
                        ) : (
                            <table className="table table-sm table-striped align-middle table-row-dashed fs-8 gy-1">
                                <thead>
                                    <tr className="text-start text-gray-500 fw-bold fs-8 text-uppercase gs-0">
                                        <th>#</th>
                                        <th>Coupon Code</th>
                                        <th>Discount</th>
                                        <th>Nominal</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="fw-semibold text-gray-600">
                                    {postCoupon.map((item, index) => (
                                        <tr key={item.coupon_id}>
                                            <td>{index + 1}</td>
                                            <td> 
                                                <span className="fw-bold d-block ">{item.coupon_code}</span>
                                            </td>
                                            <td>
                                            <span className="fw-bold d-block ">{(item.discount)?item.discount+'%':''}</span></td>
                                            <td>
                                            <span className="fw-bold d-block ">{(item.nominal) && formatRupiah(item.nominal)}</span></td>
                                            <td>
                                                <ButtonTersier className="btn-sm btn-icon text-hover-danger" type="button" onClick={() => setPostCoupon(postCoupon.filter((_, i) => i !== index))} title="Remove">
                                                    <i className="bi bi-trash"></i>
                                                </ButtonTersier>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}



