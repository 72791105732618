import React, { useEffect, useRef, useState } from 'react'
import PackageJSON from "../../../../../package.json";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { AuthDecodeToken } from '../../authentications/reducers/AuthRedux';
import { AxiosLibGlobalReqModule } from '../../../helpers/AxiosLibs';
import { MessageError, openModal } from '../modals/Modals';
import { AsideMenuItem, AsideMenuItemWithSub } from './AsideMenuItems';
import LogoImg from "../../../../_metronic/media/logo-sgu-white.png";

export function Aside({ menu }) {
    const scrollRef = useRef(null);
    const { pathname } = useLocation()
    const navigate = useNavigate();
    var datetime = new Date();
    var year = datetime.getFullYear();
    
    let current_pathArr = pathname.split(/\//g);
    let current_path = current_pathArr[1] && current_pathArr[1].replace(/[^\w\s]/gi, ' ');
    
    const menu_public = menu.filter(item => item.type !== 1);
    const menu_private = menu.filter(item => item.type === 1);

    const AsideMenu = [
        { id: 1, name: "Dashboard", goto: "/dashboard", icon: "bi-grid" },
        { id: 2, name: "Campaigns", goto: "/campaigns", icon: "bi-envelope-plus" },
        { id: 3, name: "Templates", goto: "/templates", icon: "bi-file-code" },
        { id: 4, name: "Recepients", goto: "/recepients", icon: "bi-people" },
    ]

    const AsideMenuConfig = [
        { id: 1, name: "User Manages", goto: "/user-manages", icon: "bi-person-gear" },
        // { id: 2, name: "Setting Up", goto: "/setting-up", icon: "bi-gear" }
    ]

    const styles = {
        bordertop: { borderTop: "1px dashed #1F212A" }
    }

    const Token = AuthDecodeToken();
    const current_given_name = Token.identity ? Token.identity.given_name : "-";
    const RoleID = Token.RoleID ? Token.RoleID : 0;

    const [profile, setProfile] = useState({ loading: false, data: [], message: "" });
    const GetProfile = () => {
        setProfile({ loading: true, data: [], message: "" })
        var keys = {
            AppId: Token.AppID,
            AppEnv: Token.AppEnv,
            Secretkey: Token.Secretkey,
        };
        const parameter = {
            header: keys,
            body: { target_data: "GET_PROFILE" },
            method: "post",
        };

        AxiosLibGlobalReqModule(parameter, process.env.REACT_APP_ESTES_API + process.env.REACT_APP_ESTES_API_PATH_REQUEST).then((response) => {
            if (response.error) {
                openModal({ header: "", message: <MessageError message={'Your session has been expired. Please RE-SIGN IN again.'} /> });
                setProfile({ loading: false, data: [], message: 'Your session has been expired. Please RE-SIGN IN again.' })
            } else if ("message" in response.response) {
                openModal({ header: "", message: <MessageError message={response.response.message.user} /> });
                setProfile({ loading: false, data: [], message: response.response.message.user })
                setTimeout(() => {
                    navigate("/sign-out");
                }, 1000);
            } else {
                if (response.response.data) {
                    setProfile({ loading: false, data: response.response.data, message: "" })
                } else {
                    setProfile({ loading: false, data: response.response.data, message: "No record found" })
                    openModal({ header: "", message: <MessageError message={'No record found for this user'} /> });
                }
            }
        });
    }

    const HandlerAsideMinimaize = () => {
        var body = document.getElementById("kt_body");
        var aside_value = body.getAttribute("data-kt-app-sidebar-minimize");
        body.setAttribute("data-kt-app-sidebar-minimize", (aside_value === "on") ? "off" : "on");
        var ButtonAside = document.getElementById("kt_app_sidebar_toggle");
        //var IconAside = ButtonAside.getElementsByClassName("rotate");
        //IconAside.className = (aside_value === "on") ? "fa fa-solid fa-angles-left fs-1" : "fa fa-solid fa-angles-right fs-1";
        //body.setAttribute("data-kt-app-sidebar-minimize", (aside_value === "on") ? "off" : "on");
        (aside_value === "on") ? ButtonAside.classList.remove('active') : ButtonAside.classList.add('active');
    }

    const HandlerAsideMobile = () => {
        var body = document.getElementById("kt_body");

        var KTAside = document.getElementById("kt_app_sidebar");
        var navMobile = document.getElementById("kt_app_sidebar_mobile_toggle");
        var ValueNav = navMobile.getAttribute("data-kt-toogle-nav");

        console.log(ValueNav)
        if (ValueNav === "off") {
            // KTAside.classList.add("drawer", "drawer-start", "drawer-on");
            KTAside.classList = "app-sidebar flex-column drawer drawer-start drawer-on";
        } else {
            //KTAside.classList.remove("drawer", "drawer-start", "drawer-on");
            KTAside.classList = "app-sidebar flex-column drawer drawer-start ";
        }
        console.log(KTAside)
        body.setAttribute("data-kt-app-sidebar-minimize", (ValueNav === "on") ? "off" : "on");
        body.setAttribute("data-kt-drawer", (ValueNav === "on") ? "off" : "on");
        navMobile.setAttribute("data-kt-toogle-nav", (ValueNav === "on") ? "off" : "on");
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

    useEffect(() => {
        GetProfile();

        setTimeout(() => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = 0;
                scrollRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 50);
    }, [pathname])

    return (
        <>
        <div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
            <div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo" >
                <div className="logo-container flex-column">
                    <img alt="Logo" src={LogoImg} className="h-25px app-sidebar-logo-default" />
                    <img alt="Logo" src={LogoImg} className="h-20px app-sidebar-logo-minimize" />
                    <span className="logo-text px-2 text-white  text-uppercase">STEM</span>
                </div>

                <div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary h-30px w-30px position-absolute top-50 start-100 translate-middle rotate active" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize" onClick={() => HandlerAsideMinimaize()}>
                    <i className="ki-duotone ki-black-left-line fs-3 rotate-180">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                </div>
            </div>

            <div className="app-sidebar-menu overflow-hidden flex-column-fluid">

                <div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper">
                    <div id="kt_app_sidebar_menu_scroll" className="scroll-y my-5 mx-3" 
                    ref={scrollRef}
                    data-kt-scroll="true" 
                    data-kt-scroll-activate="true" 
                    data-kt-scroll-height="auto" 
                    data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" 
                    data-kt-scroll-wrappers="#kt_app_sidebar_menu" 
                    data-kt-scroll-offset="5px" 
                    data-kt-scroll-save-state="true">
                        <div className="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                            <div className="d-flex align-items-center d-lg-none " title="Hide sidebar menu">
                                <div className="mx-auto">

                                </div>
                                <div className="btn btn-icon btn-active-color-primary w-35px h-35px end-0" onClick={() => HandlerAsideMobile()}>
                                    <i className="ki-duotone ki-black-left-line fs-3 rotate-180">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                </div>
                            </div>
                            
                            <div id="kt_aside_menu" className='menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500' data-kt-menu="true">
                        <AsideMenuItem to='/dashboard' icon='bi-grid' title={"Dashboard"} />
                        {/* <AsideMenuItemWithSub to='/campaigns' title='Campaign' icon='bi-envelope-plus'>
                            <AsideMenuItem to='/campaigns/create' hasBullet={true} title={"Create"} />
                        </AsideMenuItemWithSub> */}
                        {Object.values(menu_public).length > 0 && (
                            menu_public.map((m, index) => (
                                <AsideMenuItem to={m.path_uri} icon={m.icon} title={m.name} key={index} />
                            ))
                        )}

                        {Object.values(menu_private).length > 0 && (
                            <>
                                <div className="menu-item">
                                    <div className="menu-content pt-8 pb-2">
                                        <span className="menu-section text-muted text-uppercase fs-8 ls-1">Configurations</span>
                                    </div>
                                </div>
                                {menu_private.map((m, index) => (
                                    <AsideMenuItem to={m.path_uri} icon={m.icon} title={m.name} key={index} />
                                ))}
                                
                                
                            </>
                        )}

                    </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
                <a href="https://preview.keenthemes.com/html/metronic/docs" className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss-="click" title="200+ in-house components and 3rd-party plugins">
                    <span className="btn-label">Docs & Components</span>
                    <i className="ki-duotone ki-document btn-icon fs-2 m-0">
                        <span className="path1"></span>
                        <span className="path2"></span>
                    </i>
                </a>
            </div>
        </div>
        
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-1 ps-1">
            <div id="kt_app_toolbar_container" className="app-container container-fluid d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">{capitalizeFirstLetter(current_path)}</h1>
                    {Object.values(current_pathArr).length > 1 && (
                            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1">
                                {current_pathArr.map((v, index) => (
                                    ((v && index !== 1) && (
                                        <React.Fragment key={v}>
                                            <li className="breadcrumb-item text-muted" key={index}>
                                                <span className="text-muted text-hover-primary text-capitalize">
                                                    {v.replace(/[^\w\s]/gi, ' ')}
                                                </span>
                                            </li>
                                            {(index !== (Object.values(current_pathArr).length - 1)) && (
                                                <li className="breadcrumb-item">
                                                    <span className="bullet bg-gray-500 w-5px h-2px"></span>
                                                </li>
                                            )}
                                        </React.Fragment>
                                    ))
                                ))}
                            </ul>
                        )}
                </div>
            </div>
        </div>
        
        </>

    )


}
