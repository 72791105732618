import { Link } from "react-router-dom";
import { openModal } from "../layouts/modals/Modals";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../layouts/popovers/PopOvers";

const ButtonCreateEvent = () => {
  return (
    <Link
      className="nav-link btn btn-lg btn-primary fw-bold px-4 me-1"
      to={"/events/create"}
    >
      <i className="bi bi-plus fs-2"></i>
      Create New Event
    </Link>
  );
};

const NavTabs = ({ campaigns, campaignsDraft, campaignsSend }) => {
  return (
    <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mb-5 fs-6">
      <li className="nav-item">
        <Link
          className="nav-link fw-bolder fs-4 active"
          data-bs-toggle="tab"
          to="#all-campaigns"
        >
          All Campaigns ({Object.values(campaigns).length})
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link fw-bolder fs-4"
          data-bs-toggle="tab"
          to="#pending"
        >
          Pending ({Object.values(campaignsDraft).length})
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link fw-bolder fs-4"
          data-bs-toggle="tab"
          to="#completed"
        >
          Completed ({Object.values(campaignsSend).length})
        </Link>
      </li>
    </ul>
  );
};

const formatRupiah = (amount) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export { ButtonCreateEvent, NavTabs, formatRupiah };
