import axios from "axios";
import CryptoJS from "crypto-js";

//const ESTES_URI = process.env.REACT_APP_ESTES_API;
const ESTES_URI = "http://localhost/program/SGU/backend/middleware-api/"

async function AxiosLibReq(value,pathURI) {
    var headers=value.header;
    headers['Content-Type'] = 'application/json';    
    
    var config = {
        method: 'post',
        url: ESTES_URI+pathURI,
        headers,
        data : value.body
    };
    
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Error fetch data from Middleware.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

//const EXPRESS_URI = process.env.REACT_APP_EXPRESS_API;
async function AxiosLibGlobalReq(value,pathURI) {
    var headers={'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBOYW1lIjoiZXZlbnQtc2d1IiwiaWF0IjoxNzIzNjQ5NjIzfQ.qRkekMY-1cK1S2evERxEmZzsvvd6IbqlGqIurO8TZCE'};
    
    headers['Content-Type'] = 'application/json';    
    
    var config = {
        method: value.method ? value.method : "post",
        url: pathURI,
        headers,
        data : value.body
    };
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Failed to load resource:";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}


async function AxiosLibGlobalReq2(value,pathURI) {
    var headers=value.header;
    headers['Content-Type'] = 'application/json';    
    
    var config = {
        method: value.method ? value.method : "post",
        url: pathURI,
        headers,
        data : value.body
    };
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Failed to load resource:";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

async function AxiosLibGlobalReqModule(value,pathURI) {
    
    var Secretkey = value.header.Secretkey;           
    var d = new Date();
    var tstamp = d.getTime() / 1000;
    
    
    var body=value.body;
    
    var payloadTmp = body;
    //var trimmed_payload = payloadTmp.toString().replace(/\s+/g, '');
    var trimmed_payload = JSON.stringify(payloadTmp).replace(/\s+/g, '');
    var base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(trimmed_payload));
    //console.log(base64);
    var signature_raw = tstamp + '|<->|' + base64;
    //console.log(signature_raw);
    var signature_bytes = CryptoJS.HmacSHA256(signature_raw,Secretkey);
    //console.log(signature_bytes);
    var signature = CryptoJS.enc.Base64.stringify(signature_bytes);
    var headers={
        "App-Id":value.header.AppId,
        "App-Env":value.header.AppEnv,
        "App-Signature":signature,
        "App-Timestamp":tstamp,
        'Content-Type':'application/json'
    }  
    
    var config = {
        method: 'post',
        url: pathURI,
        headers,
        data : body
    };
    
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Error fetchi data from '"+pathURI+"'.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

async function AxiosLibBillApp(value,pathURI) {
    var headers = value.header || {};
    headers['Content-Type'] = 'application/json';    

    const username = process.env.REACT_APP_BILLAPP_USERNAME;
    const password = process.env.REACT_APP_BILLAPP_PASSWORD;
    const basicAuth = 'Basic ' + btoa(username + ':' + password);
    headers['Authorization'] = basicAuth;

    var config = {
        method: value.method ? value.method : "post",
        url: pathURI,
        headers,
        data : value.body
    };
    
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Failed to load resource:";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

async function AxiosLibTokenReq(value,pathURI) {
   
    var headers={'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBOYW1lIjoiZXZlbnQtc2d1IiwiaWF0IjoxNzIzNjQ5NjIzfQ.qRkekMY-1cK1S2evERxEmZzsvvd6IbqlGqIurO8TZCE'};
    headers['Content-Type'] = 'application/json';    
    
    var config = {
        method: value.method ? value.method : "post",
        url: pathURI,
        headers,
        data : value.body
    };
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            var message = "Failed to load resource:";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

export {AxiosLibReq, AxiosLibGlobalReq,AxiosLibGlobalReq2, AxiosLibGlobalReqModule ,AxiosLibBillApp, AxiosLibTokenReq}