import React from "react";
import moment from "moment";
import { useState } from "react";
import {
  AxiosLibGlobalReq,
  AxiosLibGlobalReqModule,
} from "../../../helpers/AxiosLibs";
import { useEffect } from "react";
import { LoadingBars } from "../../layouts/loadbars/LoadingBars";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import { ButtonPrimary } from "../../layouts/buttons";
import { RemoveEmptyValue } from "../../../helpers/CommonHelpers";

export default function GenerateItem({ jumlah ,ReloadData}) {
  var Token = AuthDecodeToken();

  const objParam = {
    filter_keywords_val: "",
    page: 1,
    limit: 100,
  };
  const [post, setPost] = useState(objParam);
  const [updStatus, setUpdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const getItems = (param) => {
    setUpdStatus({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/item/fetch-store"
    ).then((response) => {
      if (response.error) {
        setUpdStatus({ loading: false, data: [], message: response.error });
      } else if (response.status === 200) {
          setUpdStatus({
            loading: false,
            data: response.data,
            message: "Successfully Saved",
          });
          openModal({
            message: <MessageSuccess message={"Successfully Saved"} />,
          });
          ReloadData();
      } else {
        setUpdStatus({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const SubmitForm = (e) => {
    e.preventDefault();
    if (post.filter_keywords_val) {
        const param = RemoveEmptyValue(post);
            param.item_id = param.item_id
            getItems(param);
    } else {
        openModal({
            message: (
                <MessageError message={"Please fill out the form with correctly"} />
            ),
        });
    }
};


  return updStatus.message ? (
    <MessageError message={updStatus.message} />
  ) :
    <div className="detail-item">
      <form
        method="post"
        autoComplete="off"
        id="form-import"
        onSubmit={(e) => SubmitForm(e)} >
      <div className="mb-5">
        <label className=" fw-bolder">Keywords</label>
        <input
          type="text"
          name="Kyword"
          className="form-control"
          placeholder="SEMINAR"
          value={post.filter_keywords_val}
          onChange={(e) => setPost({ ...post, filter_keywords_val: e.target.value })}

        />
      </div>
      <div className="mb-5">
        <label className=" fw-bolder">Page</label>
        <input
          type="text"
          name="Page"
          className="form-control"
          value={post.page}
          onChange={(e) => setPost({ ...post, page: e.target.value })}

        />
      </div>

      <div className="my-10">
        <div className="d-flex justify-content-end align-items-center">
          {/* <button className="btn btn-lg btn-light fw-bold" type="button" onClick={()=>ModifyCampaign(campaigns.data.campaign_id)}>Modify</button> */}
          {
            <div className="btn-group">
              <ButtonPrimary
                className="btn-icon btn-lg"
                disabled={updStatus.loading}
              >
                <i className="bi bi-layer-backward fs-1"></i>
              </ButtonPrimary>
              <ButtonPrimary
                className="btn-lg"
                type="submit"
                disabled={updStatus.loading}
              >
                {updStatus.loading ? (
                  <span>Processing...</span>
                ) : (
                  <span className="me-2">Import From Accurate</span>
                )}
              </ButtonPrimary>
            </div>

          }
        </div>
      </div>
    </form>
    </div >

}

