import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//import RegistrationForm from "./RegistrationForm";
import Banner from "./Banner";
import Modals, { MessageError, MessageSuccess, openModal } from "../layouts/modals/Modals";

import { AxiosLibGlobalReq } from "../../helpers/AxiosLibs";

//import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import moment from "moment";
import { toTitleCase } from "./components/FormAtributes";
import { RemoveEmptyValue } from "../../helpers/CommonHelpers";
import BgImg from "../../../_metronic/media/auth/bg6.jpg";


export default function RegisterLayout() {
  const location = useLocation();
  //const params = new URLSearchParams(location.search);
  //const token = params.get("token");
  const path = location.pathname;
  // Use a regular expression to extract the token part from the path
  const encEvent = path.match(/\/registration\/(.+)/)?.[1];
  const tkn = JSON.parse(atob(encEvent.replace(/_/g, '/').replace(/-/g, '+')));

  const navigation = useNavigate();

  var AuthToken = localStorage.getItem("AuthToken");
  var datetime = new Date();
  var year = datetime.getFullYear();

  const [data, setData] = useState({ loading: true, data: [], message: "" });

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [couponValid, setCouponValid] = useState({
    counter: 3,
    status: "Check",
    message: "",
  });

  const [captchaToken, setCaptchaToken] = useState('');
  const [token, setToken] = useState('');
  //const { executeRecaptcha } = useGoogleReCaptcha();

  const [listCoupon, setListCoupon] = useState();
  const [dtCoupon, setDtCoupon] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    fullname: "",
    phone: "",
    email: "",
    origin: "",
    trx_amount: 0,
    event_id: (tkn) ? tkn.event_id : 0,
    coupon: "",
    discount: "",
    nominal: ""
  });

  const [postItem, setPostItem] = useState();

  const handleForm = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;
    const onlyNumbers = input.replace(/\D/g, '');
    setFormData((prev) => ({
      ...prev,
      phone: onlyNumbers,
    }));
  };

  const handleMail = (event) => {
    const input = event.target.value;
    setFormData((prev) => ({
      ...prev,
      email: input,
    }));
    // Basic email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(input)) {
      setError(''); // Clear the error if the email is valid
    } else {
      setError('Please enter a valid email address.');
    }
  };

  const handleName = (event) => {
    const inputValue = event.target.value;
    const formattedValue = toTitleCase(inputValue);
    setFormData((prev) => ({
      ...prev,
      fullname: formattedValue,
    }));
  };

  const handleOrigin = (event) => {
    const inputValue = event.target.value;
    const formattedValue = toTitleCase(inputValue);
    setFormData((prev) => ({
      ...prev,
      origin: formattedValue,
    }));
  };


  const getDetails = (event_id) => {
    setData({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      method: "get",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/event/" + event_id
    ).then((response) => {
      //console.log(response);
      if (response.error) {
        setData({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        const result = response.data;
        //console.log(result)
        const currentDate = moment(); // get current date as string
        const startDate = moment(result.reg_start);
        const endDate = moment(result.reg_end); // corrected to use reg_end
        const isWithinDateRange = moment(currentDate).isSameOrBefore(endDate);
        //console.log(isWithinDateRange)
        if (!isWithinDateRange) {
          navigation("/landing-page");
        }
        if (result.is_active === 0) {
          navigation("/landing-page");
        }
        setData({
          loading: false,
          data: result,
          message: "",
        });
        /* if (result.Coupons.length > 0) {
           setListCoupon(result.Coupons)
         }*/
        if (result.Items.length > 0) {
          setPostItem(result.Items)
        }


        const totalAmount = result.Items.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.price * 1;
        }, 0);
        setFormData({
          fullname: "",
          phone: "",
          email: "",
          origin: "",
          trx_amount: totalAmount,
          event_id: (tkn) ? tkn.event_id : 0,
          coupon: "",
          discount: "",
          nominal: "",
        });
      } else {
        setData({ loading: false, data: [], message: response.message });
      }
    });
  };

  const getCoupon = async (paramC) => {
    const parameter = {
      header: {},
      body: paramC,
      method: "post",
    };

    try {
      const response = await AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/coupon/cekcoupon"
      );

      if (response.error) {
        setDtCoupon({ loading: false, data: [], message: response.error });
        return [];
      } else if (response.data && response.status === 200) {
        const result = response.data;
        setDtCoupon({
          loading: false,
          data: result,
          message: "Successfully",
        });

        if (result.Events && result.Events.length > 0) {
          const cp = {
            coupon_code: result.coupon_code,
            discount: result.discount,
            nominal: result.nominal,
          };
          const ArrCp = [cp];
          setListCoupon(ArrCp);
          return ArrCp;
        }
        return [];
      } else {
        setDtCoupon({ loading: false, data: [], message: "Failed Resend" });
        return [];
      }
    } catch (error) {
      //console.error("Error in getCoupon:", error);
      setDtCoupon({ loading: false, data: [], message: "Error occurred" });
      return [];
    }
  };
  const getCouponThen = async (paramC) => {
    const parameter = {
      header: {},
      body: paramC,
      method: "post",
    };
    await AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/coupon/cekcoupon"
    ).then((response) => {
      //console.log(response)
      if (response.error) {
        setDtCoupon({ loading: false, data: [], message: response.error });
        return [];
      } else if (response.data && response.status === 200) {
        const result = response.data;
        setDtCoupon({
          loading: false,
          data: result,
          message: "Successfully",
        });
        if (result.Events.length > 0) {
          const cp = {
            coupon_code: result.coupon_code,
            discount: result.discount,
            nominal: result.nominal,
          };
          const ArrCp = [cp];
          setListCoupon(ArrCp)
          return ArrCp;
        }
        return [];
      } else {
        setDtCoupon({ loading: false, data: [], message: "Failed Resend" });
        return [];
      }
    });
  };

  useEffect(() => {
    if (tkn) {
      getDetails(tkn.event_id);
    }
    if (window.grecaptcha) {
      // Execute reCAPTCHA to get the token
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LcbjSwqAAAAAGgPgdwFoYH2BElEScPr0HZBZNan', { action: 'submit' }).then((token) => {
          setToken(token);
        });
      });
    } else {
      //console.error('reCAPTCHA is not loaded');
    }
  }, []);

  useEffect(() => {
    if (formData.coupon === "")
      setCouponValid((prev) => ({
        ...prev,
        status: "Check",
      }));
    if (couponValid.status === "Invalid") {
      setTimeout(() => {
        setCouponValid((prev) => ({
          ...prev,
          status: "Check",
        }));
        setFormData((prev) => ({
          ...prev,
          coupon: "", discount: "", nominal: "",
        }));
      }, 1000);
    }
  }, [formData.coupon, couponValid.status]);

  const submitForm = async (e) => {
    e.preventDefault();
    /*if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('submit_form');
    */
    //console.log('reCAPTCHA token:', token);
    setCaptchaToken(token);
    setSubmit({ loading: true, data: [], message: "" });
    if (token) {
      const updatedFormData = {
        ...formData,
        coupon_code: formData.coupon,
        inv_amount: formData.trx_amount,
        dsc_persent: formData.discount,
        dsc_nominal: formData.nominal
      };

      const dept = (data) ? data.data.Contacts[0].dept_name : "";
      delete updatedFormData.coupon; delete updatedFormData.trx_amount;
      delete updatedFormData.discount; delete updatedFormData.nominal;
      updatedFormData.status = "PENDING";
      updatedFormData.department_name = dept;
      const param = RemoveEmptyValue(updatedFormData);
      let sendData;
      sendData = {
        regis: param,
        items: postItem,
        captchaToken: token
      };


      const parameter = {
        header: {},
        method: "post",
        body: { ...sendData },
      };
      //console.log(parameter)

      AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_EXPRESS_API + "/api/regis/save"
      ).then((response) => {
        //console.log("response ", response);
        if (response.error) {
          setSubmit({ loading: false, data: [], message: response.error });
          openModal({
            message: <MessageError message={response.error} />,
          });
        } else if (response.data && response.status === 200) {
          const result = response.data;
          setSubmit({
            loading: false,
            data: result,
            message: "Registration success",
          });
          openModal({
            message: <MessageSuccess message={"You have successfully registered. Please check your email and WhatsApp for the payment link."} />,
          });

        }
        else if (response.status === 200) {
          openModal({
            message: <MessageError message={response.message} />,
          });
        }
        else {
          setSubmit({ loading: false, data: [], message: response.message });
        }
      });
    }
    else {
      //console.error('Captcha validation failed');
    }
  };

  const handleCheckCoupon = async () => {
    // console.log("cehck kupon", couponValid.counter);
    if (couponValid.counter < 1) navigation("/landing-page");
    // couponValid.status === "Check" &&
    setCouponValid((prev) => ({
      ...prev,
      counter: prev.counter - 1,
    }));
    const couponDetails = await getCouponDetails(formData.coupon)
    if (couponDetails) {
      if (couponDetails.discount !== "") {
        setCouponValid((prev) => ({
          ...prev,
          status: "Valid"
        }));
        setFormData((prev) => ({
          ...prev,
          discount: couponDetails.discount
        }));
      }
      if (couponDetails.nominal !== "") {
        setCouponValid((prev) => ({
          ...prev,
          status: "Valid"
        }));
        setFormData((prev) => ({
          ...prev,
          nominal: couponDetails.nominal
        }));
      }

      //console.log(`Discount: ${couponDetails.discount}, Nominal: ${couponDetails.nominal}`);
    } else {
      setCouponValid((prev) => ({
        ...prev,
        status: "Invalid",
      }));
    }



  };


  const getCouponDetails = async (coupon_code) => {
    const param = { coupon_code: coupon_code, event_id: formData.event_id };
    //const cekCoupon =await getCoupon(param);
    //const foundCoupon =  cekCoupon.find((coupon) => coupon.coupon_code === coupon_code && coupon.status !== "1");
    //const foundCoupon = listCoupon.find((coupon) => coupon.coupon_code === coupon_code && coupon.status !== "1");
    const cekCoupon = await getCoupon(param);
    if (Array.isArray(cekCoupon)) {
      const foundCoupon = cekCoupon.find((coupon) => coupon.coupon_code === coupon_code && coupon.status !== "1");
      //console.log(foundCoupon ? "Coupon found:" : "Coupon not found:", foundCoupon);
      if (foundCoupon) {
        return {
          discount: foundCoupon.discount,
          nominal: foundCoupon.nominal,
        };
      } else {
        return null;
      }
    } else {
      //console.log("getCoupon did not return an array:", cekCoupon);
      return null;
    }

  };

  const handleVerify = (token) => {
    setCaptchaToken(token);
  };
  //console.log(formData)

  return (
    <div
      className="d-flex flex-column flex-center flex-column-fluid"
      style={{
        backgroundImage: `url(${BgImg})`
      }}
    >
      {data.loading ? (
        <p>Loading...</p>
      ) : submit.data.length > 0 ? (
        <div className="text-center bg-white py-10 px-20 rounded">
          {submit.message === "Registration success" ? (
            <>
              <div className="icon mb-5">
                <i
                  className="bi bi-check-circle text-success"
                  style={{ fontSize: "5em" }}
                ></i>
              </div>
              <p className="mb-10">{submit.message}</p>
              <button
                className="btn btn-lg btn-primary fw-bolder"
                type="button"
                onClick={() => navigation("/landing-page")}
              >
                Ok, got it!
              </button>
            </>
          ) : ""}
        </div>
      ) : (
        <div className="d-flex flex-column flex-center text-left p-2">
          <div className="row g-5 g-xl-10 mb-2 mb-xl-2">
            <Banner data={data.data} />
          </div>
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
            <div className="card card-flush w-lg-800px" style={{
              background: 'linear-gradient(112.14deg, #3A7BD5 0%, #00D2FF 100%)'
            }}>
              <div id="kt_account_settings_profile_details"
                className="collapse show"
              >
                <div className="card-body px-15" >
                  <form id="form-registration" method="post" className="form" onSubmit={(e) => submitForm(e)}>
                    <div className="row">
                      <div className="mb-5">
                        <label htmlFor="fullname" className="required form-label text-white">
                          Name
                        </label>
                        <input
                          type="text"
                          name="fullname"
                          value={formData.fullname}
                          onChange={(e) => handleName(e)}
                          className="form-control form-control-solid"
                          placeholder="Input your full name"
                          autoComplete="off"
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="phone" className="required form-label text-white">
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={(e) => handlePhoneNumberChange(e)}
                          className="form-control form-control-solid"
                          maxLength="14" // Assuming a 10-digit mobile number
                          placeholder="08XXXXXXXXXXXX"
                          autoComplete="off"
                        />
                      </div>
                      <div className="mb-5">
                        <label htmlFor="email" className="required form-label text-white">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={(e) => handleMail(e)}
                          className="form-control form-control-solid"
                          placeholder="example@domain.com"
                          autoComplete="off"
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                      </div>
                      <div className="mb-5">
                        <label htmlFor="applyFrom" className="required form-label fs-7 text-white">
                          SGU student write the name of study program. if other, write the name of the school, campus ,company etc
                        </label>
                        <input
                          type="text"
                          name="applyFrom"
                          value={formData.origin}
                          onChange={(e) => handleOrigin(e)}
                          className="form-control form-control-solid"
                          placeholder="Where do you come from?"
                          autoComplete="off"
                        />
                      </div>
                      <div
                        className="mb-5"
                      >

                        <label htmlFor="coupon" className="form-label text-white">
                          Coupon Code
                        </label>
                        <div className="input-group input-group-solid">
                          <input
                            type="text"
                            name="coupon"
                            value={formData.coupon}
                            onChange={(e) => handleForm(e)}
                            className="form-control form-control-solid"
                            placeholder="Enter the coupon code if you have one, or leave it blank."
                            autoComplete="off"
                          />
                          <button
                            className={`btn ${couponValid.status === "Valid" && "btn-success"
                              } ${couponValid.status === "Invalid" && "btn-warning"
                              } ${couponValid.status === "Check" && "btn-info"
                              } d-flex align-items-center`}
                            disabled={formData.coupon === "" ? true : false}
                            onClick={(e) => {
                              e.preventDefault();
                              handleCheckCoupon();
                            }}
                          >
                            {couponValid.status}
                          </button>
                        </div>
                        {(couponValid.counter < 3 && couponValid.status !== "Valid") ?
                          (
                            <span className="fw-bold fs-6  mt-1 text-warning">
                              <strong>{couponValid.counter}</strong> chances remaining.
                            </span>
                          ) : ""}

                        <div className="mt-5">
                          <span className="fw-bold text-white opacity-100 d-block fs-7">*Make sure your phone number and email are correct to continue with the payment. </span>
                        </div>
                      </div>
                    </div>

                    <div className="my-5">
                      <div className="text-end mt-10 d-flex justify-content-between">
                        <button
                          className="btn btn-secondary"
                          onClick={() => navigation("/landing-page")}
                        >
                          Back
                        </button>
                        {(submit.message !== "Registration success") &&
                          <button
                            type="submit"
                            className="btn btn-dark"
                            style={{ display: (formData.coupon !== "" && couponValid.status !== "Valid") ? "none" : "inline" }}
                            disabled={
                              Object.entries(formData).some(
                                ([key, value]) => (key !== "coupon" && value === "") && (key !== "discount" && value === "") && (key !== "nominal" && value === "")
                              )
                            }
                          >
                            {submit.loading ? "Loading..." : "Submit"}
                          </button>
                        }
                      </div>

                    </div>
                  </form>
                </div>


              </div>
            </div>
          </div>
        </div>
      )}
      <Modals />
    </div>
  );
}
