import { useEffect, useState } from "react";
import {
  AxiosLibGlobalReq,
  AxiosLibGlobalReqModule,
} from "../../../../helpers/AxiosLibs";
import { AuthDecodeToken } from "../../../authentications/reducers/AuthRedux";
import { validateEmail } from "../../../../helpers/CommonHelpers";

const Token = AuthDecodeToken();

const FetchAllDataMail = (setRecepients) => {
  const parameter = {
    header: {},
    body: {},
    method: "get",
  };
  AxiosLibGlobalReq(
    parameter,
    process.env.REACT_APP_EXPRESS_API + "/api/recepients"
  ).then((response) => {
    if (response.data && response.status === 200) {
      let datamail = response.data;
      let dataActive = datamail.filter(item => item.is_active === 1);
      let results = dataActive.map((v) => {
        var obj = {};
        obj.name = decodeURIComponent(v.name);
        obj.description = v.description;
        obj.email = "";
        obj.members = JSON.parse(v.mail_users);
        return obj;
      });
      setRecepients(results);
    }
  });
};

const RDB_GROUP_GOOGLE = (setRecepients) => {
  var keys = {
    AppId: Token.AppID,
    AppEnv: Token.AppEnv,
    Secretkey: Token.Secretkey,
  };
  const parameter = {
    header: keys,
    body: { target_data: "RDB_GROUP_GOOGLE" },
    method: "post",
  };

  AxiosLibGlobalReqModule(
    parameter,
    process.env.REACT_APP_ESTES_API +
      process.env.REACT_APP_ESTES_API_PATH_REQUEST
  ).then((response) => {
    if (response.response.data) {
      var datamail = response.response.data;
      var results = datamail.map((v) => {
        var obj = {};
        obj.name = v.name;
        obj.description = v.description;
        obj.email = v.email;
        var mailMember = [];
        if (Object.values(v.members).length > 0) {
          mailMember = v.members.map((v) => {
            return v.email;
          });
        }
        obj.members = mailMember;
        return obj;
      });
      setRecepients((prev) => [...prev, ...results]);
    }
  });
};

const EmailForm = ({ mailList, setMailList, mailSingle, setMailSingle }) => {
  const [recepients, setRecepients] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    FetchAllDataMail(setRecepients);
    RDB_GROUP_GOOGLE(setRecepients);
  }, []);

  const HandlerMailSet = (value) => {
    const emails = value.split(/[ ,;]+/);
    const lastEmail = emails[emails.length - 1].trim().toLowerCase();

    if (emails.length > 1 && lastEmail !== "") {
      emails.slice(0, -1).forEach((email) => {
        email = email.trim();
        if (validateEmail(email) && !mailList.includes(email)) {
          setMailList((prevList) => [...prevList, email]);
        }
      });
      setMailSingle(lastEmail);
    } else {
      setMailSingle(value);
    }

    if (lastEmail) {
      const matches = new Set();
      const matchResults = [];
      recepients.forEach((recipient) => {
        if (recipient.email && recipient.email.toLowerCase().includes(lastEmail)) {
          if (!matches.has(recipient.email)) {
            matches.add(recipient.email);
            matchResults.push({
              type: "email",
              value: recipient.email,
              members: recipient.members,
            });
          }
        }
        // Mencari berdasarkan recepients.name
        if (recipient.name.toLowerCase().includes(lastEmail)) {
          if (!matches.has(recipient.name)) {
            matches.add(recipient.name);
            matchResults.push({
              type: "name",
              value: recipient.name,
              members: recipient.members,
            });
          }
        }
        // Mencari berdasarkan recepients.members
        recipient.members.forEach((memberEmail) => {
          if (memberEmail.toLowerCase().includes(lastEmail) && !matches.has(memberEmail)) {
            matches.add(memberEmail);
            matchResults.push({ type: "member", value: memberEmail });
          }
        });
      });
      setSearchResults([...matchResults]);
    } else {
      setSearchResults([]);
    }
  };

  const RemoveMailMember = (email) => {
    const removeitem = mailList.filter((item) => item !== email);
    setMailList(removeitem);
  };

  const handleSelectEmail = (selected) => {
    if (selected.type === "name") {
      selected.members.forEach((memberEmail) => {
        if (!mailList.includes(memberEmail)) {
          setMailList((prevList) => [...prevList, memberEmail]);
        }
      });
    } else {
      if (!mailList.includes(selected.value)) {
        setMailList((prevList) => [...prevList, selected.value]);
      }
    }
    setMailSingle("");
    setSearchResults([]);
  };

  return (
    <div className="form-mail">
      <div
        className="form-control py-0"
        style={{ maxHeight: "150px", overflowY: "auto" }}
      >
        <div className="row">
          {mailList.map((email, index) => (
            <div className="col-auto p-0" key={index}>
              <ItemMail mail={email} RemoveMailMember={RemoveMailMember} />
            </div>
          ))}
        </div>
        <input
          type="text"
          value={mailSingle}
          className="form-control form-control-flush"
          placeholder="Type email here..."
          onChange={(e) => HandlerMailSet(e.target.value)}
        />
      </div>
      {searchResults.length > 0 && (
        <div className="search-results w-300px" style={{ position:"absolute", zIndex:5 }}>
          <ul className="list-group">
            {searchResults.map((v, index) => (
              <li
                className="list-group-item cursor-pointer bg-hover-light"
                key={index}
                onClick={() => handleSelectEmail(v)}
              >
                {v.type === "email" && (
                  <>
                    <span className="d-block me-2">{v.value}</span>
                    <span className="">{v.members.length} members</span>
                  </>
                )}
                {v.type === "name" && (
                  <>
                    <span className="me-2">{v.value}</span>
                    <span className="">({v.members.length} members)</span>
                  </>
                )}
                {v.type === "member" && v.value}
              </li>
            ))}
          </ul>
        </div>
      )}
      <span className="text-muted">
        Use commas (,) between email in a sentence list.
      </span>
    </div>
  );
};

const ItemMail = ({ mail, RemoveMailMember }) => {
  return (
    <div className="rounded-pill border d-flex align-items-center m-1 p-1">
      <ItemLabelMail mail={mail} />
      <div
        className="cursor-pointer pe-3"
        onClick={() => RemoveMailMember(mail)}
      >
        <span className="bi bi-x text-dark"></span>
      </div>
    </div>
  );
};

const ItemLabelMail = ({ mail }) => {
  return (
    <div className="d-flex align-items-center me-2">
      <div className="symbol symbol-circle symbol-25px me-2">
        <div className="symbol-label fs-8 fw-bold bg-primary text-white border border-primary text-uppercase">
          {mail.charAt(0)}
        </div>
      </div>
      <span className="">{mail}</span>
    </div>
  );
};


export { EmailForm, ItemLabelMail };
