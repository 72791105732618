import React from 'react'
import { UserAccount } from './current-account/UserAccount'
import { AccountRequest } from './account-request/AccountRequest'
import { MGroups } from './groups'

export function UserManagement() {
    return (
        <div className='row g-5 g-xl-8'>
            <div className="col-xl-8">
                <UserAccount />
            </div>
            <div className="col-xl-4">
                <AccountRequest />
            </div>
            <div className="col-xl-12">
                <MGroups />
            </div>
        </div>
    )
}
