import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import LogoImg from "../../../../_metronic/media/logo-sgu-white.png";
import { Dropdown } from 'react-bootstrap';
import { AuthDecodeToken } from '../../authentications/reducers/AuthRedux';
import UsrImg from "../../../../_metronic/media/300-3.jpg";

export function AsideHeader() {
    const location = useLocation();
    const { pathname } = location;



    const HandlerAsideMinimaize = () => {
        var body = document.getElementById("kt_body");
        var aside_value = body.getAttribute("data-kt-aside-minimize");
        body.setAttribute("data-kt-aside-minimize", (aside_value === "on") ? "off" : "on");

        var ButtonAside = document.getElementById("kt_aside_toggle");
        var IconAside = ButtonAside.getElementsByClassName("fa")[0];
        IconAside.className = (aside_value === "on") ? "fa fa-solid fa-angles-left fs-1" : "fa fa-solid fa-angles-right fs-1";
    }

    const HandlerAsideMobile = () => {
        var body = document.getElementById("kt_body");

        var KTAside = document.getElementById("kt_app_sidebar");
        var navMobile = document.getElementById("kt_app_sidebar_mobile_toggle");
        var ValueNav = navMobile.getAttribute("data-kt-toogle-nav");

        console.log(ValueNav)
        if (ValueNav === "off") {
            // KTAside.classList.add("drawer", "drawer-start", "drawer-on");
            KTAside.classList = "app-sidebar flex-column drawer drawer-start drawer-on";
        } else {
            //KTAside.classList.remove("drawer", "drawer-start", "drawer-on");
            KTAside.classList = "app-sidebar flex-column drawer drawer-start ";
        }
        console.log(KTAside)
        body.setAttribute("data-kt-app-sidebar-minimize", (ValueNav === "on") ? "off" : "on");
        body.setAttribute("data-kt-drawer", (ValueNav === "on") ? "off" : "on");
        navMobile.setAttribute("data-kt-toogle-nav", (ValueNav === "on") ? "off" : "on");
    }

    const Token = AuthDecodeToken();
    const current_user = Token.identity ? Token.identity.given_name : "-";


    return (
        <div
            id="kt_app_header"
            className="app-header"
            data-kt-sticky="true"
            data-kt-sticky-activate={{ default: true, lg: true }}
            data-kt-sticky-name="app-header-minimize"
            data-kt-sticky-offset={{ default: '200px', lg: '0' }}
            data-kt-sticky-animation="false"
        >

            <div className="app-container container-fluid d-flex align-items-stretch justify-content-between" id="kt_header_container" >
                <div className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
                    <div className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle" data-kt-toogle-nav="off" onClick={() => HandlerAsideMobile()}>
                        <i className="ki-duotone ki-abstract-14 fs-2 fs-md-1">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </i>
                    </div>
                </div>
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html" className="d-lg-none">
                        <img alt="Logo" src={LogoImg} className="h-30px" />
                    </a>
                </div>
                <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_header_wrapper">
                    <div className="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true" data-kt-swapper-mode="{default: 'append', lg: 'prepend'}" data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">

                        <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0" id="kt_app_header_menu" data-kt-menu="true" >
                            <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2">
                                <Link to={"/dashboard"} className="menu-link">
                                    <span className="menu-title">Dashboards</span>
                                    <span className="menu-arrow d-lg-none"></span>
                                </Link>
                            </div>

                            

                        </div>

                    </div>

                    <div className="app-navbar flex-shrink-0" >
                        <div className="app-navbar-item ms-1 ms-md-4" id="kt_header_user_menu_toggle">
                            <div className="cursor-pointer symbol symbol-35px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                <img src={UsrImg} className="rounded-3" alt="user" />
                            </div>
                            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                                <div className=""></div>
                                <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
                                    <div className="d-flex align-items-center ms-1 ms-lg-3">
                                        <div className="menu">
                                            <div className="menu-item d-flex align-items-center">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="dark" >
                                                        Hi, {current_user}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-150px">
                                                        <div className="menu-item px-3">
                                                            <Link
                                                                to={"/profile"}
                                                                className="menu-link text-dark text-hover-primary bg-hover-light-primary fw-bolder"
                                                            >
                                                                <i className="bi bi-person-circle"></i>
                                                                <span className="ms-5">My Profile</span>
                                                            </Link>
                                                        </div>
                                                        <Dropdown.Divider />
                                                        <div className="menu-item px-3">
                                                            <Link
                                                                to={"/sign-out"}
                                                                className="menu-link text-dark text-hover-primary bg-hover-light-primary fw-bolder text-right"
                                                            >
                                                                <i className="bi bi-power"></i>
                                                                <span className="ms-5">Sign Out</span>
                                                            </Link>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
