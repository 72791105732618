import React, { useEffect, useState } from 'react'
import { AxiosLibGlobalReq } from '../../../../helpers/AxiosLibs';
import AccountReqTableData from './AccountReqTableData';
import { LoadingBars, NoRecordFound } from '../../../layouts/loadbars/LoadingBars';

export function AccountRequest() {
    const [accounts, setAccounts] = useState({ loading: false, data: [], message: "" })

    const FetchAccountReq = () => {
        setAccounts({ loading: true, data: [], message: "" });
        const parameter = {
            header: {},
            body: { is_active: 3 },
            method: "post",
        };
        AxiosLibGlobalReq(
            parameter,
            process.env.REACT_APP_EXPRESS_API + "/api/user/accounts"
        ).then((response) => {
            if (response.error) {
                setAccounts({ loading: false, data: [], message: response.error });
            } else if (response.data && response.status === 200) {
                setAccounts({ loading: false, data: response.data, message: "" });
            } else {
                setAccounts({ loading: false, data: [], message: "No record found" });
            }
        });
    }

    useEffect(() => {
        FetchAccountReq();
    }, [])

    return (
        <div className='card card-xl-stretch mb-5 mb-xl-8'>
            <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold fs-3 mb-1">Request Account</span>
                    {Object.values(accounts.data).length > 0 && (
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            <span className="fw-bolder text-info">{Object.values(accounts.data).length}</span> Pending User Request
                        </span>
                    )}
                </h3>
            </div>
            <div className="card-body py-3">
                {accounts.loading ? <LoadingBars /> : accounts.message ? (
                    <div className="p-3 border rounded text-danger bg-light-danger">
                        {accounts.message}
                    </div>
                ) : (
                    Object.values(accounts.data).length > 0 ? (
                        <AccountReqTableData data={accounts.data} refresh={FetchAccountReq} />
                    ) : (
                        <NoRecordFound message="No record found" />
                    )
                )}

            </div>
        </div>
    )
}
