import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent, SearchBarTable } from '../../../layouts/datatables/MYDatatables';
import moment from "moment"
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../../layouts/modals/Modals';
import { ButtonTersier } from '../../../layouts/buttons';
import { formatRupiah } from "./FormAtributes";

export  function LookUpContact({ postContact, setPostContact, data  }) {
  const listData = data;
  //console.log(data)
  /*==DATATABELS CONFIG==*/
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;
  const headers = [
    { name: "No", field: "contact_id", sortable: false },
    { name: "Full Name", field: "fullname", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Phone", field: "phone", sortable: false },
    { name: "SP Code", field: "sp_code", sortable: true },
    { name: "", field: "contact_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(listData).length > 0) {
      computedData = listData.map((v, index) => {
        var obj = v;
        //obj.status_name = v.status === 1 ? "Draft":"Sended"
        obj.num = index + 1;
        return obj;
      })
    }
    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.values(listData).some((value) => {
          if (value === null || value === undefined) {
            return false;
          }
          if (typeof value === 'object') {
            return Object.values(value).some(subValue =>
              String(subValue).toLowerCase().includes(search.toLowerCase())
            );
          }
          return String(value).toLowerCase().includes(search.toLowerCase());
        });
      });
    }
  
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }

  }, [listData, search, sorting, currentPage]);

  
  const handleSelectItem = (item) => {
    setPostContact([...postContact, item]);
    openModal({ open: false });
};


  return (
    <div className='table-responsive'>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
        <ButtonItemRefresh totalItems={totalItems}  />
      </div>

      <table className="table table-row-dashed table-striped  table-row-gray-200 align-middle gs-0 gy-1  table-sm" style={{fontSize: '0.800rem'}}>
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) =>
            setSorting({ field, order })
          }
        />
        <tbody>
          {Object.values(ResultData).length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>{v.num}</td>
                <td>
                  <div>
                    <span className="fw-normal d-block">
                      {v.fullname}
                    </span>
                  </div>
                </td>
                <td>
                  <span className="fw-bold d-block ">
                  {v.email}
                </span>
                </td>
                <td>
                  <span className="fw-normal d-block ">
                  {v.phone}
                </span>
                </td>
                <td>
                  <span className="fw-bold">
                    {(v.sp_code)}
                  </span>
                </td>
                <td>
                  <div className="btn-group">
                  <ButtonTersier className="btn-sm btn-icon text-hover-info" type="button" onClick={() => handleSelectItem(v)} title="Remove">
                        <i className="bi bi-check-circle"></i>
                      </ButtonTersier>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>No record found</td>
            </tr>
          )}
        </tbody>
        {totalItems > 0 ? (
          <tfoot>
            <tr>
              <td colSpan={7}>
                <div className="footer">
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        ) : (
          ""
        )}
      </table>
    </div>
  )
}



