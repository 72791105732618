import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { AuthDecodeToken } from '../../authentications/reducers/AuthRedux';
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs';
import { LoadingSkeleton } from '../../layouts/loadbars/LoadingBars';
import { AlertNofif } from '../../layouts/alerts/AlertsUI';

export class ContactLayout extends Component {
  constructor(props) {
    super(props);
    this.FetchWidgets = this.FetchWidgets.bind(this);
    var Token = AuthDecodeToken();
    this.state = {
      token: Token,
      userData: { loading: false, data: [], message: "" },
      widgets: { loading: false, data: [], message: "" },
      menu_id: 9
    };
  }

  FetchWidgets(param) {
    this.setState({ widgets: { loading: true, data: [], message: "" } });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/menu/widget"
    ).then((response) => {
      if (response.error) {
        this.setState({ widgets: { loading: false, data: [], message: response.error } });
      } else if (response.data && response.status === 200) {
        var results = response.data;
        console.log(results)
        this.setState({ widgets: { loading: false, data: results, message: "" } });
      } else {
        this.setState({ widgets: { loading: false, data: [], message: "No record found" } });
      }
    });
  }

  componentDidMount() {
    const paramwidget = {group_id:this.state.token.RoleID, menu_id:this.state.menu_id}
    this.FetchWidgets(paramwidget);
  }

  render() {
    const myoutlet = { current_group: this.state.token.RoleID, widgets:this.state.widgets.data };

    return (
      <div id='contact'>
        <div className="main">
          {this.state.widgets.loading ? <LoadingSkeleton /> : 
          this.state.widgets.message ? <AlertNofif title={"Information"} messages={this.state.widgets.message} color='danger' /> : 
          Object.values(this.state.widgets.data).length > 0 ? (
            <Outlet context={myoutlet} />
          ):<AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />}
        </div>
      </div>
    )
  }
}
