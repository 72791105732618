import React, { useEffect, useState } from "react";
import { MessageError, openModal } from "../layouts/modals/Modals";
//import { authStoreData } from "./reducers/AuthRedux";
import { GenerateDeviceID } from "../../helpers/GenerateID";
import { AxiosLibGlobalReq } from "../../helpers/AxiosLibs";
import { CryptoJSAesJson } from "../../helpers/Encryptions";

export default function Landing() {
  const PRIVATE_KEY = process.env.REACT_APP_NOT_SECRET_CODE;
  const APP_ENV = process.env.REACT_APP_ESTES_APP_ENV;
  const App_ID = GenerateDeviceID(36);

  const [openPass, setOpenPass] = useState(false);
  const [postData, setPostData] = useState({
    username: "",
    email: "@sgu.ac.id",
    password: "",
    typeCK: "production",
  });
  const [message, setMessage] = useState({
    username: "",
    password: "",
    general: "",
  });

  const [isLocal, setIsLocal] = useState(false);
  useEffect(() => {
    var URI = window.location.href;
    if (!URI.includes("pushnotif.sgu.ac.id")) {
      setIsLocal(true);
    }
  }, []);

  const submitLogin = (e) => {
    e.preventDefault();
    var formid = e.target.id;
    var target = document.getElementById(formid);
    var myButton = target.getElementsByClassName("btn-submit")[0];
    var classSpinner = ["spinner", "spinner-white", "spinner-left"];

    if (!postData.username) {
      setMessage({ ...message, username: "Email is required" });
    } else if (!postData.password) {
      setMessage({ ...message, password: "Password is required" });
    } else if (postData.username && postData.email && postData.password) {
      setMessage({ general: "", username: "", password: "" });
      myButton.classList.add(...classSpinner);
      myButton.textContent = "Processing";
      myButton.disabled = true;

      var mailUser = postData.username + "" + postData.email;
      mailUser = mailUser.replaceAll(/\s/g, "");

      var encrypted_password = JSON.parse(
        CryptoJSAesJson.encrypt(postData.password, PRIVATE_KEY)
      );

      var param = {
        username: mailUser,
        password: encrypted_password,
      };

      const parameter = {
        header: {
          "App-Id": App_ID,
          "App-Env": APP_ENV,
        },
        body: param,
      };

      AxiosLibGlobalReq(
        parameter,
        process.env.REACT_APP_ESTES_API +
          process.env.REACT_APP_ESTES_API_PATH_LOGIN
      ).then((response) => {
        if (response.error) {
          setMessage({ ...message, general: response.error });
          openModal({ message: <MessageError message={response.error} /> });

          myButton.classList.remove(...classSpinner);
          myButton.disabled = false;
          myButton.innerHTML = "Continue";
        } else if (response.result === "success") {
          CheckExistAccount(response, myButton);
        } else {
          var messageError =
            "Wrong credentials provided. Check them and try again";
          openModal({ message: <MessageError message={messageError} /> });

          myButton.classList.remove(...classSpinner);
          myButton.disabled = false;
          myButton.innerHTML = "Continue";
        }
      });
    } else {
      setMessage({
        username: "",
        password: "",
        general: "Please fill up the requirement",
      });
      openModal({
        message: (
          <MessageError
            message={
              "Sorry, looks like there are some errors detected, please try again."
            }
          />
        ),
      });
    }
  };

  const CheckExistAccount = (data, myButton) => {
    var param = { email: data.identity.email, employeeid: data.identity.empid };
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/user/check-exist"
    ).then((response) => {
      if (response.error) {
        myButton.disabled = false;
        myButton.innerHTML = "Continue";
        var message =
          response.error +
          "This email and password you entered did not match our records. Please double check and try again.";
        setMessage({ username: "", password: "", general: message });
        openModal({ message: <MessageError message={message} /> });
      } else if (response.status === 200) {
        if (Object.values(response.data).length > 0) {
          myButton.disabled = true;
          myButton.innerHTML = "Success SignIn";
          var datetime = new Date();
          datetime.setHours(datetime.getHours() + 6);

          var results = {};
          results.AppID = App_ID;
          results.AppEnv = APP_ENV;
          results.Secretkey = data.secretkey;
          results.Expired = datetime.getTime();
          results.identity = data.identity;
          results.RoleID = response.data.group_id;
          results.RoleName = response.data.group_name;

          setMessage({
            username: "",
            password: "",
            general: "Wait for a moment, you will directly to the portal",
          });
          //authStoreData(results);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          myButton.disabled = false;
          myButton.innerHTML = "Continue";
          setMessage({
            username: "",
            password: "",
            general: "You dont have an access to this portal",
          });
          openModal({
            message: (
              <MessageError
                message={"You dont have an access to this portal"}
              />
            ),
          });
        }
      } else {
        setMessage({
          username: "",
          password: "",
          general: "You dont have an access to this portal",
        });
        openModal({
          message: (
            <MessageError message={"You dont have an access to this portal"} />
          ),
        });
      }
    });
  };

  return (
    <div id="login-page">
      <form
        className="form w-100"
        noValidate="novalidate"
        id="kt_sign_in_form"
        autoComplete="off"
        onSubmit={(e) => submitLogin(e)}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">Portal Web Training</h1>
          <div className="text-gray-400 fs-4">
            Please Registration
            <span className="text-primary fw-bold ms-2">
              SGU Google account
            </span>
          </div>
        </div>

        {isLocal && (
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">
              Consumer Key
            </label>
            <select name="type_ck" className="form-select form-select-solid" defaultValue={postData.typeCK} onChange={(e)=>setPostData({...postData, typeCK:e.target.value})}>
                <option value="production">Production</option>
                <option value="development" disabled>Development</option>
            </select>
          </div>
        )}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            SGU Email
          </label>
          <div className="input-group">
            <input
              type="text"
              name="username"
              className={
                "form-control form-control-lg form-control-solid mb-3 mb-lg-0 " +
                (message.username ? "is-invalid" : "")
              }
              placeholder="firstname.lastname"
              onChange={(e) =>
                e.target.value
                  ? (setPostData({ ...postData, username: e.target.value }),
                    setMessage({ ...message, username: "" }))
                  : (setPostData({ ...postData, username: e.target.value }),
                    setMessage({
                      ...message,
                      username: "Fill out this field",
                    }))
              }
              value={postData.username}
            />
            <span className="input-group-text border-0">@sgu.ac.id</span>
          </div>

          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{message.username}</span>
            </div>
          </div>
        </div>
        <div
          className="mb-10 fv-row fv-plugins-icon-container"
          data-kt-password-meter="true"
        >
          <div className="mb-1">
            <div className="d-flex flex-stack mb-2">
              <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Password
              </label>
            </div>
            <div className="position-relative mb-3">
              <input
                className={
                  "form-control form-control-lg form-control-solid " +
                  (message.password ? "is-invalid" : "")
                }
                type={openPass ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={postData.password}
                onChange={(e) =>
                  e.target.value
                    ? (setPostData({ ...postData, password: e.target.value }),
                      setMessage({ ...message, password: "" }))
                    : (setPostData({ ...postData, password: e.target.value }),
                      setMessage({
                        ...message,
                        password: "Fill out this field",
                      }))
                }
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 text-hover-primary"
                data-kt-password-meter-control="visibility"
                onClick={(e) =>
                  openPass ? setOpenPass(false) : setOpenPass(true)
                }
              >
                <i
                  className={
                    "bi bi-" + (openPass ? "eye-slash" : "eye") + " fs-2"
                  }
                ></i>
              </span>
            </div>
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{message.password}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-dark w-100 mb-5 btn-submit"
          >
            Continue
          </button>
        </div>

        {message.general ? (
          <p className={"text-center text-primary"}>{message.general}</p>
        ) : (
          ""
        )}
      </form>
    </div>
  );
}
