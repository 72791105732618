import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { AuthDecodeToken } from "../../authentications/reducers/AuthRedux";
import {
  AxiosLibGlobalReq,
  AxiosLibGlobalReqModule,
} from "../../../helpers/AxiosLibs";
import {
  ConfirmLeave,
  MessageError,
  MessageSuccess,
  openModal,
} from "../../layouts/modals/Modals";
import {
  RemoveEmptyValue,
  validateEmail,
} from "../../../helpers/CommonHelpers";
import { EmailForm } from "./components/EmailForm";
import { ButtonPrimary, ButtonTersier } from "../../layouts/buttons";
import WLookUpTemp from "../templates/components/WLookUpTemp";
import { RenderAccessWidgets } from "../RenderAccessUser";
import { AlertNofif } from "../../layouts/alerts/AlertsUI";
import { CardBox } from "../../layouts/cards/CardUI";

export function CampaignShortForm() {
  const context = useOutletContext();
  const widget_2 = RenderAccessWidgets(context.widgets, 2);
  const widget_3 = RenderAccessWidgets(context.widgets, 3);
  const widget_4 = RenderAccessWidgets(context.widgets, 4);

  const navigate = useNavigate();
  const editorRef = useRef(null);
  const history = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      openModal({
        header: "Confirmations",
        message: (
          <ConfirmLeave
            url="/campaigns"
            message={
              "Are you sure you want to delete everything? This action cannot be undone."
            }
          />
        ),
      });
      event.preventDefault();
      window.history.pushState(null, null, window.location.href);
    };
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);

  const maxCharacters = 400;
  const [characterCount, setCharacterCount] = useState(0);

  const [submitPost, setSubmitPost] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [mailSingle, setMailSingle] = useState("");
  const [mailList, setMailList] = useState([]);
  var Token = AuthDecodeToken();
  const objParam = {
    campaign_id: "",
    name: "",
    subject: "",
    messages: "",
    status: 1,
    recepients: "",
    created_by: Token.identity.email,
    is_temp: false,
  };
  const [post, setPost] = useState(objParam);

  const HandlerChangeTemp = (data) => {
    setPost({ ...post, messages: decodeURIComponent(data) });
  };

  const [publish, setPublish] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const handlerSubject = (value) => {
    const subject = encodeURIComponent(value);
    setPost({ ...post, subject: subject, name: subject });
  };

  const HandleSubmit = (status) => {
    if (
      post.name &&
      post.subject &&
      Object.values(mailList).length > 0 &&
      post.messages
    ) {
      let target_user = mailList.map((v) => {
        let obj = {};
        obj.email = v;
        obj.is_group = 0;
        return obj;
      });
      if (validateEmail(mailSingle)) {
        target_user.push({ email: mailSingle, is_group: 0 });
      }
      const param_data = RemoveEmptyValue(post);
      param_data.recepients = JSON.stringify(target_user);
      param_data.name = post.name;
      param_data.subject = post.subject;
      param_data.status = status;
      param_data.messages = encodeURIComponent(post.messages);

      if (status === 1) {
        CreateCampaigns(param_data);
        //console.log(param_data);
      } else {
        var datapush = {};
        datapush.channel = "notif.channel.type.mobile";
        datapush.schd_type = "direct"; //direct / schedule
        datapush.subject = param_data.subject;
        datapush.target_user = target_user;
        datapush.body = encodeURIComponent(post.messages);
        PublishCampaigns(datapush, param_data);
      }
    } else {
      openModal({
        message: (
          <MessageError message={"Please fill out the form with correctly"} />
        ),
      });
    }
  };

  const CreateCampaigns = (param) => {
    setSubmitPost({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: param,
      method: "post",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/campaigns/save"
    ).then((response) => {
      if (response.error) {
        setSubmitPost({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        if (param.status === 2) {
          setSubmitPost({
            loading: false,
            data: response.data,
            message: "Success saved",
          });
        } else {
          setSubmitPost({
            loading: false,
            data: response.data,
            message: "Successfully Saved",
          });
          openModal({
            message: <MessageSuccess message={"Successfully Saved"} />,
          });
          setTimeout(() => {
            navigate("/campaigns");
          }, 1000);
        }
      } else {
        setSubmitPost({ loading: false, data: [], message: "Failed saved" });
      }
    });
  };

  const PublishCampaigns = (data, data2) => {
    setPublish({ loading: true, data: [], message: "" });
    var headers = {
      AppId: Token.AppID,
      AppEnv: Token.AppEnv,
      Secretkey: Token.Secretkey,
    };
    var bodys = {
      parameters: data,
      target_data: "CREATE_NOTIFICATION",
    };
    const parameter = {
      header: headers,
      body: bodys,
      method: "post",
    };

    AxiosLibGlobalReqModule(
      parameter,
      process.env.REACT_APP_ESTES_API +
      process.env.REACT_APP_ESTES_API_PATH_REQUEST
    ).then((response) => {
      if (response.error) {
        openModal({
          header: "",
          message: <MessageError message={response.error} />,
        });
        setPublish({ loading: false, data: [], message: response.error });
      } else if ("message" in response.response) {
        openModal({
          header: "",
          message: <MessageError message={response.response.message.user} />,
        });
        setPublish({
          loading: false,
          data: [],
          message: response.response.message.user,
        });
      } else {
        if (response.response.data) {
          CreateCampaigns(data2);
          setPublish({
            loading: false,
            data: response.response.data,
            message: "",
          });
          openModal({
            message: (
              <MessageSuccess
                message={"The notification has been successfully published."}
              />
            ),
          });
          setTimeout(() => {
            openModal({ open: false });
            navigate("/campaigns");
          }, 1000);
        } else {
          setPublish({
            loading: false,
            data: response.response.data,
            message: "Failed publish",
          });
          openModal({
            header: "",
            message: <MessageError message={"Failed publish"} />,
          });
        }
      }
    });
  };

  return (
    widget_2 === true ? (
      <CardBox>
        <CardBox.Header>
          <span className="card-label fw-bold fs-3 mb-1">Form Campaign</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Please fill up the form with correctly
          </span>
        </CardBox.Header>
        <CardBox.Body>
          <div autoComplete="off" id="form-post" className="mt-8">
            <div className="mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <label className="required fw-bolder">To:</label>
                <span className="text-muted">
                  {Object.values(mailList).length + (mailSingle ? 1 : 0)}{" "}
                  Recepients
                </span>
              </div>
              <EmailForm
                mailList={mailList}
                setMailList={setMailList}
                mailSingle={mailSingle}
                setMailSingle={setMailSingle}
              />
            </div>
            <div className="mb-5">
              <label className="required fw-bolder">Subject</label>
              <input
                type="text"
                required
                autoComplete="off"
                name="Subject"
                className="form-control"
                defaultValue={decodeURIComponent(post.subject)}
                onChange={(e) => handlerSubject(e.target.value)}
              />
            </div>

            <div className="mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <label className="required fw-bolder">Messages</label>
                <ButtonTersier
                  className="btn-sm fw-bold"
                  type="button"
                  onClick={() =>
                    openModal({
                      header: "Import Template",
                      message: (
                        <WLookUpTemp HandlerChangeTemp={HandlerChangeTemp} />
                      ),
                    })
                  }
                >
                  <i className="fa fa-upload me-1"></i>
                  <span className="text-capitalize">Import from template</span>
                </ButtonTersier>
              </div>

              <Editor
                apiKey="sxd4e2dzs0r4qtfz7zzrh63khnixp6vk68il4znsxxuo1tym"
                className="required"
                value={post.messages}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onEditorChange={(newValue, editor) => {
                  setPost({ ...post, messages: newValue });
                }}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: "link",
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic link alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | " +
                    "removeformat | help",
                  automatic_uploads: true,
                  default_link_target: "_blank",
                  link_assume_external_targets: true,
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  setup: (editor) => {
                    editor.on("keyup keydown change", (e) => {
                      const content = editor.getContent();
                      const currentLength = content.length;
                      if (currentLength >= maxCharacters) {
                        const truncatedContent = content.slice(0, maxCharacters);
                        // Set the truncated content back to the editor
                        editor.setContent(truncatedContent);
                        // Update the character count state
                        setCharacterCount(maxCharacters);
                      } else {
                        setCharacterCount(currentLength);
                      }
                    });
                  },
                  max_chars: maxCharacters,
                }}
              />
              <p className="text-muted fst-italic">
                Maximum Characters: {characterCount}/{maxCharacters}
              </p>
            </div>

            <div className="mb-5 bg-light-primary rounded p-5">
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  name="is_temp"
                  className="form-check-input"
                  defaultValue={post.is_temp}
                  onChange={(e) =>
                    setPost({ ...post, is_temp: e.target.checked })
                  }
                />
                <label
                  className={
                    "form-check-label " +
                    (post.is_temp ? "text-primary" : "text-muted")
                  }
                >
                  Tick if you want to save as template
                </label>
              </div>
            </div>

            <div className="mt-10 d-flex justify-content-between align-items-center">
              <ButtonTersier
                className="btn-lg fw-bold"
                onClick={() =>
                  openModal({
                    header: "Confirmations",
                    message: (
                      <ConfirmLeave
                        url="/campaigns"
                        message={
                          "Are you sure you want to delete everything? This action cannot be undone."
                        }
                      />
                    ),
                  })
                }
              >
                <i className="bi bi-trash"></i> Delete
              </ButtonTersier>
              <div>
                {widget_3 === true && (
                  <ButtonTersier
                    className="btn-lg fw-bold btn-submit mx-3"
                    type="button"
                    onClick={() => HandleSubmit(1)}
                    disabled={submitPost.loading}
                  >
                    Save as Draft
                  </ButtonTersier>
                )}

                {widget_4 === true && (
                  <ButtonPrimary
                    className="btn-lg fw-bold btn-submit"
                    type="button"
                    onClick={() => HandleSubmit(2)}
                    disabled={submitPost.loading || publish.loading}
                  >
                    {submitPost.loading || publish.loading
                      ? "Processing.."
                      : "Save and Publish"}
                  </ButtonPrimary>
                )}
              </div>
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
    ) : <AlertNofif title={"Information"} messages={"You don't have permission to access this resource. Please contact the administrator."} color="danger" />
  );
}
