import React, { useEffect, useState } from 'react'
import TableData from './TableData'
import { Link, useOutletContext } from 'react-router-dom'
import { AxiosLibGlobalReq } from '../../../helpers/AxiosLibs'
import { LoadingBars, NoRecordFound } from '../../layouts/loadbars/LoadingBars'
import { RenderAccessWidgets } from '../RenderAccessUser'

export function Templates() {
  const context = useOutletContext();
  const widget_6 = RenderAccessWidgets(context.widgets, 6);
  const widget_7 = RenderAccessWidgets(context.widgets, 7);

  const [templates, setTemplates] = useState({ loading: false, data: [], message: "" })

  const FetchAllData = () => {
    setTemplates({ loading: true, data: [], message: "" });
    const parameter = {
      header: {},
      body: {},
      method: "get",
    };
    AxiosLibGlobalReq(
      parameter,
      process.env.REACT_APP_EXPRESS_API + "/api/templates"
    ).then((response) => {
      if (response.error) {
        setTemplates({ loading: false, data: [], message: response.error });
      } else if (response.data && response.status === 200) {
        setTemplates({ loading: false, data: response.data, message: "" });
      } else {
        setTemplates({ loading: false, data: [], message: "No record found" });
      }
    });
  }

  useEffect(() => {
    FetchAllData();
  }, [])

  return (
    (widget_6 === true ? (
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">Master templates</span>
            <span className="text-muted mt-1 fw-semibold fs-7">List of all templates</span>
          </h3>
          {widget_7 === true && (
            <div className="card-toolbar">
              <Link className="nav-link btn btn-sm btn-primary fw-bold px-4 me-1" to={'/templates/create'}>
                <i className="bi bi-plus fs-2"></i>
                Create new templates
              </Link>
            </div>
          )}
        </div>
        <div className="card-body py-3">
          {templates.loading ? <LoadingBars /> : templates.message ? (
            <div className="p-3 border rounded text-danger bg-light-danger">
              {templates.message}
            </div>
          ) : (
            Object.values(templates.data).length > 0 ? (
              <TableData data={templates.data} ReloadData={FetchAllData} />
            ) : (
              <NoRecordFound message="No record found" />
            )
          )}

        </div>
      </div>
    ) : widget_6)
  )
}
