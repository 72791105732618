import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const AsideMenuItem = ({ children, to, title, icon, hasBullet = false }) => {
    const { pathname } = useLocation();
    const isActive = checkIsActive(pathname, to)
    return (
        <div className='menu-item'>
            <Link className={'menu-link without-sub ' + (isActive && "active")} to={to}>
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}
                {icon && (
                    <span className='menu-icon'>
                        <i className={"bi fs-3 " + icon}></i>
                    </span>
                )}
                <span className='menu-title'>{title}</span>
            </Link>
            {children}
        </div >
    );
}

const AsideMenuItemWithSub = ({ children, to, title, icon, hasBullet = false }) => {
    const { pathname } = useLocation();
    const isActive = checkIsActive(pathname, to);
    const [isOpen, setIsOpen] = useState(isActive);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={'menu-item menu-accordion ' + (isOpen && 'hover show')}
            data-kt-menu-trigger='click'>
            <span className='menu-link' onClick={handleToggle}>
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}
                {icon && (
                    <span className='menu-icon'>
                        <i className={"bi fs-3 " + icon}></i>
                    </span>
                )}
                <span className='menu-title'>{title}</span>
                <span className='menu-arrow'></span>
            </span>
            <div className={'menu-sub menu-sub-accordion ' + (isOpen && 'menu-active-bg')}>
                {children}
            </div>
        </div>
    );
}

const getCurrentUrl = (pathname) => {
    return pathname.split(/[?#]/)[0]
}

const checkIsActive = (pathname, url) => {
    const current = getCurrentUrl(pathname)
    if (!current || !url) {
        return false
    }

    if (current === url) {
        return true
    }

    if (current.indexOf(url) > -1) {
        return true
    }

    return false
}


export { AsideMenuItem, AsideMenuItemWithSub }