import moment from "moment";

const states = {
  setState: null,
  changeState(data) {
    if (!this.setState) return;
    this.setState((prevData) => {
      return {
        ...prevData,
        ...data,
      };
    });
  },
};
const GroupByKey = (list, key) => {
  var result = list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
  return result;
};

const RemoveFirstItemWithKey = (objArr, key) => {
  var objResult = [...objArr];
  const indexToRemove = objResult.findIndex((item) => item.type === key);
  let newData;
  if (indexToRemove !== -1) {
    newData = [...objArr];
    newData.splice(indexToRemove, 1);
  }

  return newData;
};

const FormatRupiah = (angka, prefix) => {
  var number_string = angka.toString().replace(/[^,\d]/g, '').toString(),
    split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  var separator = '';
  if (ribuan) {
    separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix === undefined ? rupiah : (rupiah ? '' + rupiah : '');
}

const RemoveEmptyValue = (dataArr) => {
  const newData = { ...dataArr }; // Create a copy of the data object

  for (const key in newData) {
    if (newData[key] === '' || newData[key] === 0 || newData[key] === null) {
      delete newData[key]; // Remove the property if its value is empty
    }
  }

  return newData;
}

const LoopWeekDay = (now) => {
  var weekdays = [];
  for (let i = 1; i <= 6; i++) {
    const day = now.clone().weekday(i);
    weekdays.push({
      name: day.format('DD ddd'), // Format the day name (e.g., Monday)
      date: day.format('YYYY-MM-DD'), // Format the date (e.g., 2023-09-01)
    });
  }

  return weekdays;
}

const LoopDays = (start, end) => {
  const start_dt = moment(start, 'YYYY-MM-DD');
  const end_dt = moment(end, 'YYYY-MM-DD');
  var days = [];

  while (start_dt.isSameOrBefore(end_dt)) {
    //console.log("date:",start_dt.format('YYYY-MM-DD'));
    days.push(start_dt.format('DD MMM YYYY'));
    start_dt.add(1, 'day');
  }
  return days;
}

// const isValidEmail = (val) => {
//   var result = "";
//   let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   if (!regEmail.test(val)) {
//     result = "Invalid Email Address"
//   }
//   return result;
// }

const validateEmail = (email) => {
  //const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export { GroupByKey, RemoveFirstItemWithKey, FormatRupiah, RemoveEmptyValue, LoopWeekDay, LoopDays, validateEmail };